<section>
  <div class="responsive-table-wrapper">
    <table mat-table matSort
           [ngClass]="{
              'list-with-select': multiSelect
           }"
           [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           (matSortChange)="sortData($event)">


      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="selectAllToggle($event)"
                        style="margin-top: 8px;"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" style="padding-top: 8px;">
          <mat-checkbox (click)="$event.stopPropagation();"
                        (change)="$event ? selectedChange(row) : null"
                        [checked]="isSelectedRow(row)">
          </mat-checkbox>

        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="import-case-date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start date</th>
        <td mat-cell *matCellDef="let element">
          {{setFormat(element.casesCreatedDate)}}
        </td>
      </ng-container>


      <ng-container matColumnDef="current-max">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Current/Max</th>
        <td mat-cell *matCellDef="let element"> {{element.currentCasesNumber}}/{{element.maxCasesNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Done</th>
        <td mat-cell *matCellDef="let element" [matTooltip]=""> {{element.casesCompleted ? 'yes' : 'no'}}</td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="clickedItem(row, i);"
          [ngClass]="{ 'highlight': selection.isSelected(row)}"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <div class="container mr-0">
    <div class="row justify-content-end"
         style="flex-direction: row;justify-content: space-between;align-items: center;">
      <div class="col-xs-4" *ngIf="selection.selected.length > 0">
        <p style="font-size:13px; color:grey; margin-top:auto; margin-bottom:auto">
          Selected: {{selection.selected.length}} </p>
      </div>
      <div class="col-xs-8">
        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [length]="pagesInfo.totalElements"
          [pageIndex]="pageParams.page.value"
          [pageSize]="pageParams.size.value"
          (page)="setPageSizeOrIndex($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>

  </div>
</section>
