<!--<h4 mat-dialog-title-->
<!--    style="font-size: medium; text-align: center;">{{(compileErrors == null || compileErrors.length == 0) ? 'Are you sure you want to compile cases' : 'Compile errors'}}</h4>-->
<h4 mat-dialog-title
    style="font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;">{{(!this.startCompile) ? 'Are you sure you want to compile these cases' : (this.endCompile) ? 'Completed case compilation' : 'Compiling cases'}}</h4>

<div mat-dialog-content class="fs-12">

  <div [@.disabled]="disableAnimation" class="example-headers-align mb-1">

    <div style="font-size: 16px; margin-left: 3rem; margin-top: 1rem" *ngIf="!this.startCompile">
      <mat-radio-group [(ngModel)]="compileType" color="primary">
        <mat-radio-button [value]="1" [disabled]="this.ids.length == 0">Only selected</mat-radio-button>
        <br>
        <mat-radio-button [value]="2">Use Filter</mat-radio-button>
      </mat-radio-group>
    </div>

    <div *ngIf="this.startCompile" style=" max-width: 100%;">

      <!--      <div class="ml-1" style="font-size: 16px;">-->
      <!--        Compiled: {{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}-->
      <!--      </div>-->

      <!--      <br>-->

      <div class="ml-3 mr-3 mb-2">
        <div style="text-align: center">{{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}</div>
        <mat-progress-bar class=" w-100" style="height: 10px" mode="determinate"
                          [value]="compileInfo.currentNumber/compileInfo.maxNumber*100"></mat-progress-bar>
        <!--    <div style="position:relative; top: -10px; left: 30px;">-->
        <!--      {{compileInfo.currentNumber}}/{{compileInfo.maxNumber}}-->
        <!--    </div>-->
      </div>

      <mat-expansion-panel style="border-radius: 0;"
                           *ngIf="compileInfo.skipped != null && compileInfo.skipped != ''">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Skipped compiles
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="compileInfo.skipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <!--      <mat-expansion-panel style="border-radius: 0;"-->
      <!--                           *ngIf="compileInfo.errors != null && compileInfo.errors != ''">-->
      <!--        <mat-expansion-panel-header>-->
      <!--          <mat-panel-title>-->
      <!--            Compile errors-->
      <!--          </mat-panel-title>-->
      <!--        </mat-expansion-panel-header>-->

      <!--        <mat-form-field class="w-100">-->
      <!--          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"-->
      <!--                    matAutosizeMinRows="2"-->
      <!--                    [contentEditable]="false"-->
      <!--                    [matTextareaAutosize]="true"-->
      <!--                    [(ngModel)]="compileInfo.errors"-->
      <!--                    (ngModelChange)="paramsChangeValue()"></textarea>-->
      <!--        </mat-form-field>-->
      <!--      </mat-expansion-panel>-->

      <mat-expansion-panel style="border-radius: 0;"
                           *ngIf="compileInfo.error != null && compileInfo.error != ''">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Pre compile error
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="compileInfo.error"
                    (ngModelChange)="paramsChangeValue()"></textarea>
        </mat-form-field>
      </mat-expansion-panel>

      <app-compile-info-case-info-options
        *ngIf="compileInfo.errors != null && compileInfo.errors != ''"
        [checkPaths]="getCompileInfoCaseInfoListOptionsCheckPathObject()"
        [(searchParams)]="params.search"

        (filter)="filter($event)"
        (refresh)="refreshCompileInfo();">

      </app-compile-info-case-info-options>
      <app-compile-info-case-info-list
        *ngIf="compileInfo.errors != null && compileInfo.errors != ''"
        #list
        [autoSetItems]="true"
        [compileInfoId]="this.compileInfo.id"
        [liveRefresh]="this.compileInfo.currentNumber != this.compileInfo.maxNumber"
        [pageSizeOptions]="[5]"

        [searchParams]="params.search"
        [pageParams]="params.page"
        (pageParamsChange)="pageParamsChange($event)"
        (pagesInfoChanges)="setPageInfoChanges($event)"

        (compiledError)="compiledError($event)"
        (transpiledError)="transpiledError($event)"

        (clickedItem)="clickedCompileInfo($event)">

      </app-compile-info-case-info-list>

      <br>
    </div>


    <!--        <mat-expansion-panel *ngFor="let compileError of compileErrors">-->
    <!--          <mat-expansion-panel-header>-->
    <!--            <mat-panel-title>-->
    <!--              {{compileError.testCase.name}}-->
    <!--            </mat-panel-title>-->
    <!--          </mat-expansion-panel-header>-->


    <!--          <app-show-compile-error [compileResult]="compileError">-->

    <!--          </app-show-compile-error>-->

    <!--        </mat-expansion-panel>-->

  </div>
  <!--  <br>-->
</div>

<div mat-dialog-actions class="d-flex float-right px-4 py-2">
  <button mat-button [mat-dialog-close]>Close</button>
  <button mat-button *ngIf="!spinner && !this.startCompile" (click)="compile()" color="primary">
    Compile
  </button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
