import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {GlobalEventsManagerService} from '../services/global-events-manager.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private globalEventsManager: GlobalEventsManagerService
  ) {
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.authState.pipe(first()).subscribe(
        user => {
          if (user) {
            if (user.emailVerified || user.isAnonymous) {
              this.globalEventsManager.showNavBar(true);
              resolve(true);
            } else {
              this.router.navigate(['/verifyEmail']).then();
              resolve(true);
            }
          } else {
            this.router.navigate(['/logout']).then();
            resolve(false);
          }
        },
        error => {
          console.error(error);
        }
      );
    });
  }

}
