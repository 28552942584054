import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {UserService} from '../../layout/users/shared/services/user.service';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private userService: UserService
  ) {
  }

  canActivate(): boolean {
    // if (this.userService.isAdmin()) {
    //   return true;
    // } else {
    //   this.router.navigate(['/403']);
    //   return false;
    // }
    return true;
  }

}
