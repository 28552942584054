<h1 mat-dialog-title>Are you sure you want to download this file?</h1>

<div mat-dialog-content>

</div>

<div mat-dialog-actions>
  <button id="closeButton" mat-button (click)="closeDialog()">{{exportSuccess || spinner ? 'Close' : 'Cancel'}}</button>
  <button id="exportButton" mat-button *ngIf="!spinner" (click)="export()" color="primary">Download</button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>

