import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MaxtafTokensStorageService} from './maxtaf-tokens-storage.service';

/*
* that includes the JWT with each request sent to the application server
*
* we first start by retrieving the JWT string from Local Storage directly
then we are going to check if the JWT is present
if the JWT is not present, then the request goes through to the server unmodified
if the JWT is present, then we will clone the HTTP headers, and add an extra Authorization header, which will contain the JWT
* */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  className = 'AuthInterceptor';

  constructor(public tokensService: MaxtafTokensStorageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const queryUser = request.params.get(this.tokensService.USER_TOKEN);
    const queryProject = request.params.get(this.tokensService.PROJECT_TOKEN);
    const queryUserProject = request.params.get(this.tokensService.USER_PROJECT_TOKEN);

    const user = queryUser == undefined ? this.tokensService.getUserId() : queryUser;
    const project = queryProject == undefined ? this.tokensService.getProjectId() : queryProject;
    const userProject = queryUserProject == undefined ? this.tokensService.getUserProjectID() : queryUserProject;
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();

    const cloned = request.clone({
      headers: request.headers
        .set(this.tokensService.USER_PROJECT_TOKEN, userProject == null ? '' : userProject)
        .set(this.tokensService.PROJECT_TOKEN, project == null ? '' : project)
        .set(this.tokensService.USER_TOKEN, user == null ? '' : user)
        .set(this.tokensService.FIREBASE_TOKEN, fireToken == null ? '' : fireToken)
        .set(this.tokensService.FIREBASE_REFRESH_TOKEN, refreshToken == null ? '' : refreshToken)
        .set(this.tokensService.USER_TIMEZONE_ID, Intl.DateTimeFormat().resolvedOptions().timeZone)
    });

    return next.handle(cloned);
  }

}
