import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {ProjectParams} from '../models/projectParams';
import {Params} from '../../../mx/util/params/params';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectParamsService {

  constructor(
    private apiService: ApiService,
    private cloudConfigService: CloudConfigService
  ) {
  }

  getAll(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getProjectParams(), params);
  }

  getProjectParam(paramName: string) {
    return this.apiService.get(this.cloudConfigService.getProjectParam(paramName));
  }


  deleteProjectParam(paramName: string) {
    return this.apiService.delete(this.cloudConfigService.deleteProjectParam(paramName));
  }

  createProjectParams(projectParams: ProjectParams) {
    return this.apiService.post(this.cloudConfigService.createProjectParams(), projectParams);
  }

  setProjectParams(projectParams: ProjectParams) {
    return this.apiService.put(this.cloudConfigService.setProjectParams(), projectParams);
  }

  getAllConfigurationParam(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getConfigurationParams(), params);
  }

  getConfigurationParam(paramName: string) {
    return this.apiService.get(this.cloudConfigService.getConfigurationParam(paramName));
  }

  setConfigurationParams(projectParams: ProjectParams) {
    return this.apiService.put(this.cloudConfigService.setConfigurationParams(), projectParams);
  }

  setDefaultValue(paramName: string) {
    return this.apiService.put(this.cloudConfigService.resetConfigParam() + '?paramName='+ paramName, '');
  }

  setDefaultValues(configParamFilter) {
    return this.apiService.put(this.cloudConfigService.resetConfigParams(), configParamFilter);
  }

}
