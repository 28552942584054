<mat-expansion-panel
  [hideToggle]="true"
  [ngClass]="{'card-shadow': allowShadow}"
  #expansionPanel
  [expanded]="false"
  (keydown.Space)="$event.stopImmediatePropagation();setBody();"
  (keydown.Enter)="$event.stopImmediatePropagation();setBody();">

  <mat-expansion-panel-header style="padding:0 !important"
                              [ngClass]="{
                                          'normal-height': !smallHeight,
                                          'small-height': smallHeight,
                                          'small-options-button-toolbar': smallHeight,
                                          'mx-options-header-second-colors': !defaultColors,
                                          'mx-options-header-default-colors': defaultColors,
                                          'header-bottom-border': headerBottomBorder
                                          }"
                              (click)="setBody()"
                              (keydown.Space)="$event.stopImmediatePropagation();setBody();"
                              (keydown.Enter)="$event.stopImmediatePropagation();setBody();">

    <mat-toolbar class="toolbar-options"
                 [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'options-button': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }">


      <button mat-icon-button
              class="arrow-nav-button mr-2"
              *ngIf="hasOverflowX()"
              (click)="scrollLeft()"
              [ngClass]="{
                          'mx-options-header-second-colors': !defaultColors,
                          'mx-options-header-default-colors': defaultColors,
                          'options-button': smallHeight,
                          'right-gray-shadow': defaultColors,
                          'right-blue-shadow': !defaultColors
                         }">
        <mx-icon marginBottom="true" mx="left-arrow"></mx-icon>
      </button>

      <overlay-scrollbars
        [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }"
        #optionsScroll
        style="width: 100%;"
        [options]="{ scrollbars: { visibility: 'h' } }"

      >

        <div
          [style.padding-left]="hasOverflowX() ? '0' : '15px'"
          class="mx-options-header"
          [ngClass]="{
                  'normal-height': !smallHeight,
                  'small-height': smallHeight,
                  'options-button': smallHeight,
                  'mx-options-header-second-colors': !defaultColors,
                  'mx-options-header-default-colors': defaultColors
                 }">
          <ng-content select="mx-options-header"></ng-content>
        </div>

      </overlay-scrollbars>

      <button mat-icon-button
              class="arrow-nav-button ml-2"
              *ngIf="hasOverflowX()"
              (click)="scrollRight()"
              [ngClass]="{
                          'mx-options-header-second-colors': !defaultColors,
                          'mx-options-header-default-colors': defaultColors,
                          'options-button': smallHeight,
                          'left-gray-shadow': defaultColors,
                          'left-blue-shadow': !defaultColors
                         }">
        <mx-icon marginBottom="true" mx="right-arrow"></mx-icon>
      </button>

    </mat-toolbar>

  </mat-expansion-panel-header>
  <div [ngClass]="{'body-bottom-border': bodyBottomBorder}" [ngStyle]="{'background': bodyColor}">
    <ng-content select="mx-options-body"></ng-content>
  </div>
</mat-expansion-panel>
