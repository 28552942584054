<h1 mat-dialog-title>Change case file location/name</h1>

<div mat-dialog-content class="change-file-location-container">
  <label *ngIf="testCase.caseType != 'JAVA'">Source directory</label>
  <span *ngIf="testCase.caseType != 'JAVA'" class="mb-2"
        [matTooltip]="'Change this in project params'">{{fileLocation.projectParamPart}}</span>

  <!--  <mat-form-field  *ngIf="testCase.caseType == 'JAVA'" class="ml-1">-->
  <!--    <mat-select placeholder="maven source directory"-->
  <!--                [(value)]="fileLocation.buildAutomationToolPart">-->
  <!--      <mat-option value="Contains" >{{buildAutomationToolPath.testSourceDirectory}}</mat-option>-->
  <!--      <mat-option value="Exact">{{buildAutomationToolPath.sourceDirectory}}</mat-option>-->
  <!--    </mat-select>-->
  <!--  </mat-form-field>-->
  <label *ngIf="testCase.caseType == 'JAVA'">Source directory</label>
  <mat-radio-group aria-label="Select an option" *ngIf="testCase.caseType == 'JAVA'"
                   [(ngModel)]="fileLocation.buildAutomationToolPart">
    <mat-radio-button
      value="{{buildAutomationToolPath.testSourceDirectory}}">{{fileLocation.projectParamPart}}{{buildAutomationToolPath.testSourceDirectory}}</mat-radio-button>
    <br>
    <mat-radio-button
      value="{{buildAutomationToolPath.sourceDirectory}}">{{fileLocation.projectParamPart}}{{buildAutomationToolPath.sourceDirectory}}</mat-radio-button>
  </mat-radio-group>

  <mat-form-field class="mt-1">
    <input matInput type="text" placeholder="File location"
           [(ngModel)]="fileLocation.packageCasePartWithoutExtension">
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button id="closeButton" mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button *ngIf="!spinner && autoUpdate" (click)="updateCaseFileLocation()" color="primary">Update</button>
  <button mat-button *ngIf="!spinner && !autoUpdate" (click)="updateCaseFileLocation()" color="primary">OK</button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
