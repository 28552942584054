import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import * as OverlayScrollbars from 'overlayscrollbars';
import {EngineStatus} from '../../layout/users/shared/models/EngineStatus';
import {AIInsertCode} from "../models/aIInsertCode";

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsManagerService {

  private _grayScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _darkGrayScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _sideNavMod: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  private _screenWidth: BehaviorSubject<number> = new BehaviorSubject<number>(1200);
  private _showTopMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showNavBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showNavBarButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showTopMenuChangeProjectButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _showTopMenuUserInfoButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showUserInHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _showAdminSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _showNoticeBoardSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _autoSave: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _workspaceNavBarHide: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _engineIndicatorSettings: BehaviorSubject<EngineStatus> = new BehaviorSubject<EngineStatus>(EngineStatus.REMOVED);
  private _lspServerIndicatorSettings: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _bridgeIndicatorSettings: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _insertCodeSettings: BehaviorSubject<AIInsertCode> = new BehaviorSubject<AIInsertCode>(new AIInsertCode());
  private _askAI: BehaviorSubject<AIInsertCode> = new BehaviorSubject<AIInsertCode>(new AIInsertCode());
  private _showAIChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _maxAIRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _currentAiRequests: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private _refreshAIChat: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _aiKey: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _pastToAiPrompt: BehaviorSubject<AIInsertCode> = new BehaviorSubject<AIInsertCode>(new AIInsertCode());

  public pastToAiPrompt: Observable<AIInsertCode> = this._pastToAiPrompt.asObservable();
  public aiKey: Observable<string> = this._aiKey.asObservable();
  public maxAIRequests: Observable<number> = this._maxAIRequests.asObservable();
  public currentAiRequests: Observable<number> = this._currentAiRequests.asObservable();
  public workspaceNavBarHideEmitter: Observable<boolean> = this._workspaceNavBarHide.asObservable();
  public grayScreenEmitter: Observable<boolean> = this._grayScreen.asObservable();
  public darkGrayScreenEmitter: Observable<boolean> = this._darkGrayScreen.asObservable();
  public sideNavModEmitter: Observable<number> = this._sideNavMod.asObservable();
  public screenWidthEmitter: Observable<number> = this._screenWidth.asObservable();
  public showTopMenuEmitter: Observable<boolean> = this._showTopMenu.asObservable();
  public showNavBarEmitter: Observable<boolean> = this._showNavBar.asObservable();
  public showNavBarButtonEmitter: Observable<boolean> = this._showNavBarButton.asObservable();
  public showTopMenuChangeProjectButton: Observable<boolean> = this._showTopMenuChangeProjectButton.asObservable();
  public showNavTopMenuUserInfoButton: Observable<boolean> = this._showTopMenuUserInfoButton.asObservable();
  public showUserInHeaderEmitter: Observable<boolean> = this._showUserInHeader.asObservable();
  public showAdminSettingsEmitter: Observable<boolean> = this._showAdminSettings.asObservable();
  public showNoticeBoardEmitter: Observable<boolean> = this._showNoticeBoardSettings.asObservable();
  public autoSaveEmitter: Observable<boolean> = this._autoSave.asObservable();

  public engineIndicatorEmitter: Observable<EngineStatus> = this._engineIndicatorSettings.asObservable();
  public lspServerIndicatorEmitter: Observable<string> = this._lspServerIndicatorSettings.asObservable();
  public bridgeIndicatorEmitter: Observable<boolean> = this._bridgeIndicatorSettings.asObservable();
  public insertCodeEmitter: Observable<AIInsertCode> = this._insertCodeSettings.asObservable();
  public askAIEmitter: Observable<AIInsertCode> = this._askAI.asObservable();
  public hideShowAIEmitter: Observable<boolean> = this._showAIChat.asObservable();
  public refreshAIChatEmitter: Observable<string> = this._refreshAIChat.asObservable();

  private pageScroll: OverlayScrollbars;
  public transferSpinner = false;

  constructor() {
  }



  setGrayScreen(isGray: boolean) {
    this._grayScreen.next(isGray);
    if (isGray == false) {
      this._darkGrayScreen.next(isGray);
    }
  }

  setDarkGrayScreen(isDarkGray: boolean) {
    this._darkGrayScreen.next(isDarkGray);
  }

  setSideNavMod(sideNavMod: number) {
    this._sideNavMod.next(sideNavMod);
  }

  setWorkspaceNavBarHide(workspaceNavBarHide: boolean) {
    this._workspaceNavBarHide.next(workspaceNavBarHide);
  }

  setScreenWith(screenWidth) {
    this._screenWidth.next(screenWidth);
  }

  hideAllBars() {
    this.showNavBar(false, false, false, false, false);
  }

  showNavBar(isShowNavBar: boolean, isShowUserInHeader?: boolean, isShowNoticeBoard?: boolean, isShowTopMenu: boolean = true, isShowAi = undefined) {
    this._showTopMenu.next(isShowTopMenu);
    if (isShowNavBar) {
      this._showNavBar.next(isShowNavBar);
      this._showNavBarButton.next(isShowNavBar);
    } else {
      this._showNavBar.next(isShowNavBar);
      this._showAIChat.next(isShowNavBar);
    }

    this._showUserInHeader.next(isShowUserInHeader == null ? isShowNavBar : isShowUserInHeader);
    this._showNoticeBoardSettings.next(isShowNoticeBoard == null ? isShowNavBar : isShowNoticeBoard);
    if(isShowAi != undefined){
      this.hideShowAIChat(isShowAi);
    }
  }

  showTopBarButtons(isShowNavBarButton: boolean, isShowTopMenuChangeProjectButton?: boolean, isShowTopMenuUserInfoButton?: boolean) {
    this._showNavBarButton.next(isShowNavBarButton);
    this._showTopMenuChangeProjectButton.next(isShowTopMenuChangeProjectButton);
    this._showTopMenuUserInfoButton.next(isShowTopMenuUserInfoButton);
  }

  showAdminSettings(isShowAdminSettings: boolean) {
    this._showAdminSettings.next(isShowAdminSettings);
  }

  setAutoSave(autoSave: boolean) {
    this._autoSave.next(autoSave);
  }

  getAutoSave() {
    this._autoSave.getValue();
  }

  setLspStatus(lspStatus) {
    this._lspServerIndicatorSettings.next(lspStatus);
  }

  setEngine(engineStatus: EngineStatus) {
    this._engineIndicatorSettings.next(engineStatus);
  }

  setBridge(bridgeStatus: boolean) {
    this._bridgeIndicatorSettings.next(bridgeStatus);
  }

  setAiInsertCode(insertCode) {
    this._insertCodeSettings.next(new AIInsertCode(insertCode));
  }

  askAI(question) {
    this._askAI.next(new AIInsertCode(question));
  }

  addToAiPrompt(addToPrompt) {
    this._pastToAiPrompt.next(new AIInsertCode(addToPrompt, false));
  }

  refreshAIChat(userProjectId) {
    this._refreshAIChat.next(userProjectId);
  }

  hideShowAIChat(show) {
    this._showAIChat.next(show);
  }

  setMaxAIRequests(maxAiRequests: number) {
    this._maxAIRequests.next(maxAiRequests);
  }

  setAIKey(aiKey: string) {
    this._aiKey.next(aiKey);
  }

  setCurrentAIRequests(currentAiRequests: number) {
    this._currentAiRequests.next(currentAiRequests);
  }

  setPageScroll(pageScroll: OverlayScrollbars) {
    this.pageScroll = pageScroll;
  }

  scrollX(x: number, duration: number = 300) {
    this.pageScroll.scroll({x, y: this.pageScroll.getState().overflowAmount.y}, duration);
  }

  scrollY(y: number, duration: number = 300) {
    this.pageScroll.scroll({x: this.pageScroll.getState().overflowAmount.x, y}, duration);
  }

  scroll(x: number, y: number, duration: number = 300) {
    this.pageScroll.scroll({x, y}, duration);
  }

}
