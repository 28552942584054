import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {ParallelSerial} from "../../../runs/shared/models/parallelSerial";
import {RunParamsType} from "../../../runs/shared/models/runParamsType";
import {CloudConfigService} from "../../../../shared/services/cloud-config.service";

@Component({
  selector: 'app-api-key-info',
  templateUrl: './api-key-info.component.html',
  styleUrls: ['./api-key-info.component.css']
})
export class ApiKeyInfoComponent implements OnInit {
  ParallelSerialEnum: any = ParallelSerial;
  RunParamsTypeEnum = RunParamsType;
  errorMessage = '';

  @Input('parallelSerial') parallelSerial: ParallelSerial = this.ParallelSerialEnum.SERIAL;
  @Output('parallelSerialChange') parallelSerialChange = new EventEmitter<ParallelSerial>();

  @Input('runParamsType') runParamsType: RunParamsType = this.RunParamsTypeEnum.PRIORITY;
  @Output('runParamsTypeChange') runParamsTypeChange = new EventEmitter<RunParamsType>();

  @Input('parallelSerialDataDriven') parallelSerialDataDriven: ParallelSerial = this.ParallelSerialEnum.SERIAL;
  @Output('parallelSerialDataDrivenChange') parallelSerialDataDrivenChange = new EventEmitter<ParallelSerial>();

  @Input('repeatRun') repeatRun = 1;
  @Output('repeatRunChange') repeatRunChange = new EventEmitter<number>();

  @Input('params') params = '';
  @Output('paramsChange') paramsChange = new EventEmitter<string>();

  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  apiRequestUrl;
  public curlyBrace = '{';
  constructor(
    public tokensService: MaxtafTokensStorageService,
    public cloudConfigService: CloudConfigService,
    public dialogRef: MatDialogRef<ApiKeyInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {

    }
  ) {
    this.apiRequestUrl = this.cloudConfigService.runCaseForApiWithName() + '?testName=';
  }

  ngOnInit(): void {
  }
}
