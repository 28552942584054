<h1 mat-dialog-title class="text-center">You Have Been Idle!</h1>
<div mat-dialog-content>
  <p>You will time out in {{seconds}} seconds!</p>
</div>
<div mat-dialog-actions>
  <button id="noButton" mat-button (click)="logout()" color="warn">
    Logout
  </button>

  <button id="yesButton" mat-button (click)="reset()" color="primary">
    Stay
  </button>
</div>
