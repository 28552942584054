<div class="error-message"
     [ngClass]="{
     'clean-design': cleanDesign,
      'error-color': !infoColors,
       'info-color': infoColors
       }"
     *ngIf="showErrorAlert"
     [class.show-in-dialog]="showInDialog" [class.full-width]="fullWidth">
  <div [ngClass]="{'clean-design': cleanDesign, 'alert-danger': !infoColors}"
       class="alert alert-dismissible fade show"
       [class.stick-to-top-of-bottom-component]="hasMoreDetails">
    <h5 *ngIf="hasMoreDetails" class="alert-heading">{{ errorTitle }}</h5>
    <hr *ngIf="hasMoreDetails">
    <overlay-scrollbars
      #navTreeScrolling
      [options]="{ scrollbars: { autoHide: 'l' } }"
      [ngStyle]="{'width': '100%'}"
    >
      <p class="mb-0">{{ errorMessage }}</p>
    </overlay-scrollbars>
    <button (click)="hideErrorAlert()" *ngIf="!removeClose" type="button" class="close" data-dismiss="alert"
            aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <mat-expansion-panel [ngClass]="{'clean-design': cleanDesign}" *ngIf="hasMoreDetails" hideToggle
                       #errorMessageExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mx-icon *ngIf="!errorMessageExpansionPanel.expanded" class="white_arrows mr-3" mx="collapse"></mx-icon>
        <mx-icon *ngIf="errorMessageExpansionPanel.expanded" class="white_arrows mr-3" mx="expand"></mx-icon>
        More details
      </mat-panel-title>

      <mat-panel-description *ngIf="errorMessageExpansionPanel.expanded" class="mr-0" style="text-align: right">
        <button mat-button (click)="copyToClipboard(); $event.stopPropagation()">
          <mx-icon mx="copy"></mx-icon>
          <span>Copy to clipboard</span>
        </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <overlay-scrollbars
      #navTreeScrolling
      [options]="{ scrollbars: { autoHide: 'l' } }"
    >
    <pre>
      {{ moreDetails }}
    </pre>
    </overlay-scrollbars>
  </mat-expansion-panel>
</div>
