import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import * as OverlayScrollbars from 'overlayscrollbars';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';

@Component({
  selector: 'mx-options',
  templateUrl: './mx-options.component.html',
  styleUrls: ['./mx-options.component.css']
})
export class MxOptionsComponent implements OnInit {

  @Input('allowShadow') allowShadow = true;
  @Input('showButtons') showButtons = true;
  @Input('showButtonText') showButtonText = true;
  @Input('showTooltip') showTooltip = false;
  @Input('smallHeight') smallHeight = false;
  @Input('defaultColors') defaultColors = true;
  @Input('iconMarginBottom') iconMarginBottom = true;


  @Input('bodyBottomBorder') bodyBottomBorder = true;
  @Input('headerBottomBorder') headerBottomBorder = true;
  bodyColor = 'whitesmoke';

  @Input('bodyColor') set setBodyColor(bodyColor: string) {
    if (bodyColor == null) {
      this.bodyColor = 'whitesmoke';
    } else {
      this.bodyColor = bodyColor;
    }
  }

  isSearchCollapsed = false;

  @ViewChild('optionsScroll') optionsScroll: OverlayScrollbarsComponent;

  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  constructor() {
  }

  ngOnInit(): void {
  }

  scrollLeft() {
    const overlayScrollbars: OverlayScrollbars = this.optionsScroll.osInstance();
    overlayScrollbars.scroll(overlayScrollbars.scroll().position.x - 200, 300);
  }

  scrollRight() {
    const overlayScrollbars: OverlayScrollbars = this.optionsScroll.osInstance();
    overlayScrollbars.scroll(overlayScrollbars.scroll().position.x + 200, 300);
  }

  hasOverflowX(): boolean {
    if (this.optionsScroll == undefined || this.optionsScroll.osInstance() == undefined) {
      return false;
    }
    const overlayScrollbars: OverlayScrollbars = this.optionsScroll.osInstance();
    return overlayScrollbars.getState().hasOverflow.x;
  }

  setBody() {
    if (this.isSearchCollapsed) {
      this.openBody();
    } else {
      this.closeBody();
    }
  }

  openBody() {
    this.isSearchCollapsed = true;
    this.expansionPanel.open();
  }

  closeBody() {
    this.isSearchCollapsed = false;
    this.expansionPanel.close();
  }

  toggleBody() {
    this.isSearchCollapsed = !this.isSearchCollapsed;
    this.setBody();
  }

}
