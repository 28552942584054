<h4 mat-dialog-title
    style="font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;">
  Workspace import logs
</h4>

<div mat-dialog-content>
  <app-import-case-info-option
    [checkPaths]="getImportCaseInfoListOptionsCheckPathObject()"
    [(searchParams)]="params.search"

    (filter)="filter($event)"
    (refresh)="refreshImportCaseInfo();"
    (delete)="deleteImportCaseInfo();">

  </app-import-case-info-option>

  <app-import-case-info-list #list
                             [autoSetItems]="true"

                             [searchParams]="params.search"
                             [pageParams]="params.page"
                             (pageParamsChange)="pageParamsChange($event)"
                             (pagesInfoChanges)="setPageInfoChanges($event)"

                             (clickedItem)="clickedImportCaseInfo($event)"
                             (selected)="selected($event)">

  </app-import-case-info-list>
</div>
<div mat-dialog-actions class="d-flex float-right px-4 py-2">
  <button id="cancelButton" mat-button [mat-dialog-close]="false" style="float:right">Close</button>
</div>
