import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiWrapperComponent} from './ai-wrapper.component';
import {AiTitleModule} from '../ai-title/ai-title.module';
import {AiTextModule} from '../ai-text/ai-text.module';
import {AiCodeModule} from '../ai-code/ai-code.module';
import {AiInputModule} from '../ai-input/ai-input.module';
import {AiMessageModule} from "../ai-message/ai-message.module";
import {OverlayscrollbarsModule} from "overlayscrollbars-ngx";
import {AiLoadResponseModule} from "../ai-load-response/ai-load-response.module";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {ExportAiChatModule} from "../export-ai-chat/export-ai-chat.module";
import {
  AddProjectParamDialogModule
} from "../../../users/dialogs/add-project-param-dialog/add-project-param-dialog.module";
import {AngularSplitModule} from "angular-split";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from "@angular/material/menu";
import {ClickOutsideModule} from "ng-click-outside";
import {MatTreeModule} from "@angular/material/tree";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NavTreeFileIconModule} from "../../../storage/components/nav-tree-file-icon/nav-tree-file-icon.module";
import {UnzipDialogModule} from "../../../storage/components/unzip-dialog/unzip-dialog.module";
import {PasteDialogModule} from "../../../storage/components/paste-dialog/paste-dialog.module";
import {RenameDialogModule} from "../../../storage/components/rename-dialog/rename-dialog.module";
import {ErrorModule} from "../../../mx/error/error.module";
import {DndModule} from "../../../mx/dnd/dnd.module";
import {
  FilePropertiesDialogModule
} from "../../../storage/components/file-properties-dialog/file-properties-dialog.module";
import {ZipDialogModule} from "../../../storage/components/zip-dialog/zip-dialog.module";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {DownloadFileDialogModule} from "../../../storage/components/download-file-dialog/download-file-dialog.module";
import {YesNoDialogModule} from "../../../dialogs/yes-no-dialog/yes-no-dialog.module";
import {UploadDialogModule} from "../../../storage/components/upload-dialog/upload-dialog.module";
import {
  CreateNewFolderDialogModule
} from "../../../storage/components/create-new-folder-dialog/create-new-folder-dialog.module";
import {
  CreatePageObjectDialogModule
} from "../../../cases/components/create-page-object-dialog/create-page-object-dialog.module";
import {SearchModule} from "../../../storage/components/file-search-dialog/search.module";
import {AiOptionsDialogComponent} from "../ai-options-dialog/ai-options-dialog.component";
import {AiOptionsDialogModule} from "../ai-options-dialog/ai-options-dialog.module";


@NgModule({
  declarations: [AiWrapperComponent],
  imports: [
    CommonModule,
    AiTitleModule,
    AiTextModule,
    AiCodeModule,
    AiInputModule,
    AiMessageModule,
    OverlayscrollbarsModule,
    AiLoadResponseModule,
    IconModule,
    MatButtonModule,
    ExportAiChatModule,
    AddProjectParamDialogModule,
    AngularSplitModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    ClickOutsideModule,
    CommonModule,
    MatTreeModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    NavTreeFileIconModule,
    IconModule,
    OverlayscrollbarsModule,
    UnzipDialogModule,
    PasteDialogModule,
    RenameDialogModule,
    ClickOutsideModule,
    ErrorModule,
    DndModule,
    FilePropertiesDialogModule,
    ZipDialogModule,
    DragDropModule,
    DownloadFileDialogModule,
    YesNoDialogModule,
    UploadDialogModule,
    CreateNewFolderDialogModule,
    CreatePageObjectDialogModule,
    SearchModule,
    AiOptionsDialogModule,
    AiInputModule
  ], exports: [AiWrapperComponent]
})
export class AiWrapperModule {
}
