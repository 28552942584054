import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CloudConfigService {

  constructor(
    private configService: ConfigService
  ) {
  }

  private DEFAULT_PATH_VAR = this.configService.getDefaultPathVar();

  private API_URL = this.configService.getHostURL() + this.configService.getPrefix() + '/maxtaf';

  private CASES_PATH = 'cases';
  private ARCHIVED_PATH = 'archived';
  private CASE_RUN_RESULT_PATH = 'caseRunResult';
  private CASE_RUN_RESULT_PERCENTAGE_OF_COMPLETION_PATH = this.CASE_RUN_RESULT_PATH + '/' + 'updatePercentageOfCompletion';
  private CASE_RUN_RESULT_APPEND_LOG_PATH = this.CASE_RUN_RESULT_PATH + '/' + 'appendLog';
  private CASE_RUN_RESULT_APPEND_SYSTEM_LOG = this.CASE_RUN_RESULT_PATH + '/' + 'appendSystemLog';
  private CASE_RUN_RESULT_APPEND_ALLURE_RESULT = this.CASE_RUN_RESULT_PATH + '/' + 'appendAllureResult';
  private CASE_RUN_RESULT_CREATE_REPORT = this.CASE_RUN_RESULT_PATH + '/' + 'createReport';
  private CASE_RUN_RESULT_SET_REPORT_STATUS = this.CASE_RUN_RESULT_PATH + '/' + 'setReportStatus';
  private CASE_RUN_RESULT_ADD_TEST_REPORT = this.CASE_RUN_RESULT_PATH + '/' + 'addTestReport';
  private CASE_RUN_RESULT_ADD_TEST_REPORT_STEP = this.CASE_RUN_RESULT_PATH + '/' + 'addTestReportStep';
  private UPDATE_GRID_NODE_HOST_PATH = this.CASE_RUN_RESULT_PATH + '/' + 'gridNodeHost';
  private UPDATE_VNC_STATUS_PATH = this.CASE_RUN_RESULT_PATH + '/' + 'vncStatus';
  private CASE_SUITES_PATH = 'caseSuites';
  private RUNS_PATH = 'runs';
  private PARENT_PATH = 'parent';
  private CHILDREN_PATH = 'children';
  private EXPORT_PATH = 'export';
  private IMPORT_PATH = 'import';
  private SUITES_PATH = 'suites';
  private DUPLICATE_PATH = 'duplicate';
  private FAILURES_PATH = 'failures';
  private SUITES_NAMES_PATH = this.SUITES_PATH + '/name';
  private REPLACE_PATH = this.SUITES_PATH + 'replace';
  private SUITES_REPLACE_PATH = this.SUITES_PATH + '/' + this.REPLACE_PATH;
  private SUITES_CASE_PATH = this.SUITES_PATH + '/' + this.CASES_PATH;
  private SUITES_ITEMS_PATH = 'items';
  private MAXTAF_RUN_PATH = '' + this.RUNS_PATH;
  private MAXTAF_API_RUN_PATH = 'api/' + this.RUNS_PATH;
  private MAXTAF_PROJECT_RUN_PARAMS_PATH = 'projectRunParams';
  private PARAMS_PATH = 'params';
  private PARAM_PATH = 'param';
  private MAXTAF_RUN_PARAMS_PATH = 'runParams';
  private MAXTAF_BUILD_RUN_PARAMS_PATH = 'buildRunParams';
  private PROJECT_PATH = 'project';
  private STOP_PATH = 'stop';
  private SELF_PATH = 'self';
  private TEMPLATE_LIST_PATH = 'getTemplatesList';
  private SAVE_SCREENSHOT_FOR_CASE_PATH = this.CASE_RUN_RESULT_PATH + '/' + 'saveScreenshot';
  private PERFORMANCE_PATH = 'perf';
  private PERFORMANCE_RECORD_PATH = this.PERFORMANCE_PATH + '/record';
  private RUN_MASTER_PARAMS = 'masterParams';
  private MAXTAF_RUN_REPORT_PATH = 'runReport';
  private CAN_RUN_PATH = 'canRun';
  private PROJECT_PARAMS_PATH = 'projectParams';
  private RELEASE_RUNS_PATH = 'releaseRuns';
  private FILE_LIST_PATH = 'listFiles';
  private FILE_UPLOAD_PATH = 'uploadFile';
  private ALLURE_PATH = 'allure';
  private REPORT_PATH = 'report';
  private UPDATE_ALL_PROJECT_DATABASES_PATH = 'updateAllProjectDatabases';
  private ROLLBACK_PROJECTS_PATH = 'rollbackToDateAllProjectDatabases';
  private FIND_ONE_PATH = 'findOne';
  private INCREMENT_PATH = 'increment';
  private SUITE_NAME_PATH = this.SUITES_PATH + '/name';
  private NAME_PATH = 'name';
  private CASE_NAME_PATH = this.CASES_PATH + '/name';
  private RUN_SUITE_NAME_START_PATH = this.SUITE_NAME_PATH;
  private RUN_CASE_NAME_START_PATH = this.CASE_NAME_PATH;
  private RUN_CASE_START_PATH = this.CASES_PATH;
  private RUN_SUITE_START_PATH = this.SUITES_PATH;
  private FILE_PATH = 'file';
  private TEMPLATE_PATH = 'template';
  private PRIVATE_RUN_PROGRESS_PATH = 'privateRunProgress';
  private ENGINE_PATH = 'engine';
  private ENGINE_ADDRESS_PATH = this.ENGINE_PATH + '/address';
  private BUILD_PARAM_PATH = 'value';
  private CODE_HISTORY_PATH = 'code/history';
  private REVERT_CASE_CODE_HISTORY_PATH = 'code/revert';
  private CASE_METADATA_PATH = 'metadata';
  private ACTIVE_PATH = 'active';
  private COMPILED_PATH = 'compiled';
  private TRANSPILED_PATH = 'transpiled';
  private TRANSPILED2_PATH = 'transpiled2';
  private CHANGE_SEQUENCE_PATH = 'changeSequence';
  private LIST_PATH = 'list';
  private IS_ACTIVE_PATH = 'isActive';
  private DELETE_PATH = 'delete';
  private COMPILATION_PATH = 'compilation';
  private PATHS_PATH = 'paths';
  private JAVA_BUILD_AUTOMATION_TOOL_PATH = 'javaBuildAutomationTool';
  private UPDATE_CASE_FILE_LOCATION_PATH = 'caseFileLocation';

  private SUITES_REPLACE_URL = this.API_URL + '/' + this.SUITES_REPLACE_PATH;
  private SUITES_NAME_URL = this.API_URL + '/' + this.SUITES_NAMES_PATH;
  private SUITES_CASE_URL = this.API_URL + '/' + this.SUITES_CASE_PATH;
  private ALLURE_REPORT_URL = this.API_URL + '/' + this.ALLURE_PATH + '/' + this.REPORT_PATH;
  private FILE_URL = this.API_URL + '/' + this.FILE_PATH;
  private CASES_URL = this.API_URL + '/' + this.CASES_PATH;
  private MAXTAF_COMPILE_INFO_URL = this.API_URL + '/compile/info';
  private CASES_OR_SUITE_URL = this.CASES_URL + '/or/' + this.SUITES_PATH;
  private ACTIVE_CASES_BY_NAME_URL = this.API_URL + '/' + this.CASES_PATH + '/name';
  private CASES_METADATA_BY_FILE_LOCATION_URL = this.CASES_URL + '/byFile';
  private ARCHIVED_URL = this.API_URL + '/' + this.ARCHIVED_PATH;
  private ARCHIVED_CASES_URL = this.ARCHIVED_URL + '/' + this.CASES_PATH;
  private ARCHIVED_SUITES_URL = this.ARCHIVED_URL + '/' + this.SUITES_PATH;
  private UPDATE_CASE_RUN_RESULT_PERCENTAGE_OF_COMPLETION_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_PERCENTAGE_OF_COMPLETION_PATH;
  private UPDATE_CASE_RUN_RESULT_APPEND_LOG_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_APPEND_LOG_PATH;
  private UPDATE_CASE_RUN_RESULT_APPEND_SYSTEM_LOG_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_APPEND_SYSTEM_LOG;
  private UPDATE_CASE_RUN_RESULT_APPEND_ALLURE_RESULT_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_APPEND_ALLURE_RESULT;
  private UPDATE_CASE_RUN_RESULT_CREATE_REPORT_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_CREATE_REPORT;
  private UPDATE_CASE_RUN_RESULT_SET_REPORT_STATUS_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_SET_REPORT_STATUS;
  private UPDATE_CASE_RUN_RESULT_ADD_TEST_REPORT_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_ADD_TEST_REPORT;
  private UPDATE_CASE_RUN_RESULT_ADD_TEST_REPORT_STEP_URL = this.API_URL + '/' + this.CASE_RUN_RESULT_ADD_TEST_REPORT_STEP;
  private CASE_SUITES_URL = this.API_URL + '/' + this.CASE_SUITES_PATH;
  private CASE_SUITES_CHILDREN_URL = this.API_URL + '/' + this.CASE_SUITES_PATH + '/' + this.CHILDREN_PATH;
  private CASE_SUITES_PARENT_URL = this.API_URL + '/' + this.CASE_SUITES_PATH + '/' + this.PARENT_PATH;
  private RUNS_URL = this.API_URL + '/' + this.RUNS_PATH;
  private EXPORT_URL = this.API_URL + '/' + this.EXPORT_PATH;
  private EXPORT_JSON_PROJECT_PARAMS_URL = this.API_URL + '/' + this.EXPORT_PATH + '/' + 'json' + '/' + this.PROJECT_PARAMS_PATH;
  private IMPORT_URL = this.API_URL + '/' + this.IMPORT_PATH;
  private SUITES_URL = this.API_URL + '/' + this.SUITES_PATH;
  private IS_COMPILED_FILE_URL = this.FILE_URL + '/' + this.COMPILED_PATH;
  private EXPORT_JSON_CASES_URL = this.EXPORT_URL + '/' + 'json' + '/' + this.CASES_PATH;
  private EXPORT_JSON_STRING_CASES_URL = this.EXPORT_URL + '/' + 'json/string' + '/' + this.CASES_PATH;
  private EXPORT_XML_CASES_URL = this.EXPORT_URL + '/' + 'xml' + '/' + this.CASES_PATH;
  private EXPORT_TRANSFER_CASES_URL = this.EXPORT_URL + '/' + 'transfer' + '/' + this.CASES_PATH;
  private EXPORT_JSON_SUITES_URL = this.EXPORT_URL + '/' + 'json' + '/' + this.SUITES_PATH;
  private EXPORT_JSON_STRING_SUITES_URL = this.EXPORT_URL + '/' + 'json/string' + '/' + this.SUITES_PATH;
  private EXPORT_XML_SUITES_URL = this.EXPORT_URL + '/' + 'xml' + '/' + this.SUITES_PATH;
  private EXPORT_TRANSFER_SUITES_URL = this.EXPORT_URL + '/' + 'transfer' + '/' + this.SUITES_PATH;
  private MAXTAF_PROJECT_RUN_PARAMS_URL = this.API_URL + '/' + this.MAXTAF_PROJECT_RUN_PARAMS_PATH;
  private MAXTAF_CONFIGURATION_PATH = 'configurationParams';
  private MAXTAF_CONFIGURATION_PARAMS_URL = this.API_URL + '/' + this.MAXTAF_CONFIGURATION_PATH;

  private MAXTAF_RUN_PARAMS_URL = this.API_URL + '/' + this.MAXTAF_RUN_PARAMS_PATH;
  private MAXTAF_BUILD_RUN_PARAMS_URL = this.API_URL + '/' + this.MAXTAF_BUILD_RUN_PARAMS_PATH;
  private MAXTAF_BUILD_RUN_PARAM_VALUE_URL = this.API_URL + '/' + this.MAXTAF_BUILD_RUN_PARAMS_PATH + '/' + this.BUILD_PARAM_PATH;
  private PERFORMANCE_RECORD_URL = this.API_URL + '/' + this.PERFORMANCE_RECORD_PATH;
  private PROJECT_URL = this.API_URL + '/' + this.PROJECT_PATH;
  private COMPLETED_RUN_URL = this.API_URL + '/' + this.CASES_PATH + '/' + this.RUNS_PATH + '/completed';
  private UPDATE_GRID_NODE_HOST_URL = this.API_URL + '/' + this.UPDATE_GRID_NODE_HOST_PATH;
  private UPDATE_VNC_STATUS_URL = this.API_URL + '/' + this.UPDATE_VNC_STATUS_PATH;
  private SAVE_SCREENSHOT_FOR_CASE_URL = this.API_URL + '/' + this.SAVE_SCREENSHOT_FOR_CASE_PATH;
  private MAXTAF_RUN_REPORT_URL = this.API_URL + '/' + this.MAXTAF_RUN_REPORT_PATH;
  private FILE_LIST_URL = this.API_URL + '/' + this.FILE_LIST_PATH;
  private FILE_UPLOAD_URL = this.API_URL + '/' + this.FILE_UPLOAD_PATH;
  private PRIVATE_RUN_PROGRESS_URL = this.API_URL + '/' + this.PRIVATE_RUN_PROGRESS_PATH;
  private ENGINE_URL = this.API_URL + '/' + this.ENGINE_PATH;
  private CODE_HISTORY_URL = this.API_URL + '/' + this.CASES_PATH + '/' + this.CODE_HISTORY_PATH;
  private REVERT_CASE_CODE_HISTORY_URL = this.API_URL + '/' + this.CASES_PATH + '/' + this.REVERT_CASE_CODE_HISTORY_PATH;
  private TEMPLATE_URL = this.API_URL + '/' + this.TEMPLATE_PATH;
  private JAVA_BUILD_AUTOMATION_TOOL_URL = this.API_URL + '/' + this.JAVA_BUILD_AUTOMATION_TOOL_PATH;
  private UPDATE_CASE_FILE_LOCATION_URL = this.API_URL + '/' + this.UPDATE_CASE_FILE_LOCATION_PATH;

  private static getHost() {
    return window.location.protocol + '//' + window.location.hostname + (window.location.hostname === 'localhost' ? ':4200' : '');
  }

  public getCases() {
    return this.CASES_URL;
  }

  public getTestAndSourceDirectory() {
    return this.JAVA_BUILD_AUTOMATION_TOOL_URL + '/' + this.PATHS_PATH;
  }

  public updateCaseFileLocation(caseFileLocationId: string = this.DEFAULT_PATH_VAR) {
    return this.UPDATE_CASE_FILE_LOCATION_URL + '/' + caseFileLocationId;
  }

  public getCase(id: string = this.DEFAULT_PATH_VAR, projectId?: string) {
    return this.CASES_URL + '/' + id + (projectId != undefined ? '?' + this.configService.getHeaderProjectId() + '=' + projectId : '');
  }

  public getCaseByFileLocation() {
    return this.CASES_METADATA_BY_FILE_LOCATION_URL;
  }

  public addCase(templateName?: string) {
    return this.CASES_URL + '' + (templateName ? '?templateName=' + templateName : '');
  }

  public duplicateCase(caseId: string = this.DEFAULT_PATH_VAR, caseName?: string) {
    return this.CASES_URL + '/' + caseId + '/' + this.DUPLICATE_PATH + ((caseName != undefined) ? '?caseName=' + caseName : '');
  }

  public updateCase(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id;
  }

  public updateCaseParams(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/' + this.PARAMS_PATH;
  }

  public deleteCase(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id;
  }

  public deleteCases() {
    return this.CASES_URL;
  }

  public getCaseSuites(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/suites';
  }

  public removeCaseFromSuites(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/' + this.SUITES_PATH;
  }

  public getCaseAndSuiteParent(caseOrSuiteId = this.DEFAULT_PATH_VAR) {
    return this.CASES_OR_SUITE_URL + '/' + caseOrSuiteId;
  }

  public getCaseAndSuiteParentActive(caseOrSuiteId = this.DEFAULT_PATH_VAR) {
    return this.CASES_OR_SUITE_URL + '/' + caseOrSuiteId;
  }

  public runCaseWithId(caseId: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + caseId + '/' + this.MAXTAF_RUN_PATH;
  }

  public compileCase(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/' + this.COMPILATION_PATH;
  }

  public isCompiled(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/' + this.COMPILED_PATH;
  }

  public isTranspiled(id: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + id + '/' + this.TRANSPILED_PATH;
  }

  public compileCases(queryParams: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + this.COMPILATION_PATH + '' + ((queryParams == '') ? '' : '?' + queryParams);
  }

  public compileCasesAsync(queryParams: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + this.COMPILATION_PATH + '/async' + ((queryParams == '') ? '' : '?' + queryParams);
  }

  public compileCasesInfo(id: string = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_COMPILE_INFO_URL + '/' + id;
  }

  public compileCasesInfos() {
    return this.MAXTAF_COMPILE_INFO_URL;
  }

  public getCaseCompileInfos(id: string = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_COMPILE_INFO_URL + '/' + id + '/cases/info';
  }

  public stopCompileRequest(id: string = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_COMPILE_INFO_URL + '/' + id + '/stop';
  }

  public getCaseCodeHistory() {
    return this.CODE_HISTORY_URL;
  }

  public revertCaseCode(caseId: string = this.DEFAULT_PATH_VAR, caseCodeHistoryId: string = this.DEFAULT_PATH_VAR) {
    return this.REVERT_CASE_CODE_HISTORY_URL + '/' + caseId + '/' + caseCodeHistoryId;
  }

  public getSuiteRuns(suiteId: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_URL + '/' + suiteId + '/' + this.RUNS_PATH;
  }

  public runSuiteWithId(suiteId: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_URL + '/' + suiteId + '/' + this.MAXTAF_RUN_PATH;
  }

  public addSuite() {
    return this.SUITES_URL;
  }

  public duplicateSuite(suiteId: string = this.DEFAULT_PATH_VAR, suiteName?: string) {
    return this.SUITES_URL + '/' + suiteId + '/' + this.DUPLICATE_PATH + ((suiteName != undefined) ? '?suiteName=' + suiteName : '');
  }

  public updateSuite(id: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_URL + '/' + id;
  }

  public moveInTrashSuite(id: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_URL + '/' + id;
  }

  public getSuites() {
    return this.SUITES_URL;
  }


  public deleteSuites() {
    return this.ARCHIVED_URL + '/' + this.SUITES_PATH;
  }

  public unTrashSuites() {
    return this.ARCHIVED_URL + '/' + this.SUITES_PATH;
  }

  public moveInTrashSuites() {
    return this.SUITES_URL;
  }


  public getSuite(id: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_URL + '/' + id;
  }

  public getChildrenOfParent(parentId = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_PARENT_URL + '/' + parentId + '/' + this.CHILDREN_PATH;
  }

  public addItemToParentList(caseId: string = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_CHILDREN_URL + '/' + caseId + '/' + this.LIST_PATH;
  }

  public addItemListToParent(parentId: string = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_PARENT_URL + '/' + parentId + '/' + this.LIST_PATH;
  }

  public changeSequence(caseSuiteID: string = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_URL + '/' + caseSuiteID + '/' + this.CHANGE_SEQUENCE_PATH;
  }

  public remove(caseSuiteID: string = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_URL + '/' + caseSuiteID;
  }

  public changeIsActive(caseSuiteId: string = this.DEFAULT_PATH_VAR) {
    return this.CASE_SUITES_URL + '/' + caseSuiteId + '/' + this.IS_ACTIVE_PATH;
  }

  public getCasesFromTrash() {
    return this.ARCHIVED_CASES_URL;
  }

  public getCaseArchived(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_CASES_URL + '/' + id;
  }

  public unTrashCase(id: string = this.DEFAULT_PATH_VAR, newCaseName?: string) {
    return this.ARCHIVED_CASES_URL + '/' + id + (newCaseName == undefined ? '' : '?newCaseName=' + newCaseName);
  }

  public unTrashCases() {
    return this.ARCHIVED_CASES_URL;
  }

  public deleteCaseArchived(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_CASES_URL + '/' + id;
  }

  public deleteCasesArchived() {
    return this.ARCHIVED_CASES_URL;
  }

  public deleteRunResult(id: string = this.DEFAULT_PATH_VAR) {
    return this.RUNS_URL + '/' + id;
  }

  public deleteRunResults() {
    return this.RUNS_URL;
  }

  public getSuitesArchived(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_URL + '/' + this.SUITES_PATH + '/' + id;
  }

  public removeCaseFromSuitesArchived(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_CASES_URL + '/' + id + '/' + this.SUITES_PATH;
  }

  public getCaseRunsArchived(caseId: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_CASES_URL + '/' + caseId + '/' + this.RUNS_PATH;
  }

  public getSuiteRunsArchived(suiteId: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_CASES_URL + '/' + suiteId + '/' + this.RUNS_PATH;
  }

  public deleteSuite(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_SUITES_URL + '/' + id;
  }

  public getSuitesFromTrash() {
    return this.ARCHIVED_SUITES_URL;
  }

  public getSuiteArchived(id: string = this.DEFAULT_PATH_VAR) {
    return this.ARCHIVED_SUITES_URL + '/' + id;
  }

  public unTrashSuite(id: string = this.DEFAULT_PATH_VAR, newSuiteName?: string) {
    return this.ARCHIVED_SUITES_URL + '/' + id + (newSuiteName == undefined ? '' : '?newSuiteName=' + newSuiteName);
  }

  public getRunResults() {
    return this.RUNS_URL;
  }

  public changePreventDeletionField(runId) {
    return this.RUNS_URL + '/' + runId + "/preventDeletion";
  }

  public getRunResult(id = this.DEFAULT_PATH_VAR) {
    return this.RUNS_URL + '/' + id;
  }

  public getRunChildren(id = this.DEFAULT_PATH_VAR) {
    return this.RUNS_URL + '/' + id + '/' + this.CHILDREN_PATH;
  }

  public stopRunResult(runId = this.DEFAULT_PATH_VAR) {
    return this.RUNS_URL + '/' + runId + '/' + this.STOP_PATH;
  }

  public stopAllRunResult() {
    return this.RUNS_URL + '/' + this.STOP_PATH;
  }

  public selfStopAllRunResult() {
    return this.RUNS_URL + '/' + this.SELF_PATH + '/' + this.STOP_PATH;
  }

  public getActiveRunResult() {
    return this.RUNS_URL + '/' + this.ACTIVE_PATH;
  }

  public getActiveRunResultList() {
    return this.RUNS_URL + '/' + this.ACTIVE_PATH + '/' + this.LIST_PATH;
  }

  public getProjectParams() {
    return this.MAXTAF_PROJECT_RUN_PARAMS_URL;
  }


  public getConfigurationParams() {
    return this.MAXTAF_CONFIGURATION_PARAMS_URL;
  }

  public getConfigurationParam(paramName: string) {
    return this.MAXTAF_CONFIGURATION_PARAMS_URL + '/' + this.FIND_ONE_PATH + '?paramName=' + paramName;
  }

  public setConfigurationParams() {
    return this.MAXTAF_CONFIGURATION_PARAMS_URL;
  }

  public getProjectParam(paramName: string) {
    return this.MAXTAF_PROJECT_RUN_PARAMS_URL + '/' + this.FIND_ONE_PATH + '?paramName=' + paramName;
  }

  public deleteProjectParam(paramName: string) {
    return this.MAXTAF_PROJECT_RUN_PARAMS_URL + '?paramName=' + paramName;
  }

  public createProjectParams() {
    return this.MAXTAF_PROJECT_RUN_PARAMS_URL;
  }

  public setProjectParams() {
    return this.MAXTAF_PROJECT_RUN_PARAMS_URL;
  }

  public buildParams(childId: string = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_BUILD_RUN_PARAMS_URL + '/' + childId;
  }

  public buildParamsWithParentId(childId: string = this.DEFAULT_PATH_VAR, parentId: string = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_BUILD_RUN_PARAMS_URL + '/' + childId + '/' + parentId;
  }

  public buildParamsWithRequestParams(requestParams: string) {
    return this.MAXTAF_BUILD_RUN_PARAMS_URL + '?requestParams=' + requestParams;
  }

  public generateReport() {
    return this.ALLURE_REPORT_URL;
  }

  public deleteReport(reportId: string = this.DEFAULT_PATH_VAR) {
    return this.ALLURE_REPORT_URL + '/' + reportId + '/' + this.DELETE_PATH;
  }

  public getTemplatesList() {
    return this.TEMPLATE_URL + '/' + this.TEMPLATE_LIST_PATH;
  }

  public exportTemplate(id?: string[], templateName?: string, templateDescription?: string) {
    return this.TEMPLATE_URL + '/' + this.EXPORT_PATH + '?ids%5B%5D=' + id + '&templateName=' + templateName + '&templateDescription=' + templateDescription;
  }

  public compileFile(fileLocation?: string) {
    return this.FILE_URL + '/' + this.COMPILATION_PATH + '?fileLocation=' + fileLocation;
  }

  public isCompiledFile(fileLocation: string) {
    return this.IS_COMPILED_FILE_URL + '?fileLocation=' + fileLocation;
  }

  public getPrivateRunProgress(id: string) {
    return this.PRIVATE_RUN_PROGRESS_URL + '?id=' + id;
  }

  public replaceWithActiveCase(archivedCaseId: string = this.DEFAULT_PATH_VAR) {
    return this.CASES_URL + '/' + this.REPLACE_PATH + '/' + archivedCaseId;
  }

  public replaceWithActiveSuite(archivedSuiteId: string = this.DEFAULT_PATH_VAR) {
    return this.SUITES_REPLACE_URL + '/' + archivedSuiteId;
  }

  public exportCases(exportType = 'json') {
    return this.EXPORT_URL + '/' + exportType + '/' + this.CASES_PATH;
  }

  public exportSuites(exportType = 'json') {
    return this.EXPORT_URL + '/' + exportType + '/' + this.SUITES_PATH;
  }

  public exportProjectParams() {
    return this.EXPORT_JSON_PROJECT_PARAMS_URL;
  }

  public importCases() {
    return this.IMPORT_URL + '/' + this.CASES_PATH;
  }

  public importSuites() {
    return this.IMPORT_URL + '/' + this.SUITES_PATH;
  }

  public importProjectParams() {
    return this.IMPORT_URL + '/' + this.PROJECT_PARAMS_PATH;
  }

  public runReport(runId = this.DEFAULT_PATH_VAR) {
    return this.MAXTAF_RUN_REPORT_URL + '/' + runId;
  }

  public showReport(res = this.DEFAULT_PATH_VAR) {
    return this.ALLURE_REPORT_URL + '/' + res;
  }

  public runCaseForApiWithName() {
    return CloudConfigService.getHost() + this.CASES_URL + '/' + this.NAME_PATH + '/' + this.MAXTAF_API_RUN_PATH;
  }

  public runSuiteForApiWithName() {
    return CloudConfigService.getHost() + this.SUITES_URL + '/' + this.NAME_PATH + '/' + this.MAXTAF_API_RUN_PATH;
  }

  public updateVncStatus(runResultId: string) {
    return CloudConfigService.getHost() + this.UPDATE_VNC_STATUS_URL + '/' + runResultId;
  }

  getImportInfoCases(id) {
    return this.API_URL + '/import/info/case/' + id;
  }

  getImportInfoCase() {
    return this.API_URL + '/import/info/case';
  }

  getRunStopInfo(id) {
    return this.API_URL + '/runs/stop/info/' + id;
  }

  getDeleteRunInfo(id) {
    return this.API_URL + '/runs/delete/info/' + id;
  }

  getDeleteRunInfos() {
    return this.API_URL + '/runs/delete/info';
  }

  getImportInfoSuites(id) {
    return this.API_URL + '/import/info/suite/' + id;
  }

  getImportInfoSuite() {
    return this.API_URL + '/import/info/suite';
  }

  getImportInfoProjectParams(id) {
    return this.API_URL + '/import/info/projectParams/' + id;
  }

  getImportInfoProjectParam() {
    return this.API_URL + '/import/info/projectParams';
  }

  generateUtam() {
    return this.CASES_URL + '/generateUtam';
  }

  resetConfigParams() {
    return this.MAXTAF_CONFIGURATION_PARAMS_URL + '/reset';

  }

  resetConfigParam() {
    return this.MAXTAF_CONFIGURATION_PARAMS_URL + '/reset/one';
  }

  setDefaultValue() {

  }
}
