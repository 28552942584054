<h1 mat-dialog-title>Generate From HTML</h1>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <!--  <label>Method</label>-->
  <!--  <div class="mb-3">-->
  <!--    <mat-button-toggle-group (change)="method = $event.value;" [disabled]="saveSpinner" [value]="0">-->
  <!--      <mat-button-toggle [value]="0" >From Computer File</mat-button-toggle>-->
  <!--      <mat-button-toggle [value]="1" >From Workspace File</mat-button-toggle>-->
  <!--      <mat-button-toggle [value]="2" >From URL</mat-button-toggle>-->
  <!--      <mat-button-toggle [value]="3" >From Source</mat-button-toggle>-->
  <!--    </mat-button-toggle-group>-->
  <!--  </div>-->

  <!--  <div *ngIf="method == '0'">-->
  <!--    <label>File selector</label>-->
  <!--    <div class="container" style=" max-width: 100%;">-->
  <!--      <div class="row justify-content-md-center">-->
  <!--        <div class="col">-->
  <!--          <div class="input-group mb-3">-->
  <!--            <div class="custom-file">-->
  <!--              <input (change)="selectLocalFile($event)" type="file" class="custom-file-input" id="inputGroupFile01">-->
  <!--              <label class="custom-file-label" for="inputGroupFile01">{{localFileName}}</label>-->
  <!--            </div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div *ngIf="method == '1'">
    <label>File selector</label>
    <label *ngIf="workspaceFileSelected">{{workspaceFilePath}}</label>

    <app-nav-workspace-tree *ngIf="!workspaceFileSelected"
                            [initialData]="rootWorkspaceNode"
                            [ngClass]="{'display-none': !expandWorkspace}"
                            [height]="'calc(100% - 30px)'"
                            [(copyNode)]="copyNode"
                            [(isCopy)]="isCopy"
                            (selected)="selectedFile($event)"
    >
    </app-nav-workspace-tree>

    <button id="selectNewFile" mat-button *ngIf="workspaceFileSelected && !saveSpinner"
            (click)="workspaceFileSelected = false">Select new file
    </button>
  </div>

  <!--  <div *ngIf="method == '2'">-->
  <!--    <label>URL</label>-->
  <!--    &lt;!&ndash;  <div *ngIf="!duplicate" class="mb-3">&ndash;&gt;-->
  <!--    &lt;!&ndash;    <input [disabled]="saveSpinner" type="text" [(ngModel)]="case.name">&ndash;&gt;-->
  <!--    &lt;!&ndash;  </div>&ndash;&gt;-->
  <!--    <div class="mb-3">-->
  <!--      <input [disabled]="saveSpinner" type="text">-->
  <!--    </div>-->
  <!--  </div>-->

  <!--  <app-editor-workspace *ngIf="method == '3'"-->
  <!--    class="code-editor d-block"-->
  <!--    [allowEdit]="true"-->
  <!--    [height]="'380px'"-->
  <!--    [isCurrentlyBeingCompiled]="false"-->

  <!--    [showDetailsLine]="false"-->
  <!--    [typeOfFile]="'text'"-->
  <!--  ></app-editor-workspace>-->

  <div mat-dialog-actions>
    <button id="cancelButton" mat-button *ngIf="!saveSpinner" [mat-dialog-close]>Cancel</button>

    <button id="createButton" mat-button *ngIf="!saveSpinner" (click)="generate()" color="primary">
      Generate
    </button>
    <button mat-button *ngIf="saveSpinner" color="primary">
      <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
        <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                     [diameter]="20"
                     class="whiteSpinner"></mat-spinner>
      </mat-icon>
    </button>
  </div>
