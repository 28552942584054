import {NgModule} from '@angular/core';
import {UserComponent} from './user.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {CommonModule} from '@angular/common';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {IconModule} from '../../../mx/icon/icon.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorModule} from '../../../mx/error/error.module';
import {EditPasswordDialogModule} from '../../dialogs/edit-password-dialog/edit-password-dialog.module';
import {EditDialogModule} from '../../../dialogs/edit-dialog/edit-dialog.module';
import {CreateApiKeyModule} from "../../dialogs/create-api-key/create-api-key.module";
import {YesNoDialogModule} from "../../../dialogs/yes-no-dialog/yes-no-dialog.module";
import {ApiKeyInfoModule} from "../api-key-info/api-key-info.module";

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    IconModule,
    ReactiveFormsModule,
    ErrorModule,
    EditPasswordDialogModule,
    EditDialogModule,
    CreateApiKeyModule,
    YesNoDialogModule,
    ApiKeyInfoModule
  ],
  declarations: [
    UserComponent
  ],
  exports: [
    UserComponent
  ]
})

export class UserModule {
}
