<div class="container-fluid"
     (keyup.enter)="filterRecords()">

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-select placeholder="Filter done as exact match, or not"
                    [(value)]="search.exactSearch">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="col-md" style="display: flex; justify-content: center;">
      <button mat-raised-button class="mr-1 mb-1 heightButton" (click)="filterRecords()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="px-1">Filter</span>
      </button>
      <button mat-raised-button class="mr-1 mb-1 heightButton" (click)="clearFilter()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="px-1">Clear</span>
      </button>
      <button mat-raised-button class="mb-1 heightButton" (click)="clickedClose()" mat-button color="primary">
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="px-1">Close</span>
      </button>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('name')" type="text"
               placeholder="Name..."
               [(ngModel)]="search.name">
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('description')" type="text"
               placeholder="Description..."
               [(ngModel)]="search.description">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('ownerUsername')" type="text"
               placeholder="Owner username..."
               [(ngModel)]="search.ownerUsername">
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('projectId')" type="text"
               placeholder="Id..."
               [(ngModel)]="search.projectId">
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md center-checkbox">
      <mat-checkbox [disabled]="lockSearchParams.includes('includePublic')" [(ngModel)]="search.includePublic">Show
        public projects
      </mat-checkbox>
    </div>

  </div>

</div>
