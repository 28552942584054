import {SecurityGroup} from './securityGroup';
import {Project} from '../../../projects/shared/models/project';
import {User} from './user';

export class UserProject {

  constructor(
    public id: string = '',
    public userRunParams: string = '',
    public securityGroups: SecurityGroup[] = [],
    public roles: string[] = [],
    public user: User = new User(),
    public project: Project = new Project(),
    public existApiKey: boolean = false
  ) {
  }

}
