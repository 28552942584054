import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AiChatService} from '../../shared/services/ai-chat.service';
import {AiChat} from '../../shared/models/aiChat';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ExportAiChatComponent} from '../export-ai-chat/export-ai-chat.component';
import {GlobalEventsManagerService} from '../../../../shared/services/global-events-manager.service';
import {
  AddProjectParamDialogComponent
} from '../../../users/dialogs/add-project-param-dialog/add-project-param-dialog.component';
import {ProjectParamsService} from '../../../users/shared/services/project-params.service';
import {AiInputComponent} from '../ai-input/ai-input.component';
import {Observable, Subscription} from "rxjs";
import {MaxtafTokensStorageService} from "../../../../shared/services/maxtaf-tokens-storage.service";
import {TemplatePortal} from "@angular/cdk/portal";
import {Overlay, OverlayRef} from "@angular/cdk/overlay";
import {AiOptionsDialogComponent} from "../ai-options-dialog/ai-options-dialog.component";
import {UserService} from "../../../users/shared/services/user.service";
import {AiChatStreamDelta} from "../../shared/models/aiStreamChat";


@Component({
  selector: 'app-ai-wrapper',
  templateUrl: './ai-wrapper.component.html',
  styleUrls: ['./ai-wrapper.component.css']
})
export class AiWrapperComponent implements OnInit {
  chat: AiChat[] = [];
  activeChat: AiChat = null;
  @ViewChild('pageScroll') public pageScroll: OverlayScrollbarsComponent;
  @ViewChild('chatArea') public chatAreaViewChild: ElementRef;
  @ViewChild('aiInputWrapper') public aiInputAreaViewChild: ElementRef;
  @ViewChild('inputArea') public inputAreaViewChild: ElementRef;
  @ViewChild('aiInput') public aiInput: AiInputComponent;
  @Output('hideAI') closeEvent = new EventEmitter<any>();
  @Input('resizeAIMode') resizeAIMode = false;

  subscribe;
  disableInput = false;
  errorMessage = '';


  maximumNumberOfRequests = 20;
  currentNumberOfRequests = 1;
  projectParamValue = '';
  chatHeight = '400px';

  inputActive = false;
  @Output('resizeAI') resizeAIEvent = new EventEmitter<any>();
  private esAIChatAskQuestion: EventSource;

  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;
  showLoadResponseDiv = false;
  isRunActive = false;
  aiRequest: Subscription;

  constructor(
    private aiChatService: AiChatService,
    public globalEventsManagerService: GlobalEventsManagerService,
    private projectParamService: ProjectParamsService,
    public tokensService: MaxtafTokensStorageService,
    public overlay: Overlay,
    public dialog: MatDialog,
    public tokensStorage: MaxtafTokensStorageService,
    public viewContainerRef: ViewContainerRef,
    public userService: UserService
  ) {
    this.subscribe = this.globalEventsManagerService.refreshAIChatEmitter.subscribe((mode) => {
      this.getContent();
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
    });

    this.globalEventsManagerService.maxAIRequests.subscribe((mode) => {
      this.maximumNumberOfRequests = mode;
      this.setMessageType();
    });

    this.globalEventsManagerService.currentAiRequests.subscribe((mode) => {
      this.currentNumberOfRequests = mode;
      this.setMessageType();
    });

    this.globalEventsManagerService.aiKey.subscribe((mode) => {
      this.projectParamValue = mode;
      this.setMessageType();
    });
  }

  ngOnInit(): void {
    this.getContent();
  }

  ngOnDestroy() {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
    this.stopAIRequest();
  }

  resizeAI() {
    this.resizeAIEvent.emit();
  }

  getChetHeight() {
    try {
      const divHeight = this.chatAreaViewChild.nativeElement.offsetHeight;
      return divHeight + 'px';
    } catch (e) {

    }

    return this.chatHeight;
  }

  getAiInputHeight() {
    try {
      const divHeight = this.aiInputAreaViewChild.nativeElement.offsetHeight;
      return divHeight;
    } catch (e) {

    }

    return 91;
  }

  getInputDivHeight() {

    try {
      const divHeight = this.inputAreaViewChild.nativeElement.offsetHeight;
      return divHeight;
    } catch (e) {

    }

    return 91;
  }

  setMessageType() {
    if (this.projectParamValue == null || this.projectParamValue.trim() == '') {
      if (this.currentNumberOfRequests < this.maximumNumberOfRequests) {
        if (this.errorMessage.startsWith("You have reached the daily limit of")) {
          this.errorMessage = '';
          this.disableInput = false;
        }
      } else {
        this.errorMessage = "You have reached the daily limit of " + this.maximumNumberOfRequests + " requests using the MaxTAF Cloud AI key. If you would like to use your own openAI key.";
        this.disableInput = true;
      }
    } else {
      if (this.errorMessage.startsWith("You have reached the daily limit of")) {
        this.errorMessage = '';
        this.disableInput = false;
      }
    }
  }

  closeMessage() {
    this.errorMessage = '';
  }

  openAddOrEditProjectParamDialog(paramId = 'mx.ai.openai.key') {
    this.projectParamService.getProjectParam(paramId)
      .subscribe(
        projectParam => {

          const dialogRef = this.dialog.open(AddProjectParamDialogComponent, {
            width: '500px',
            data: {
              projectParam: projectParam
            }
          });

          dialogRef.afterClosed().subscribe(result => {

          });
        },
        error => {
          console.error(error);

        }
      );
  }


  response = '';

  stopAIRequest() {
    if (this.aiRequest) {
      this.aiRequest.unsubscribe();
    }
    this.isRunActive = false;
    this.showLoadResponseDiv = false;
    // this.esAIChatAskQuestion.close();
  }

  askQuestionWithoutContent() {
    this.stopAIRequest();

    this.activeChat = null;
    this.showLoadResponseDiv = true;
    this.isRunActive = true;

    setTimeout(() => {
      this.scrollToBottom();
    }, 500);


    const user = this.tokensService.getUserId();
    const project = this.tokensService.getProjectId();
    const userProject = this.tokensService.getUserProjectID();
    const fireToken = this.tokensService.getFireToken();
    const refreshToken = this.tokensService.getFireRefreshToken();


    this.esAIChatAskQuestion = new EventSource(
      '/api/ai/chat/ask' +
      '?' + this.tokensService.USER_PROJECT_TOKEN + '=' + userProject + '&' +
      this.tokensService.PROJECT_TOKEN + '=' + project + '&' +
      this.tokensService.USER_TOKEN + '=' + user + '&' +
      this.tokensService.FIREBASE_TOKEN + '=' + fireToken + '&' +
      this.tokensService.FIREBASE_REFRESH_TOKEN + '=' + refreshToken + '&' +
      this.tokensService.USER_TIMEZONE_ID + '=' + Intl.DateTimeFormat().resolvedOptions().timeZone + ''
    );

    this.response = '';
    this.errorMessage = '';

    this.aiRequest = this.askMaxtafAI().subscribe({
      next: (result) => {
        this.errorMessage = '';
        this.isRunActive = true;
        this.showLoadResponseDiv = false;

        let objResponse: AiChatStreamDelta;
        try {
          objResponse = JSON.parse(result);
          if (objResponse.error) {
            this.isRunActive = false;
            this.showLoadResponseDiv = false;
            this.errorMessage = objResponse.content;

            // this.getContent(true);
            this.esAIChatAskQuestion.close();
          }

          if (objResponse.content != null) {
            this.response += objResponse.content;
          }
          this.activeChat = new AiChat("id", "assistant", this.response, "dz", true);
        } catch (e) {
          console.error("error: ", e);
          this.showLoadResponseDiv = false;
          this.esAIChatAskQuestion.close();
        }

        // this.scrollY(100000);
        this.scrollToBottomLive();
      },
      error: (error) => {
        // console.log("error: ", error);
        this.isRunActive = false;
        this.showLoadResponseDiv = false;
        this.esAIChatAskQuestion.close();
        // this.error = error;
        // console.log("error !!!!!!!!!!!!!!!!!!!!!!!: ", error);
        // this.errorMessage = 'We are currently experiencing technical difficulties. Please try again later.';
        // this.getContent(true);
      },
      complete: () => {
        this.isRunActive = false;
        this.showLoadResponseDiv = false;
        // this.getContent(true);
      }
    });
  }

  scrollToBottomLive() {
    const osInstance = this.pageScroll.osInstance();
    let scrollInfo = osInstance.scroll();
    // console.log("scrollInfo: ", scrollInfo.max.y - scrollInfo.position.y);

    // if(scrollInfo.ratio.y > 0.95) {
    if (scrollInfo.max.y - scrollInfo.position.y < 100) {
      osInstance.scroll({y: "100%"}, 500);
    }
  }

  scrollToBottom(duration = 500) {
    const osInstance = this.pageScroll.osInstance();
    osInstance.scroll({y: "100%"}, duration);
  }

  scrollY(y: number, duration: number = 0) {
    this.pageScroll.osInstance().scroll(
      {
        x: this.pageScroll.osInstance().getState().overflowAmount.x,
        y
      },
      duration
    );
  }

  askMaxtafAI(): Observable<string> {
    return new Observable<string>((observer) => {
      this.esAIChatAskQuestion.onmessage = (event) => {
        observer.next(event.data);
      };

      this.esAIChatAskQuestion.onerror = (error) => {
        console.error('EventSource failed:', error);
        observer.error(error);
      };
      return () => {
        this.esAIChatAskQuestion.close();
      };
    });
  }

  getContent(changeValueOfLoadAIResponse = false) {
    if (this.tokensStorage.getUserId() == null || this.tokensStorage.getUserId() == '') {
      return;
    }
    this.aiChatService.getChat().subscribe((res: AiChat[]) => {
      this.addToChatArray(res);
      setTimeout(() => {
        this.scrollToBottom();
      }, 500);
      this.activeChat = null;
      if (changeValueOfLoadAIResponse) {
        this.showLoadResponseDiv = false;
      }

    }, error => {

      console.error('error: ', error);
    });
  }

  addToChatArray(res: AiChat[]): void {
    res.forEach(r => {
      if (!this.chat.some(c => c.id === r.id)) {
        this.chat.push(r);
      }
    });

    this.chat = this.chat.filter(c => res.some(r => r.id === c.id));
  }

  public addToPrompt(question: string) {
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);

    this.inputActive = true;
    this.aiInput.addToPrompt(question);

  }

  public askAndSaveQuestion(question: string, startNewChat = false, askAI = true) {
    if (startNewChat) {
      this.aiChatService.delete().subscribe(res1 => {
        this.aiChatService.saveQuestion(question).subscribe(res => {
          this.addToChatArray(res);
          setTimeout(() => {
            this.scrollToBottom();
          }, 500);

          this.askQuestionWithoutContent();

        }, error => {
          console.error('error: ', error);
        });
      }, error => {
        this.showLoadResponseDiv = false;
        console.error('error: ', error);
      });

    } else {
      //this.showLoadResponseDiv = true;
      setTimeout(() => {
        this.scrollToBottom(0);
      }, 500);
      this.aiChatService.saveQuestion(question).subscribe(res => {

        this.addToChatArray(res);
        setTimeout(() => {
          this.scrollToBottom(0);
          this.askQuestionWithoutContent();
        }, 500);

      }, error => {
        //this.showLoadResponseDiv = false;
        console.error('131 error: ', error);
      });
    }
  }

  hideShowVisibility() {
    this.closeEvent.emit();
  }

  exportAIChat() {
    const dialogRef = this.dialog.open(ExportAiChatComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  deleteAIChat() {
    this.showLoadResponseDiv = true;
    this.aiChatService.delete().subscribe(res => {
      this.getContent(true);
    }, error => {
      this.showLoadResponseDiv = false;
      console.error('error: ', error);
    });
  }

  openDeleteDialog() {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '500px',
      data: {
        title: 'Are you sure you want to delete chat with AI?.',
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result == true) {
        // Discard changes;
        this.deleteAIChat();
      }
    });
  }

  refreshData() {
    this.getContent();
  }

  refreshDataAndAsk(newChat) {
    this.addToChatArray(newChat);
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);

    this.askQuestionWithoutContent();
  }

  inputActiveChanges(inputActive: boolean) {
    this.inputActive = inputActive;
    setTimeout(() => {
      this.scrollToBottom();
    }, 500);
  }


  openOptionsContextMenu(event: MouseEvent/*, node: MaxtafFileNode*/) {
    // event.type = 'contextmenu';
    this.closeContextMenu();

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(event)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);


    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    // this.overlayRef = this.overlay.create();

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: "node"
    }));

  }

  closeContextMenu() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  menuRefreshAi() {
    this.refreshData();
    this.closeContextMenu();
  }

  menuDeleteAi() {
    this.openDeleteDialog();
    this.closeContextMenu();
  }

  menuExportAi() {
    this.exportAIChat();
    this.closeContextMenu();
  }

  menuAiSetting() {
    this.openSettingsDialog();
    this.closeContextMenu();
  }

  private openSettingsDialog() {
    const dialogRef = this.dialog.open(AiOptionsDialogComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {


    });
  }

  public openedAI() {
    // this.scrollToBottom(100);
    setTimeout(() => {
      this.scrollToBottom(0);
    }, 100);

    // this.scrollY(100000);
  }

}
