<div
  [ngClass]="{'margin-bottom-icon': marginBottom}"
  class="mx-icon" *ngIf="!spinner">
  <span
    (mouseenter)="hoverAdd($event)"
    (mouseleave)="hoverRemove($event)"
    class="mdi"
    [ngClass]="[icon]"
    [ngStyle]="{'color': color}"
  >
  </span>
</div>

<div class="mx-spinner" *ngIf="spinner">
  <mat-spinner
    [ngStyle]="{'color': color}"
    [diameter]="diameter">

  </mat-spinner>
</div>
