<section>
  <div class="responsive-table-wrapper">
    <table mat-table matSort multiTemplateDataRows
           [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           (matSortChange)="sortData($event)">


      <!-- Checkbox Column -->
      <!--      <ng-container matColumnDef="select">-->
      <!--        <th mat-header-cell *matHeaderCellDef>-->
      <!--          <mat-checkbox (change)="$event ? masterToggle() : null"-->
      <!--                        style="margin-top: 8px;"-->
      <!--                        [checked]="selection.hasValue() && isAllSelected()"-->
      <!--                        [indeterminate]="selection.hasValue() && !isAllSelected()"-->
      <!--                        [aria-label]="checkboxLabel()">-->
      <!--          </mat-checkbox>-->
      <!--        </th>-->
      <!--        <td mat-cell *matCellDef="let row" style="padding-top: 8px;">-->
      <!--          <mat-checkbox (click)="$event.stopPropagation();"-->
      <!--                        (change)="$event ? selectedChange(row) : null"-->
      <!--                        [checked]="isSelectedRow(row)">-->
      <!--          </mat-checkbox>-->

      <!--        </td>-->
      <!--      </ng-container>-->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Name</th>
        <td mat-cell *matCellDef="let element">
          {{element.testCaseName}}
        </td>
      </ng-container>


      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Error Message</th>
        <td mat-cell
            *matCellDef="let element"
            [matTooltip]="element != null && element.compileError != null && element.compileError.length > 35 ? element.compileError : ''">
          {{
          (element != null && element.compileError != null)
            ? element.compileError.length < 35 ? element.compileError : element.compileError.substr(0, 35) + "..." :
            (element != null && element.transpileError != null)
              ? element.transpileError.length < 35 ? element.transpileError : element.transpileError.substr(0, 35) + "..." :
              ""
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button class="ml-3" aria-label="expand row"
                  (click)="element.expanded = !element.expended; $event.stopPropagation()">
            <mat-icon *ngIf="element.expanded == null || !element.expanded">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="element.expanded != null && element.expanded">keyboard_arrow_up</mat-icon>
          </button>
          <!--          {{element.expended}}-->
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail"
               [@detailExpand]="element.expended != null && element.expended ? 'expanded' : 'collapsed'">
            <!--                    <mat-expansion-panel *ngFor="let compileError of compileErrors">-->
            <!--                      <mat-expansion-panel-header>-->
            <!--                        <mat-panel-title>-->
            <!--                          {{compileError.testCase.name}}-->
            <!--                        </mat-panel-title>-->
            <!--                      </mat-expansion-panel-header>-->


            <app-show-compile-error
              style="width: 600px"
              [disableRemove]="true"
              [smallHeight]="true"
              (compiledErrorLine)="compiledErrorLine($event, element)"
              (transpiledErrorLine)="transpiledErrorLine($event, element)"
              [compileResult]="element">
            </app-show-compile-error>
            <!--                      </app-show-compile-error>-->

            <!--                    </mat-expansion-panel>-->
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
          class="example-element-row"
          [class.example-expanded-row]="element.expended"
          (click)="element.expended = !element.expended">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

      <!--      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>-->
      <!--&lt;!&ndash;      clickedItem(row, i);&ndash;&gt;-->
      <!--      <tr mat-row-->
      <!--          *matRowDef="let row; columns: columnsToDisplayWithExpand; let i = index"-->
      <!--          class="example-element-row"-->
      <!--          (click)="expandedElement = expandedElement === row ? null : row;"-->
      <!--          [class.example-expanded-row]="expandedElement === row"-->
      <!--          [ngClass]="{ 'highlight': selection.isSelected(row)}"></tr>-->
      <!--      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>-->
    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <div class="container mr-0">
    <div class="row justify-content-end"
         style="flex-direction: row;justify-content: space-between;align-items: center;">
      <div class="col-xs-4" *ngIf="selection.selected.length > 0">
        <p style="font-size:13px; color:grey; margin-top:auto; margin-bottom:auto">
          Selected: {{selection.selected.length}} </p>
      </div>
      <div class="col-xs-8">
        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [length]="pagesInfo.totalElements"
          [pageIndex]="pageParams.page.value"
          [pageSize]="pageParams.size.value"
          (page)="setPageSizeOrIndex($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>

  </div>
</section>
