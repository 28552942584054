export class AiChat {


  constructor(public id: string,
              public role: string,
              public content: string,
              public userProjectId: string,
              public ai: boolean) {
  }
}
