<!--compile error-->
<div class="compile-error-container data-wrapper card-shadow data-wrapper-padding-none data-wrapper-border-radius-none"
     [ngClass]="smallHeight ? 'small-expansion-panel' : 'normal-expansion-panel'"
     *ngIf="compileError.compileError || compileError.transpileError">
  <mat-accordion class="example-headers-align" [multi]="true">

    <mat-expansion-panel *ngIf="compileError.transpileError"
                         #mxmlCompileErrorPanel
                         hideToggle
                         class="mb-0 compile-error"
                         [expanded]="true"
    >
      <mat-expansion-panel-header
        style="background: #ff5252; border-radius: 0 0 0 0 !important;  padding-left: 15px; padding-right: 15px; cursor: pointer">
        <mat-panel-title style="color:white;">

          <mx-icon *ngIf="!disableClose && !mxmlCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="collapse"></mx-icon>
          <mx-icon *ngIf="!disableClose && mxmlCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="expand"></mx-icon>
          Mxml compile error:
        </mat-panel-title>
        <mat-panel-description class="mr-0" *ngIf="!disableRemove">
          <span></span>
          <mx-icon class="close_compile_error" marginBottom="true"
                   (click)="closeTranspileError()"
                   mx="close">
          </mx-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <overlay-scrollbars
        style=" padding: 10px 28px; max-height: 236px !important;"
        [options]="{ scrollbars: { autoHide: 'n' } }"
      >
        <pre (click)="goToTranspiledErrorLine($event)" style="color: black;"
             [innerHTML]="transpileErrorMessage"></pre>
      </overlay-scrollbars>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="compileError.compileError"
                         class="mt-0 compile-error"
                         #javaCompileErrorPanel hideToggle
                         [expanded]="true"

    >
      <mat-expansion-panel-header
        style="background: #ff5252; padding-left: 15px; padding-right: 15px; cursor: pointer">
        <mat-panel-title style="color:white;">
          <mx-icon *ngIf="!disableClose && !javaCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="collapse"></mx-icon>
          <mx-icon *ngIf="!disableClose && javaCompileErrorPanel.expanded" class="white_arrows mr-3"
                   mx="expand"></mx-icon>

          <button mat-raised-button
                  class="mr-2 ask-ai"
                  color="primary"
                  (click)="doActionAI(); $event.stopPropagation()">
            <mx-icon mx="ai"></mx-icon>
            <span>Ask Ai</span>
          </button>
          Java compile error:
        </mat-panel-title>

        <mat-panel-description class="mr-0" *ngIf="!disableRemove">
          <span></span>
          <mx-icon mx="close" marginBottom="true" class="close_compile_error"
                   (click)="closeCompileError()">
          </mx-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <overlay-scrollbars
        style=" padding: 10px 28px; max-height: 236px !important; width: 100%;"
        [options]="{ scrollbars: { autoHide: 'n' } }"
      >
          <pre (click)="goToCompileErrorLine($event)" style="color: black;"
               [innerHTML]="compileErrorMessage"></pre>
      </overlay-scrollbars>
    </mat-expansion-panel>
    <!--    </overlay-scrollbars>-->
  </mat-accordion>
</div>
