import {CaseSuite} from './caseSuite';
import {CaseAndSuiteParent} from './case-and-suite-parent';

export class Suite extends CaseAndSuiteParent {

  constructor(suite?: Suite) {
    super();
    if (suite != undefined || suite != null) {
      this.id = suite.id;
      this.name = suite.name;
      this.description = suite.description;
      this.type = suite.type;
    }
  }


  cases: Array<CaseSuite>;

}
