<h1 mat-dialog-title>{{heading}}</h1>

<div mat-dialog-content>
  <div *ngIf="exportSuccess" class="fs-12 alert alert-success alert-dismissible fade show" role="alert">
    Export finished with success.
    <button (click)="exportSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-error-message class="fs-12" *ngIf="exportError" [error]="error" [showInDialog]="true"></app-error-message>

  <mat-radio-group [(ngModel)]="exportType" aria-label="Select an option"
                   style="width:100%; text-align: center">
    <mat-radio-button class="mr-3" matTooltip="MaxTAF Cloud" [value]="'JSON'" [disabled]="!checkPaths.json.enabled">
      JSON
    </mat-radio-button>
    <mat-radio-button class="mr-3" [disabled]="!active || !checkPaths.xml.enabled" matTooltip="Maximo" [value]="'XML'">
      XML
    </mat-radio-button>
    <mat-radio-button class="mr-3" *ngIf="selected.length === 1" [disabled]="!active || !checkPaths.template.enabled"
                      matTooltip="MaxTAF Template" [value]="'TEMPLATE'">TEMPLATE
    </mat-radio-button>
    <mat-radio-button matTooltip="MaxTAF Transfer" [disabled]="!checkPaths.transfer.enabled" [value]="'TRANSFER'">
      TRANSFER
    </mat-radio-button>
  </mat-radio-group>

  <div class="mt-3" *ngIf="exportType === 'TEMPLATE' && selected.length === 1">
    <p>File will be saved in workspace/templates directory.</p>
    <label>Template name</label>
    <input type="text" [(ngModel)]="templateName">
    <label>Template description</label>
    <input type="text" [(ngModel)]="templateDescription">
  </div>

  <br *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'">
  <br *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'">

  <mat-form-field *ngIf="exportType === 'TRANSFER'" class="example-form">
    <mat-label>Projects</mat-label>
    <mat-select [formControl]="projects" multiple>
      <mat-select-trigger>
        {{projects.value ? projects.value[0].name : ''}}
        <span *ngIf="projects.value?.length > 1" class="example-additional-selection">
        (+{{projects.value.length - 1}} {{projects.value?.length === 2 ? 'other' : 'others'}})
      </span>
      </mat-select-trigger>
      <mat-option *ngFor="let project of projectsList" [value]="project">{{project.name}} - [{{project.id}}]
      </mat-option>

    </mat-select>
  </mat-form-field>

  <br *ngIf="exportType === 'TRANSFER'">
  <mat-checkbox *ngIf="exportType === 'TRANSFER'" type="checkbox" [checked]="overwriteCase"
                (change)="overwriteCase = !overwriteCase"
                id="overwrite2Check">Overwrite case data with import file?
  </mat-checkbox>

  <br *ngIf="exportType === 'TRANSFER'">
  <br *ngIf="exportType === 'TRANSFER'">

  <div [@.disabled]="disableAnimation" *ngIf="!hideAdvancedSettings && exportType !== 'TEMPLATE'"
       style="margin-left: 2rem">
    <!--    <mat-expansion-panel>-->
    <!--      <mat-expansion-panel-header>-->
    <!--        <mat-panel-title>-->
    <!--          Advanced settings-->
    <!--        </mat-panel-title>-->
    <!--      </mat-expansion-panel-header>-->

    <mat-checkbox [disabled]="exportType != 'JSON' || useFilter || this.selected.length != 0"
                  [(ngModel)]="onlyArchivedOrActive"
                  matTooltip="{{exportType == 'JSON'? (onlyArchivedOrActive ? 'Export without ' + (active ? 'archived' : 'active') + ' ' + exportType + 's': 'Export archived and active ' + exportType + 's'): 'Only available for JSON'}}">
      Only {{selected.length == 1 ? 'this ' + exportType.toLowerCase() + '' : selected.length > 1 ? 'selected' : active ? 'active' : 'archived'}}
    </mat-checkbox>
    <br>
    <!--      <mat-checkbox [disabled]="jsonOrXmlorTemplate != 'JSON'" [jsonOrXmlorTemplate]="jsonOrXml == 'JSON'" [(ngModel)]="classFile"-->
    <!--                    matTooltip="Only available for JSON">Class file-->
    <!--      </mat-checkbox>-->
    <br>
    <mat-checkbox [disabled]="this.selected.length != 0" [(ngModel)]="useFilter"
                  (change)="useFilter == true ? onlyArchivedOrActive = true : null">Use Filter
    </mat-checkbox>


    <!--    </mat-expansion-panel>-->
  </div>

  <br>

</div>

<div mat-dialog-actions>
  <button id="closeButton" mat-button (click)="closeDialog()">{{exportSuccess || spinner ? 'Close' : 'Cancel'}}</button>
  <button id="exportButton" mat-button *ngIf="!spinner" (click)="export()" color="primary">Export</button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>

