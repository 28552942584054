<h5 mat-dialog-title class="ml-4">{{ searchHeader }}</h5>

<div mat-dialog-content>
  <!-- Directory Path -->
  <div style="display: flex; align-items: center; width: 80%; margin: 0 auto;" class="pb-3" *ngIf="!isSearching">
    <label class="path-label" style="margin-right: 10px;">Path:</label>
    <div class="path-display" style="flex-grow: 1; display: flex; align-items: center;">
      <span class="path-icon" style="margin-right: 10px;">🏠</span> <!-- Replace with an actual icon as needed -->
      <span class="path-text">{{ searchCriteria.directoryPath }}</span>
    </div>
  </div>

  <!-- Spinner Overlay -->
  <div class="overlay" *ngIf="isSearching">
    <mat-spinner></mat-spinner>
    <button mat-button (click)="stopSearch()" [disabled]="!searchEnabled" color="warn" id="stopSearchButton">Stop Search</button>
  </div>

  <!-- File Pattern -->
  <div style="display: flex; align-items: center; width: 80%; margin: 0 auto;" class="pb-3" *ngIf="!isSearching">
    <label style="margin-right: 10px;">File Pattern:</label>
    <input type="text" [(ngModel)]="searchCriteria.filePattern" placeholder="Enter File Pattern" style="flex-grow: 1;">
  </div>

  <!-- Search String with Conditional Display -->
  <div *ngIf="!isSearching && searchCriteria.searchString !== undefined" style="display: flex; align-items: center; width: 80%; margin: 0 auto;" class="pb-3">
    <label style="margin-right: 10px;">Containing String:</label>
    <input type="text" [(ngModel)]="searchCriteria.searchString" placeholder="Enter Search String" style="flex-grow: 1;">
  </div>

  <!-- Case Sensitivity Checkbox -->
  <div style="width: 80%; margin: 0 auto;" class="pb-3" *ngIf="!isSearching">
    <mat-checkbox [(ngModel)]="searchCriteria.caseSensitive" [disabled]="!searchCriteria.searchString">Case Sensitive</mat-checkbox>
  </div>
</div>

<div class="search-results-container" *ngIf="searchResults.length > 0">
  <mat-table #table [dataSource]="searchResults" class="mat-elevation-z8">

    <!-- File Name Column -->
    <ng-container matColumnDef="fileName">
      <mat-header-cell *matHeaderCellDef> File Name </mat-header-cell>
      <mat-cell *matCellDef="let element" class="pointer">
        <app-nav-tree-file-icon class="pointer"
                                [extension]="element.fileName.substr(element.fileName.lastIndexOf('.')+1)"></app-nav-tree-file-icon>
        <span>{{ element.fileName }}</span>
      </mat-cell>
    </ng-container>

    <!-- Path Column -->
    <ng-container matColumnDef="path">
      <mat-header-cell *matHeaderCellDef> Path </mat-header-cell>
      <mat-cell *matCellDef="let element" class="mat-cell-path">
        {{element.filePath}}
      </mat-cell>
    </ng-container>

    <!-- Matched Lines Column -->
    <ng-container matColumnDef="matchedLines" *ngIf="searchCriteria.searchString">
      <mat-header-cell *matHeaderCellDef> Matched Lines </mat-header-cell>
      <mat-cell *matCellDef="let element">
        Line {{element.lineNumber}}: {{element.lineContent}}
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row (click)="onFileNameClick(row)" class="pointer" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<div *ngIf="searchStatusMessage" class="search-status-message">
  {{ searchStatusMessage }}
</div>
<div mat-dialog-actions style="margin-right: 24px">
  <button mat-button [mat-dialog-close]="" id="cancelButton">Cancel</button>
  <button mat-button (click)="startSearch()" color="primary" id="startSearchButton">Start Search</button>
  <!--  <button mat-button (click)="stopSearch()" [disabled]="!searchEnabled" color="warn" id="stopSearchButton">Stop Search</button>-->
</div>
