import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Case} from '../../shared/models/case';
import {CaseFileLocation} from '../../shared/models/case-file-location';
import {DuplicateCase} from '../../shared/models/duplicateCase';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TemplateService} from '../../../../shared/services/template.service';
import {CaseService} from '../../shared/services/case.service';
import {SuiteService} from '../../../suites/shared/services/suite.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {ChangeFileLocationDialogComponent} from '../change-file-location-dialog/change-file-location-dialog.component';
import {UserService} from '../../../users/shared/services/user.service';
import {UserPreferencesParam} from '../../../users/shared/models/userPreferencesParam';
import {UserConstService} from '../../../users/shared/services/user-const.service';
import {GenerateFromHtmlComponent} from '../generate-from-html/generate-from-html.component';
import {GlobalEventsManagerService} from "../../../../shared/services/global-events-manager.service";

@Component({
  selector: 'app-create-case-or-page-object',
  templateUrl: './create-case-or-page-object.component.html',
  styleUrls: ['./create-case-or-page-object.component.css']
})
export class CreateCaseOrPageObjectComponent implements OnInit, OnDestroy {
  showErrorAlert = false;
  error;

  selectedTemplate = '';
  templates = [];
  filteredTemplates = [];
  filteredTestingFrameworks = [];

  case: Case;
  saveSpinner = false;
  duplicate = false;
  fileLocationName = '';
  startFileLocationFullPath = '';

  createFromFile = false;
  fileLocation: CaseFileLocation = undefined;
  extension: string = undefined;

  openCreatedCase = true;

  isLicensed = false;

  setNewFileLocation = false;
  advanceFileLocation = false;

  duplicateCaseInfo = new DuplicateCase();

  // newCaseFilePath: string;
  // type: string;
  blockType: boolean = false;
  fromWorkspace: boolean = false;
  fromPageObject: boolean = false;
  redirect: boolean = true;


  defaultCaseFramework = 'JUnit';
  defaultCaseTemplate = 'Selenium \'Hello World\'';
  defaultCaseType = 'JAVA';
  defaultPageObjectTemplate = 'Blank';
  defaultPageObjectType = 'JAVA';
  defaultType = 'cases';
  setDefaultValues = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private templateService: TemplateService,
    private userService: UserService,
    private caseService: CaseService,
    private suiteService: SuiteService,
    private globalEventsManagerService: GlobalEventsManagerService,
    private projectService: ProjectService,
    private maxtafTokensStorageService: MaxtafTokensStorageService,
    public dialogRef: MatDialogRef<CreateCaseOrPageObjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // duplicate
      duplicate: boolean,
      // duplicate
      id: string,

      // create from file
      fileLocation: CaseFileLocation,
      newCaseFileLocation: string,

      // create from file
      code: string,
      // create from file
      testName: string,
      // create from file
      extension: string,

      openCreatedCase: boolean,

      newCaseFilePath: string,
      type: string,
      blockType: boolean,
      fromWorkspace: boolean,
      fromPageObject: boolean,
      redirect: boolean
    }
  ) {

    this.getDefaultValues();
    projectService.isLicensed(maxtafTokensStorageService.getProjectId()).subscribe(
      res => {
        this.isLicensed = res;
      },
      error => {
        this.showError(error);
      }
    );

    if (data.redirect != undefined) {
      this.redirect = data.redirect;
    }


    dialogRef.disableClose = true;


  }

  initVarsFromData() {
    this.case = new Case();

    this.case.id = this.data.id;
    if (this.defaultType == 'cases') {
      this.case.pageObject = false;
    } else {
      this.case.pageObject = true;
    }
    this.setDefaultTemplateAndFramework();

    // TODO check
    this.case.code = this.data.code;
    this.case.name = this.data.testName;

    if (this.data.fileLocation != undefined) {
      this.createFromFile = true;
      this.fileLocation = this.data.fileLocation;

      if (this.data.fileLocation.fileName.trim().replace('_', ' ').toLowerCase() != this.data.testName.trim().toLowerCase()) {
        this.setNewFileLocation = true;
      }

      this.fileLocationName = this.fileLocation.packageCasePartWithoutExtension;
      this.startFileLocationFullPath = this.fileLocation.fullPath;
    }

    if (this.data.newCaseFileLocation) {
      this.case.newCaseFileLocation = this.data.newCaseFileLocation;
    }

    if (this.data.extension != undefined) {
      this.extension = this.data.extension;
      this.case.caseType = this.getCaseTypeFromExtension(this.extension);
    }

    if (this.data.duplicate != undefined) {
      this.duplicate = this.data.duplicate;
    }

    if (this.data.newCaseFilePath != undefined) {
      this.case.newCaseFilePath = this.data.newCaseFilePath;
    }

    if (this.data.blockType != undefined) {
      this.blockType = this.data.blockType;
    }

    if (this.data.type != undefined) {
      this.case.caseType = this.data.type;
    }

    if (this.data.fromWorkspace != undefined) {
      this.fromWorkspace = this.data.fromWorkspace;
    }

    if (this.data.fromPageObject != undefined) {
      this.fromPageObject = this.data.fromPageObject;
      // this.selectedTemplate = '';
    }

    if (this.data.openCreatedCase != undefined) {
      this.openCreatedCase = this.data.openCreatedCase;
    }

    this.getTemplatesList();
  }

  getDefaultValues() {
    this.userService.getUserPreferencesParamList(UserConstService.getDefaultUserPreferenceSearchParams('mx.user.preferences.cases-workspace.create')).subscribe(
      (res: UserPreferencesParam[]) => {
        this.setDefaultValues = true;
        res.forEach((item: UserPreferencesParam) => {
          if (item.name == 'mx.user.preferences.cases-workspace.create.case.framework') {
            this.defaultCaseFramework = item.value;
          }
          if (item.name == 'mx.user.preferences.cases-workspace.create.case.template') {
            this.defaultCaseTemplate = item.value;
          }
          if (item.name == 'mx.user.preferences.cases-workspace.create.case.type') {
            this.defaultCaseType = item.value;
          }
          if (item.name == 'mx.user.preferences.cases-workspace.create.pageObject.template') {
            this.defaultPageObjectTemplate = item.value;
          }
          if (item.name == 'mx.user.preferences.cases-workspace.create.pageObject.type') {
            this.defaultPageObjectType = item.value;
          }
          if (item.name == 'mx.user.preferences.cases-workspace.create.type') {
            this.defaultType = item.value;
          }
        });

        this.initVarsFromData();
      },
      (error) => {
        console.error('error', error);
      }
    );
  }

  setDefaultTemplateAndFramework() {
    if (this.case.pageObject) {
      this.case.caseType = this.defaultPageObjectType;
      this.case.testingFramework = this.defaultCaseFramework;
      this.selectedTemplate = this.defaultPageObjectTemplate;
      this.defaultType = 'pageObjects';
    } else {
      this.case.caseType = this.defaultCaseType;
      this.case.testingFramework = this.defaultCaseFramework;
      this.selectedTemplate = this.defaultCaseTemplate;
      this.defaultType = 'cases';
    }
  }

// ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for running the test
  runShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.runShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.runShortcutListener, false);
  }


  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  closeDialog(value: boolean = false): void {
    this.dialogRef.close(value);
  }

  do() {
    if (this.duplicate) {
      this.duplicateCase();
    } else {
      this.save();
    }
  }

  generateHtml() {
    this.dialog.open(GenerateFromHtmlComponent, {
      width: '700px',
      data: {
        openCreatedCase: false
      }
    });
    this.closeDialog();
  }

  save() {
    this.addCase();
  }

  saveNewDefaultValues() {
    if (this.defaultType == 'cases') {
      if (this.case.pageObject == true) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.type', 'pageObjets');

      }
    } else {
      if (this.case.pageObject == false) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.type', 'cases');

      }
    }
    if (this.case.pageObject) {
      if (this.defaultPageObjectTemplate != this.selectedTemplate) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.pageObject.template', this.selectedTemplate);
      }
      if (this.defaultPageObjectType != this.case.caseType) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.pageObject.type', this.case.caseType);
      }
    } else {
      if (this.defaultCaseFramework != this.case.testingFramework) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.case.framework', this.case.testingFramework);
      }
      if (this.defaultCaseTemplate != this.selectedTemplate) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.case.template', this.selectedTemplate);
      }
      if (this.defaultCaseType != this.case.caseType) {
        this.updateCasePageObject('mx.user.preferences.cases-workspace.create.case.type', this.case.caseType);
      }
    }
  }

  updateCasePageObject(name, value) {
    this.userService.setUserPreferencesParam(name, value).subscribe(
      (res: UserPreferencesParam) => {
      },
      (error) => {
        console.error('error', error);
      }
    );
  }

  addCase() {
    this.saveSpinner = true;

    if (this.createFromFile) {
      this.case.newCaseFileLocation = this.fileLocation.fileNameWithExtension;
    }
    // this.case.pageObject = false;
    this.caseService.addCase(this.case, this.createFromFile ? undefined : this.selectedTemplate)
      .subscribe(
        res => {
          this.saveSpinner = false;
          try {
            this.saveNewDefaultValues();
          } catch (e) {
            console.error(e);
          }

          if (this.redirect) {
            this.openCase(res.id, res.pageObject == null || res.pageObject == true, res);
          }
          this.closeDialog(res);
        },
        error => {
          this.showError(error);
          this.saveSpinner = false;
        }
      );
  }

  private openCase(id, isPageObject, testCase: Case) {
    if (this.openCreatedCase) {
      this.router.navigate(
        ['cases-workspace'],
        {
          queryParams: {
            caseId: id,
            scriptTab: true
          }
        }
      );

      let prompt =
        "You are a coding assistant named CodeGPT. Your role is to help users create scripts by engaging in a dialogue to understand their coding goals and requirements. Ask the user a series of questions to gather information about the desired script, including its purpose, functionality, input/output, error handling, and any specific constraints. Based on the user's responses, generate the appropriate code in the specified programming language (Java, Python, or JavaScript) and provide explanations or suggestions as needed.\n" +
          "If you need to understand anything about the machine where the code will be executed, you can send a command line that you want executed by prefixing it with 'SYSTEM CALL:'. For example, 'SYSTEM CALL: find / -name \"pom.xml\" | cat' will search for a 'pom.xml' file and return its content, allowing you to determine if any new dependencies are required. You can also use 'curl' to access the internet and gather additional information." +
          "\n" +
        "\nYour starting code template is as follows:" +
        "\n```" + testCase.caseType.toLowerCase() + "\n" +
        testCase.code +
        "\n```\n" +
        "\nBegin the conversation by introducing yourself and asking the user about the purpose and main functionality of the script they want to create.";
      this.globalEventsManagerService.askAI(prompt);

    }
  }

  private duplicateCase() {
    this.saveSpinner = true;

    // if (this.setNewFileLocation) {
    //   if (this.advanceFileLocation) {
    //     this.duplicateCaseInfo.caseFileLocation = this.fileLocation;
    //   } else {
    //     let x = this.fileLocationName.substr(this.fileLocationName.lastIndexOf('/') + 1);
    //     this.duplicateCaseInfo.name = x;
    //     const oldFileNameWithExtension = this.fileLocation.fileNameWithExtension;
    //     this.fileLocation.packageCasePartWithoutExtension = this.fileLocationName;
    //     // this.fileLocation.packageCasePart = this.fileLocation.packageCasePart.replace(oldFileNameWithExtension, this.fileLocation.fileNameWithExtension);
    //     this.fileLocation.packageCasePart = this.fileLocation.packageCasePartWithoutExtension + '.' + this.fileLocation.extension;
    //     this.fileLocation.fullPath = this.fileLocation.projectParamPart + this.fileLocation.buildAutomationToolPart + this.fileLocation.packageCasePart;
    //     this.duplicateCaseInfo.caseFileLocation = this.fileLocation;
    //   }
    //
    //   if (this.startFileLocationFullPath == this.fileLocation.fullPath) {
    //     this.showErrorAlert = true;
    //     this.error = {
    //       error: {
    //         errorMessage: 'Change file location'
    //       }
    //     };
    //     this.saveSpinner = false;
    //   } else {
    //     this.showErrorAlert = false;
    //   }
    // }

    this.caseService.duplicateCase(this.case.id, this.duplicateCaseInfo).subscribe(
      res => {
        this.saveSpinner = false;
        this.openCase(res.id, res.pageObject == null || res.pageObject == true, res);
        this.closeDialog(res);
      },
      getError => {
        this.saveSpinner = false;
        console.error(getError);
        this.showError(getError);
      }
    );

  }

  parseTestingFrameworkName(name) {
    switch (name) {
      case 'JUNIT':
        return 'JUnit';
      case 'TESTNG':
        return 'TestNG';
      case 'CLEAN_JAVA':
        return 'Java Native';
      case 'PYTEST':
        return 'Pytest';
      case 'ROBOT_FRAMEWORK':
        return 'Robot Framework';
      case 'UNITTEST':
        return 'Unittest';
      case 'MOCHA':
        return 'Mocha';
      case 'JASMINE':
        return 'Jasmine';
      case 'WEBDRIVERIO':
        return 'WebdriverIO';
      case 'CLEAN_JAVASCRIPT':
        return 'JavaScript Native';
      case 'CLEAN_PYTHON':
        return 'Python Native';
      case 'NUNIT':
        return 'NUnit';
      case 'CLEAN_CSHARP':
        return 'C# Native';
      default:
        return '';
    }
  }

  getCaseTypeFromExtension(extension) {
    switch (extension.toLowerCase()) {
      case 'java':
        return 'JAVA';
      case 'mxml':
        return 'MXML';
      case 'utam':
        return 'UTAM';
      case 'py':
        return 'PYTHON';
      case 'robot':
        return 'ROBOT_FRAMEWORK';
      case 'js':
        return 'JAVASCRIPT';
      case 'cs':
        return 'CSHARP';
    }
  }

  getTestingFrameworksFor(casesPageObjects, type) {
    const testingFrameworks = [];
    this.templates.forEach(template => {
      if (template.type.toLowerCase() === casesPageObjects && template.type.toLowerCase() === casesPageObjects && template.caseType.toLowerCase() === type.toLowerCase() && !testingFrameworks.includes(template.testingFramework)) {
        testingFrameworks.push(template.testingFramework);
      }
    });

    this.case.testingFramework = testingFrameworks[0];
    this.filteredTestingFrameworks = testingFrameworks;
    this.getTemplatesFor(casesPageObjects, this.case.caseType, this.case.testingFramework);
  }

  getTemplatesFor(type, caseType, testingFramework) {

    if (this.case.pageObject) {
      this.filteredTemplates = this.templates.filter(
        template => {
          return template.type.toLowerCase() == type.toLowerCase() &&
            template.caseType.toLowerCase() === caseType.toLowerCase();
        }
      );
      this.selectedTemplate = this.filteredTemplates[0].name;

      this.filteredTemplates.forEach(template => {
        if (template.name.toLowerCase().includes(this.defaultPageObjectTemplate.toLowerCase())) {
          this.selectedTemplate = template.name;
        }
      });
    } else {
      this.filteredTemplates = this.templates.filter(template => {

          return template.type.toLowerCase() === type.toLowerCase() &&
            template.caseType.toLowerCase() === caseType.toLowerCase() &&
            template.testingFramework.toLowerCase() === testingFramework.toLowerCase();
        }
      );

      this.selectedTemplate = this.filteredTemplates[0].name;

      this.filteredTemplates.forEach(template => {
        if (template.name.toLowerCase() === this.defaultCaseTemplate.toLowerCase()) {
          this.selectedTemplate = template.name;
        }
      });
    }
  }

  private getTemplatesList() {
    this.templateService.getTemplatesList().subscribe(
      res => {
        this.templates = res;

        // if (this.fromPageObject) {
        //   this.getTemplatesFor('pageObjects', this.case.caseType, this.case.testingFramework);
        // } else
        if (this.case.pageObject) {
          // this.getTestingFrameworksFor('pageObjects', this.case.caseType);

          this.getTemplatesFor('pageObjects', this.case.caseType, this.case.testingFramework);
        } else {
          this.getTestingFrameworksFor('cases', this.case.caseType);
          this.getTemplatesFor('cases', this.case.caseType, this.case.testingFramework);
        }
      },
      error => {
        this.showError(error);
      }
    );
  }

  openAdvance() {
    this.fileLocation.packageCasePartWithoutExtension = this.fileLocationName;

    const newFileLocation = {...this.fileLocation};

    const dialogRef = this.dialog.open(ChangeFileLocationDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: {
        fileLocation: newFileLocation,
        testCase: this.case,
        autoUpdate: false
      }
    });

    dialogRef.afterClosed().subscribe((caseFileLocation: CaseFileLocation) => {
      if (caseFileLocation) {
        if (this.fileLocation.fullPath != caseFileLocation.fullPath) {
          this.advanceFileLocation = true;
        }
        this.case.fileLocation = caseFileLocation;
        this.fileLocation = caseFileLocation;
        this.fileLocationName = this.case.fileLocation.packageCasePartWithoutExtension;
      }
    });
  }

}
