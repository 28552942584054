import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchService } from './search.service';
import { SearchCriteria } from './search-criteria.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {

  searchCriteria: SearchCriteria = new SearchCriteria();
  searchResults: { fileName: string, filePath: string, lineNumber: string, lineContent: string }[] = [];
  searchEnabled: boolean = false;
  isSearching: boolean = false;
  searchHeader: string = 'Search';
  displayedColumns: string[] = ['fileName', 'path'];
  searchStatusMessage: string = '';

  constructor(
      public dialogRef: MatDialogRef<SearchComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private searchService: SearchService
  ) {
    if (data.path) {
      this.searchCriteria.directoryPath = data.path;
    }
    this.updateDisplayedColumns();
  }

  startSearch(): void {
    this.searchResults = [];
    this.searchStatusMessage = '';
    this.searchEnabled = true;
    this.isSearching = true;
    this.updateDisplayedColumns();

    this.searchService.getSearchResults(this.searchCriteria).subscribe(
        results => {
          this.searchStatusMessage = '';
          if (results.length > 0) {
            const lastResult = results[results.length - 1];
            if (lastResult === 'STOP_REQUEST_RECEIVED') {
              this.searchStatusMessage = 'Search was halted upon user request.';
              results.pop(); // Remove the last item
            } else if (lastResult === 'RESULT_SET_LIMIT_REACHED') {
              this.searchStatusMessage = 'Search limit reached. Refine your criteria for more focused results.';
              results.pop(); // Remove the last item
            }
          } else {
            this.searchStatusMessage = 'No results found.';
          }
          this.searchResults = results.map(result => {
            // Split the result into parts, but limit to 3 parts to avoid splitting the actual content
            const [fullPath, lineNumber, ...lineContentParts] = result.split(':');
            const lineContent = lineContentParts.join(':'); // Re-join in case the content contains colons

            // Extract the path and filename
            const pathParts = fullPath.split('/');
            const fileName = pathParts.pop() || '';
            const filePath = pathParts.join('/') || '/'; // Ensure leading slash for root path

            return { fileName, filePath, lineNumber, lineContent };
          });
          this.searchEnabled = false;
          this.isSearching = false;
        },
        error => {
          console.error(error);
          this.searchEnabled = false;
          this.isSearching = false;
        }
    );
  }




  stopSearch(): void {
    this.isSearching = false;
    this.searchService.stopSearch().subscribe(
        () => {
          this.searchEnabled = false;
        },
        error => {
          console.error('Error stopping the search', error);
          this.searchEnabled = false;
        }
    );
  }

  onFileNameClick(element: { fileName: string, filePath: string, lineNumber: string, lineContent: string }): void {
    const resultString = `${element.filePath}/${element.fileName}`;
    this.dialogRef.close(
      {
        path: resultString,
        lineNumber: element.lineNumber
      }
      );
  }


  private updateDisplayedColumns() {
    if (this.searchCriteria.searchString && this.searchCriteria.searchString.trim() !== '') {
      this.displayedColumns = ['fileName', 'path', 'matchedLines'];
    } else {
      this.displayedColumns = ['fileName', 'path'];
    }
  }
  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.searchShortcutListener, false);

  }

  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for creating the test
  searchShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('startSearchButton').click();
    }
  };

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.searchShortcutListener, false);
  }

}

