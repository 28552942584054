import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

@Component({
  selector: 'app-ai-input',
  templateUrl: './ai-input.component.html',
  styleUrls: ['./ai-input.component.css']
})
export class AiInputComponent implements OnInit {
  @Input('message') message = '';
  @Input('ai') ai = false;
  @Input('isRunActive') isRunActive = false;
  @Input('disableInput') disableInput = false;
  @Input('inputActive') inputActive = false;
  @Output('inputActiveChanges') inputActiveChanges = new EventEmitter<boolean>();
  @Output('stopRequest') stopRequestEmitter = new EventEmitter<boolean>();
  @Input('inputHeight') inputHeight = 91;
  value: string = '';

  @ViewChild('textareaElement') textareaElement: ElementRef;
  // @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Output('askQuestion') askQuestion = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit(): void {
  }


  askQuestionEvent() {
    this.askQuestion.emit(this.value);
    this.value = '';
    this.inputActive = false;
    this.inputActiveChanges.emit(this.inputActive);
  }

  public addToPrompt(addToPrompt: string) {
    this.value += addToPrompt;
    this.inputActive = true;
  }

  focusOnInput() {
    this.inputActive = true;
    this.inputActiveChanges.emit(this.inputActive);
  }

  focusOutInput() {
    this.inputActive = false;
    this.inputActiveChanges.emit(this.inputActive);
  }

  stopEvent() {
    this.stopRequestEmitter.emit(true);
  }
}
