<section class="main-container" style="height: calc(100vh - 54px) !important;     display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
font-size: 14px;">
  <div style="width: 100%">
    <div class="nav-tree-category-title" style="height: 30px; background: #d9d7d7;">
      <div style="padding-left: 1rem">
        <span class="mr-1">AI Chat</span>
        <!--        warningMessage-->

        <span *ngIf="this.projectParamValue == null || this.projectParamValue == ''" class="project-params-link"
              (click)="openAddOrEditProjectParamDialog()" [matTooltip]="'You have a daily limit of '+maximumNumberOfRequests+' requests with the MaxTAF Cloud AI key.\n'+
'You have already used '+currentNumberOfRequests+' requests.\n'+
'If you would like to use your own openAI key, specify it in the configuration parameters as the value for \'mx.ai.openai.key\'.'">
          ({{currentNumberOfRequests}}/{{maximumNumberOfRequests}})
        </span>
      </div>

      <div class="ai-options">


        <button mat-icon-button class="show-hide-nav-bar-button"
                [matTooltip]="'Scroll down'"
                (click)="scrollToBottom()">
          <mx-icon [mx]="'arrow-collapse-down'"></mx-icon>
        </button>
        <button mat-icon-button class="show-hide-nav-bar-button"
                [matTooltip]="'Resize ai'"
                [disabled]="this.userService.isCurrentUserGuest() || this.userService.isCurrentUserAnonymous() "
                (click)="resizeAI()">
          <mx-icon [mx]="!resizeAIMode?'arrow-collapse-right' : 'arrow-collapse-left'"></mx-icon>
        </button>
        <button mat-icon-button class="show-hide-nav-bar-button"
                [matTooltip]="'Delete'"
                (click)="menuDeleteAi()">
          <mx-icon [mx]="'delete'"></mx-icon>
        </button>
        <button mat-icon-button class="show-hide-nav-bar-button"
                [matTooltip]="'options'"
                [disabled]="this.userService.isCurrentUserGuest() || this.userService.isCurrentUserAnonymous() "
                (click)="openOptionsContextMenu($event); $event.stopPropagation();">
          <mx-icon mx="options"></mx-icon>
        </button>
        <button mat-icon-button class="show-hide-nav-bar-button"
                [matTooltip]="'Hide conversation'"
                (click)="hideShowVisibility()">
          <mx-icon mx="minus"></mx-icon>
        </button>
      </div>

    </div>

    <ng-template #contextMenu>
      <section class="node-context-menu" (clickOutside)="closeContextMenu()">

        <button class="context-menu-button" mat-menu-item (click)="menuRefreshAi()">
          <mx-icon marginBottom="true" mx="refresh"></mx-icon>
          Refresh
        </button>
        <!--        <button class="context-menu-button" mat-menu-item (click)="menuDeleteAi()">-->
        <!--          <mx-icon marginBottom="true" mx="delete"></mx-icon>-->
        <!--          Delete-->
        <!--        </button>-->
        <button class="context-menu-button" mat-menu-item (click)="menuExportAi()">
          <mx-icon marginBottom="true" mx="export"></mx-icon>
          Export
        </button>
        <button class="context-menu-button" mat-menu-item (click)="menuAiSetting()">
          <mx-icon marginBottom="true" mx="settings"></mx-icon>
          Settings
        </button>
      </section>
    </ng-template>


  </div>

  <div class="ai-guest-anonymous"
       *ngIf="this.userService.isCurrentUserAnonymous() || this.userService.isCurrentUserGuest()">
      <span>
        As a {{(this.userService.isCurrentUserAnonymous() ? 'anonymous' : 'guest')}} you do not have access to AI on this project
      </span>
  </div>

  <as-split direction="vertical" unit="pixel" [gutterSize]="5"
            *ngIf="!this.userService.isCurrentUserAnonymous() && !this.userService.isCurrentUserGuest()">

    <as-split-area #chatArea style="overflow: hidden;caret-color: transparent;">

      <div
        [ngStyle]="{
  'height': getChetHeight()
  }"
        style="overflow-y: auto;">

        <overlay-scrollbars
          #pageScroll
          [ngClass]="{ 'height-without-board': true }"
          [options]="{ scrollbars: { autoHide: 'l' } }"
        >
<!--          <app-ai-message-->
<!--            *ngFor="let item of this.chat"-->
<!--            (refreshAndAsk)="refreshDataAndAsk($event)"-->
<!--            [ai]="item.ai"-->
<!--            [chatId]="item.id"-->
<!--            [message]="item.content">-->
<!--          </app-ai-message>-->

          <ng-container *ngFor="let item of this.chat">
            <app-ai-message
              *ngIf="!item.content.startsWith('You are a coding assistant named CodeGPT. Your role is to help users create scripts by engaging in a dialogue to understand their coding goals and requirements.')"
              (refreshAndAsk)="refreshDataAndAsk($event)"
              [ai]="item.ai"
              [chatId]="item.id"
              [message]="item.content">
            </app-ai-message>
          </ng-container>

          <app-ai-message
            *ngIf="activeChat  != null"
            (refreshAndAsk)="refreshDataAndAsk($event)"
            [ai]="activeChat.ai"
            [chatId]="activeChat.id"
            [message]="activeChat.content">
          </app-ai-message>
          <app-ai-load-response
            *ngIf="showLoadResponseDiv">
          </app-ai-load-response>
        </overlay-scrollbars>
      </div>
    </as-split-area>

    <as-split-area
      [size]="getAiInputHeight()"
      [minSize]="(inputActive? 210 :91)"
      [maxSize]="(inputActive? 210 :91)"
      #inputArea
      style="overflow: hidden; background: #d9d7d7;"
    >
      <div class="ai-input-wrapper" #aiInputWrapper>
        <div
          *ngIf="errorMessage != null && errorMessage != ''"
          style="
  border-top: #007bff 1px solid;
    border-bottom: #007bff 1px solid;
  background: #d9d7d7;"
        >

        <span class="close-dialog" style="float: right; cursor: pointer; padding-right: 5px;padding-top: 5px;"
              (click)="closeMessage()">
          <mat-icon>close</mat-icon>
        </span>

          <div  class="message-box">
            {{errorMessage}}
          </div>

        </div>

        <div [ngClass]="
      {
      'input-active': inputActive && !disableInput,
      'normal-input': !inputActive || disableInput
      }">
          <app-ai-input
            #aiInput
            [inputActive]="inputActive"
            (inputActiveChanges)="inputActiveChanges($event)"
            [disableInput]="disableInput"
            [isRunActive]="isRunActive && !disableInput"
            (askQuestion)="askAndSaveQuestion($event)"
            (stopRequest)="stopAIRequest()">

          </app-ai-input>
        </div>
      </div>
    </as-split-area>
  </as-split>
</section>
