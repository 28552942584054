<section class="main-container">

  <div class="row">
    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select placeholder="Filter done as exact match, or not"
                    [(value)]="search.exactSearch">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <button (click)="filterRecords()" mat-raised-button color="primary" class="mr-1 mb-1">
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button (click)="clearImportCaseFilter()" mat-raised-button color="primary" class="mr-1 mb-1">
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button (click)="clickedClose()" mat-raised-button class="mb-1"
              color="primary">
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>

  </div>


  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-label>Created date</mat-label>
        <input #fromInput matInput [matDatepicker]="datepickerMin" (dateInput)="setMinRunDate($event.value)">
        <mat-datepicker-toggle matSuffix [for]="datepickerMin"></mat-datepicker-toggle>
        <mat-datepicker #datepickerMin></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <mat-label>Last update date</mat-label>
        <input #toInput matInput [matDatepicker]="datepickerMax" (dateInput)="setMaxRunDate($event.value)">
        <mat-datepicker-toggle matSuffix [for]="datepickerMax"></mat-datepicker-toggle>
        <mat-datepicker #datepickerMax></mat-datepicker>
      </mat-form-field>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select placeholder="Is done"
                    [(value)]="search.completed">
          <mat-option value=""></mat-option>
          <mat-option value="Yes">Yes</mat-option>
          <mat-option value="No">No</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('errors')" type="text"
               placeholder="Errors.."
               [(ngModel)]="search.errors">
      </mat-form-field>
    </div>

  </div>
</section>
