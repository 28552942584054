import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CompileInfo} from '../../shared/models/compileInfo';
import * as moment from 'moment';
import {CompileService} from '../../shared/services/compile.service';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CompileCaseInfo} from '../../shared/models/CompileCaseInfo';
import {CompileInfoCaseInfoListComponent} from '../compile-info-case-info-list/compile-info-case-info-list.component';
import {
  CompileInfoCaseInfoConstParams,
  CompileInfoCaseInfoConstService,
  CompileInfoCaseInfoDialogParams
} from './compile-info-case-info-const.service';
import {CheckPath} from '../../../mx/options/CheckPath';
import {
  CompileInfoCaseInfoOptionsPaths
} from '../compile-info-case-info-options/compile-info-case-info-options.component';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-compile-info-details-dialog',
  templateUrl: './compile-info-details-dialog.component.html',
  styleUrls: ['./compile-info-details-dialog.component.css']
})
export class CompileInfoDetailsDialogComponent implements OnInit {

  compileInfo: CompileInfo = new CompileInfo();

  pagesInfo: PageInfo = new PageInfo();
  compileInfos: CompileCaseInfo[] = [];
  @ViewChild(CompileInfoCaseInfoListComponent) compileInfoList: CompileInfoCaseInfoListComponent;
  params: CompileInfoCaseInfoDialogParams;


  constructor(private dialogRef: MatDialogRef<CompileInfoDetailsDialogComponent>,
              private allDialogRef: MatDialog,
              private compileService: CompileService,
              protected route: ActivatedRoute,
              protected router: Router,
              @Inject(MAT_DIALOG_DATA) data: {
                compileInfo: CompileInfo
              }) {
    this.compileInfo = data.compileInfo;
    this.compileService.compileCasesInfo(this.compileInfo.id).subscribe(
      (res: CompileInfo) => {
        this.compileInfo = res;
        this.importInfoResponse(this.compileInfo);
      },
      error => {
        console.error(error);
      }
    );
    this.params = CompileInfoCaseInfoConstService.initCompileInfoCaseInfoDialogParams(5);
  }

  private importInfoResponse(res: CompileInfo) {
    this.compileInfo = res;
  }

  ngOnInit(): void {
  }


  getCompileInfoCaseInfoListOptionsCheckPathObject(): CompileInfoCaseInfoOptionsPaths {
    return {
      refresh: new CheckPath('GET', this.compileService.compileCasesInfos()),
      filter: new CheckPath('GET', this.compileService.compileCasesInfos())
    };
  }

  filter(compileInfoCaseInfoConstParams: CompileInfoCaseInfoConstParams) {
    this.compileInfoList.getItems();
  }

  refreshCompileInfo() {
    this.compileInfoList.getItems();
  }

  setPageInfoChanges(pagesInfo: PageInfo) {
    this.pagesInfo = pagesInfo;
  }

  pageParamsChange(pageParams: PageParams) {
    this.params.page = pageParams;
  }

  private showError(error: any) {
    console.error(error);
  }


  clickedCompileInfo(compileCaseInfo: ClickedTableItem<CompileCaseInfo>) {
    //
    // const dialogRef = this.dialog.open(CompileInfoDetailsDialogComponent, {
    //   width: '600px',
    //   data: {
    //     compileInfo: compileInfo.item
    //   }
    // });
    //
    // dialogRef.afterClosed().subscribe(result => {
    //
    // });

  }


  setFormat(runDate: Date) {
    const startFormat = moment.localeData(navigator.language).longDateFormat('lll');
    const format = startFormat.substr(0, startFormat.substr(0, startFormat.indexOf(':')).lastIndexOf(' ')) + ', h:mm:ss a';
    return moment(runDate).format(format);
  }

  paramsChangeValue() {

  }

  compiledError(compiledError: any) {
    this.router.navigate(
      [
        'cases-workspace'
      ],
      {
        queryParams: {
          caseId: compiledError.compileCaseInfo.testCaseId,
          scriptTab: true,
          selectLine: compiledError.compiledError.line,
          selectPath: compiledError.compiledError.path,
          selectType: 'compile',
          compileError: compiledError.compileCaseInfo.compileError,
          transpileError: compiledError.compileCaseInfo.transpileError,
        }
      });
    this.allDialogRef.closeAll();
  }

  transpiledError(transpiledError: any) {
    this.router.navigate(
      [
        'cases-workspace'
      ],
      {
        queryParams: {
          caseId: transpiledError.compileCaseInfo.testCaseId,
          scriptTab: true,
          selectLine: transpiledError.compiledError.line,
          selectPath: transpiledError.compiledError.path,
          selectType: 'transpile',
          compileError: transpiledError.compileCaseInfo.compileError,
          transpileError: transpiledError.compileCaseInfo.transpileError,
        }
      });
    this.allDialogRef.closeAll();
  }
}
