import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ai-text',
  templateUrl: './ai-text.component.html',
  styleUrls: ['./ai-text.component.css']
})
export class AiTextComponent implements OnInit {

  message = undefined;

  @Input('message') set setMessage(newMessage: string) {
    this.message = newMessage;
    //this.message = newMessage.replace(/`([^`]*)`/g, '<b>`$1`</b>');
  }

  @Input('ai') ai = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  protected readonly undefined = undefined;
}
