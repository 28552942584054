<section class="main-container ai-title-wrapper"
         [ngClass]="{'ai-color': ai, 'user-color': !ai}"
>
  <div class="title-box">
    <mx-icon mx="user" *ngIf="!ai"></mx-icon>
    <mx-icon mx="ai" *ngIf="ai"></mx-icon>
    <span class="title">{{(ai ? 'AI Chat' : 'You')}}</span>
  </div>
  <div class="title-buttons">
    <button mat-button
            style="margin: 0;padding: 0"
            [matTooltip]="'Copy to clipboard'"
            (click)="copyToClipboard(); $event.stopPropagation()">
      <mx-icon mx="copy"></mx-icon>
      <!--    <span>Copy to clipboard</span>-->
    </button>
  </div>
</section>
