<h5 mat-dialog-title class="ml-4">Create Folder</h5>

<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>
  <div style="width: 80%; margin: 0 auto;" class="pb-3">
    <label>Folder name</label>
    <input [disabled]="saveSpinner" type="text" [(ngModel)]="folderName" maxlength="32">
  </div>
  <!--  <div style="width: 80%; margin: 0 auto;" class="pb-3">-->
  <!--    <mat-checkbox type="checkbox" [checked]="overwrite" (change)="overwrite = !overwrite"-->
  <!--                  id="overwriteCheck">Overwrite data from zip file?-->
  <!--    </mat-checkbox>-->
  <!--  </div>-->
</div>
<div mat-dialog-actions>
  <button mat-button *ngIf="!saveSpinner" [mat-dialog-close] id="cancelButton">Cancel</button>

  <button mat-button *ngIf="!saveSpinner" id="createButton" (click)="createNewFolder()" color="primary">Create</button>
  <button mat-button *ngIf="saveSpinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
