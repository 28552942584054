<h5 mat-dialog-title class="ml-4">Zip '{{node != undefined ? node.name : ''}}' folder</h5>

<div mat-dialog-content>

  <app-error-message *ngIf="showErrorAlert" [error]="error"></app-error-message>

  <label>Zip location</label>
  <div style="width: 80%; margin: 0 auto;" class="pb-3">
    <input type="text" [(ngModel)]="createZipFileLocation">
  </div>

  <div style="width: 80%; margin: 0 auto;" class="pb-3">
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwrite" (change)="overwrite = !overwrite"
                  id="overwriteCheck">Overwrite data from zip file?
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions style="margin-right: 24px">
  <button mat-button [mat-dialog-close]="false">Close</button>
  <button mat-button *ngIf="!spinner" (click)="zip()" color="primary">Zip</button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
