import {Component, Inject, OnInit} from '@angular/core';
import {Case} from '../../shared/models/case';
import {CaseFileLocation} from '../../shared/models/case-file-location';
import {DuplicateCase} from '../../shared/models/duplicateCase';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TemplateService} from '../../../../shared/services/template.service';
import {CaseService} from '../../shared/services/case.service';
import {SuiteService} from '../../../suites/shared/services/suite.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {ChangeFileLocationDialogComponent} from '../change-file-location-dialog/change-file-location-dialog.component';

@Component({
  selector: 'app-create-page-object-dialog',
  templateUrl: './create-page-object-dialog.component.html',
  styleUrls: ['./create-page-object-dialog.component.css']
})
export class CreatePageObjectDialogComponent implements OnInit {

  showErrorAlert = false;
  error;

  selectedTemplate = '';
  templates = [];
  filteredTemplates = [];
  filteredTestingFrameworks = [];

  case: Case;
  saveSpinner = false;
  duplicate = false;
  fileLocationName = '';
  startFileLocationFullPath = '';

  createFromFile = false;
  fileLocation: CaseFileLocation = undefined;
  extension: string = undefined;

  openCreatedCase = true;

  isLicensed = false;

  setNewFileLocation = false;
  advanceFileLocation = false;

  duplicateCaseInfo = new DuplicateCase();

  // newCaseFilePath: string;
  // type: string;
  blockType = false;
  fromWorkspace = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private templateService: TemplateService,
    private caseService: CaseService,
    private suiteService: SuiteService,
    private projectService: ProjectService,
    private maxtafTokensStorageService: MaxtafTokensStorageService,
    public dialogRef: MatDialogRef<CreatePageObjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      // duplicate
      duplicate: boolean,
      // duplicate
      id: string,

      // create from file
      fileLocation: CaseFileLocation,
      newCaseFileLocation: string,

      // create from file
      code: string,
      // create from file
      testName: string,
      // create from file
      extension: string,

      openCreatedCase: boolean,

      newCaseFilePath: string,
      type: string,
      blockType: boolean,
      fromWorkspace: boolean,
    }
  ) {
    projectService.isLicensed(maxtafTokensStorageService.getProjectId()).subscribe(
      res => {
        this.isLicensed = res;
      },
      error => {
        this.showError(error);
      }
    );

    this.getTemplatesList();
    dialogRef.disableClose = true;

    this.case = new Case();
    this.case.caseType = 'JAVA';
    this.case.testingFramework = 'JUNIT';
    this.case.id = this.data.id;

    // TODO check
    this.case.code = this.data.code;
    this.case.name = this.data.testName;

    if (data.fileLocation != undefined) {
      this.createFromFile = true;
      this.fileLocation = data.fileLocation;

      if (data.fileLocation.fileName.trim().replace('_', ' ').toLowerCase() != this.data.testName.trim().toLowerCase()) {
        this.setNewFileLocation = true;
      }

      this.fileLocationName = this.fileLocation.packageCasePartWithoutExtension;
      this.startFileLocationFullPath = this.fileLocation.fullPath;
    }

    if (this.data.newCaseFileLocation) {
      this.case.newCaseFileLocation = this.data.newCaseFileLocation;
    }

    if (data.extension != undefined) {
      this.extension = data.extension;
      if (this.extension == 'json' && this.case.name.includes('.utam')) {
        this.extension = 'utam.json';
      }
      this.case.caseType = this.getCaseTypeFromExtension(this.extension);
    }

    if (data.duplicate != undefined) {
      this.duplicate = data.duplicate;
    }

    if (data.newCaseFilePath != undefined) {
      this.case.newCaseFilePath = data.newCaseFilePath;
    }

    if (data.blockType != undefined) {
      this.blockType = data.blockType;
    }

    if (data.type != undefined) {
      this.case.caseType = data.type;
    }

    if (data.fromWorkspace != undefined) {
      this.fromWorkspace = data.fromWorkspace;
    }

    if (data.openCreatedCase != undefined) {
      this.openCreatedCase = data.openCreatedCase;
    }


  }


  // ESC shortcut for closing the window
  cancelShortcutListener = (e) => {
    if (e.keyCode == 27) {
      e.preventDefault();
      document.getElementById('cancelButton').click();
    }
  };

  // ENTER shortcut for running the test
  runShortcutListener = (e) => {
    if (e.keyCode == 13) {
      e.preventDefault();
      document.getElementById('createButton').click();
    }
  };

  ngOnInit(): void {
    document.addEventListener('keydown', this.cancelShortcutListener, false);
    document.addEventListener('keydown', this.runShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.cancelShortcutListener, false);
    document.removeEventListener('keydown', this.runShortcutListener, false);
  }


  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  closeDialog(value): void {
    this.dialogRef.close(value);
  }

  do() {
    if (this.duplicate) {
      this.duplicateCase();
    } else {
      this.save();
    }
  }

  save() {
    this.addCase();
  }

  addCase() {
    this.saveSpinner = true;

    if (this.fromWorkspace) {

    }


    if (this.createFromFile) {
      this.case.newCaseFileLocation = this.fileLocation.fileNameWithExtension;
    }

    if (this.case.name.includes('.utam')) {
      this.case.name = this.case.name.replace('.utam', '');
    }

    this.case.pageObject = true;

    this.caseService.addCase(this.case, this.createFromFile ? undefined : this.selectedTemplate)
      .subscribe(
        res => {
          this.saveSpinner = false;
          this.openCase(res.id, res.pageObject == null || res.pageObject == true);
          this.closeDialog(res);
        },
        error => {
          this.showError(error);
          this.saveSpinner = false;
        }
      );
  }

  private openCase(id, isPageObject) {
    if (this.openCreatedCase) {
      this.router.navigate(
        ['cases-workspace'],
        {queryParams: {caseId: id}}
      );
    }
  }

  private duplicateCase() {
    this.saveSpinner = true;

    if (this.setNewFileLocation) {
      if (this.advanceFileLocation) {
        this.duplicateCaseInfo.caseFileLocation = this.fileLocation;
      } else {
        const oldFileNameWithExtension = this.fileLocation.fileNameWithExtension;
        this.fileLocation.packageCasePartWithoutExtension = this.fileLocationName;
        // this.fileLocation.packageCasePart = this.fileLocation.packageCasePart.replace(oldFileNameWithExtension, this.fileLocation.fileNameWithExtension);
        this.fileLocation.packageCasePart = this.fileLocation.packageCasePartWithoutExtension + '.' + this.fileLocation.extension;
        this.fileLocation.fullPath = this.fileLocation.projectParamPart + this.fileLocation.buildAutomationToolPart + this.fileLocation.packageCasePart;
        this.duplicateCaseInfo.caseFileLocation = this.fileLocation;
      }

      if (this.startFileLocationFullPath == this.fileLocation.fullPath) {
        this.showErrorAlert = true;
        this.error = {
          error: {
            errorMessage: 'Change file location'
          }
        };
        this.saveSpinner = false;
      } else {
        this.showErrorAlert = false;
      }
    }
    this.caseService.duplicateCase(this.case.id, this.duplicateCaseInfo).subscribe(
      res => {
        this.saveSpinner = false;
        this.openCase(res.id, res.pageObject == null || res.pageObject == true);
        this.closeDialog(res);
      },
      getError => {
        this.saveSpinner = false;
        console.error(getError);
        this.showError(getError);
      }
    );
  }

  parseTestingFrameworkName(name) {
    switch (name) {
      case 'JUNIT':
        return 'JUnit';
      case 'TESTNG':
        return 'TestNG';
      case 'CLEAN_JAVA':
        return 'Java Native';
      case 'PYTEST':
        return 'Pytest';
      case 'ROBOT_FRAMEWORK':
        return 'Robot Framework';
      case 'UNITTEST':
        return 'Unittest';
      case 'MOCHA':
        return 'Mocha';
      case 'WEBDRIVERIO':
        return 'WebdriverIO';
      case 'CLEAN_JAVASCRIPT':
        return 'JavaScript Native';
      case 'CLEAN_PYTHON':
        return 'Python Native';
      case 'NUNIT':
        return 'NUnit';
      case 'CLEAN_CSHARP':
        return 'C# Native';
      default:
        return '';
    }
  }

  getCaseTypeFromExtension(extension) {
    switch (extension.toLowerCase()) {
      case 'java':
        return 'JAVA';
      case 'mxml':
        return 'MXML';
      case 'utam.json':
        return 'UTAM';
      case 'py':
        return 'PYTHON';
      case 'robot':
        return 'ROBOT_FRAMEWORK';
      case 'js':
        return 'JAVASCRIPT';
      case 'cs':
        return 'CSHARP';
    }
  }

  // getTestingFrameworksFor(type) {
  //   const testingFrameworks = [];
  //   this.templates.forEach(template => {
  //     if (template.caseType.toLowerCase() === type.toLowerCase() && !testingFrameworks.includes(template.testingFramework)) {
  //       testingFrameworks.push(template.testingFramework);
  //     }
  //   });
  //
  //   this.case.testingFramework = testingFrameworks[0];
  //   this.filteredTestingFrameworks = testingFrameworks;
  //   this.getTemplatesFor(this.case.caseType, this.case.testingFramework);
  // }

  getTemplatesFor(type, caseType, testingFramework) {

    this.filteredTemplates = this.templates.filter(
      template => (template.type.toLowerCase() == type.toLowerCase() && template.caseType.toLowerCase() === caseType.toLowerCase())
    );

    this.selectedTemplate = this.filteredTemplates[0].name;

    this.filteredTemplates.forEach(template => {
      if (template.name.toLowerCase().includes('blank')) {
        this.selectedTemplate = template.name;
      }
    });
  }

  private getTemplatesList() {
    this.templateService.getTemplatesList().subscribe(
      res => {
        this.templates = res;
        // this.getTestingFrameworksFor(this.case.caseType);
        this.getTemplatesFor('pageObjects', this.case.caseType, this.case.testingFramework);
      },
      error => {
        this.showError(error);
      }
    );
  }

  openAdvance() {
    this.fileLocation.packageCasePartWithoutExtension = this.fileLocationName;

    const newFileLocation = {...this.fileLocation};

    const dialogRef = this.dialog.open(ChangeFileLocationDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: {
        fileLocation: newFileLocation,
        testCase: this.case,
        autoUpdate: false
      }
    });

    dialogRef.afterClosed().subscribe((caseFileLocation: CaseFileLocation) => {
      if (caseFileLocation) {
        if (this.fileLocation.fullPath != caseFileLocation.fullPath) {
          this.advanceFileLocation = true;
        }
        this.case.fileLocation = caseFileLocation;
        this.fileLocation = caseFileLocation;
        this.fileLocationName = this.case.fileLocation.packageCasePartWithoutExtension;
      }
    });
  }

}
