<div class="filter-by">
  <button mat-icon-button
          [disabled]="disabled"
          class="prefix-input"
          (click)="runSearch(inputName.value)">
    <mx-icon [color]="disabled ? 'disabled': 'primary'" [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
             class="" mx="filter"></mx-icon>
  </button>

  <div class="input-name">
    <input
      [disabled]="disabled"
      placeholder="{{placeholderText}}"
      [(ngModel)]="filterValue"
      type="text"
      #inputName
      [cdkTrapFocusAutoCapture]="focus" [cdkTrapFocus]="focus"
      (keyup)="isSearchCollapsed ? openCloseSearchPanel():null"
      (keyup.space)="addSpace($event)"
      (keyup.enter)="runSearch(inputName.value)"
    >
  </div>

  <div style="position: relative" *ngIf="!hideHelpButtons">
    <button mat-icon-button [disabled]="disabled" class="close-input" (click)="clearText();runSearch('')"
            [style.visibility]="inputName.value == '' ? 'hidden' : 'visible'">
      <mx-icon [color]="disabled ? 'disabled': 'primary'" [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="close"></mx-icon>
    </button>

    <button matSuffix class="suffix-input" [disabled]="disabled" (click)="openCloseSearchPanel()" mat-icon-button
            color="primary">
      <mx-icon *ngIf="!isSearchCollapsed" [color]="disabled ? 'disabled': 'primary'"
               [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="open-arrow"></mx-icon>
      <mx-icon *ngIf="isSearchCollapsed" [color]="disabled ? 'disabled': 'primary'"
               [ngClass]="{'icon-suffix': true, 'cursor-pointer': !disabled}"
               mx="close-arrow"></mx-icon>
    </button>
  </div>
</div>

