import {CaseAndSuiteParent} from '../../../suites/shared/models/case-and-suite-parent';
import {CaseFileLocation} from './case-file-location';

export class Case extends CaseAndSuiteParent {
  code: string;
  transpiledCode: string;
  transpiledCode2: string;
  transpiledCode3: string;
  transpiledCode4: string;
  archived: boolean;
  caseType: string; // JAVA MXML
  fileLocation: CaseFileLocation = new CaseFileLocation();
  transpiledFileLocation: CaseFileLocation = new CaseFileLocation();
  transpiledFile2Location: CaseFileLocation = new CaseFileLocation();
  transpiledFile3Location: CaseFileLocation = new CaseFileLocation();
  transpiledFile4Location: CaseFileLocation = new CaseFileLocation();
  compileFileLocation: CaseFileLocation = new CaseFileLocation();
  newCaseFileLocation: string;
  newCaseFilePath: string;
  testingFramework: string;
  // pageObject: boolean;
  // runCaseId: string;
  // runCaseName: string;
}
