<h1 mat-dialog-title>Api key</h1>

<div mat-dialog-content>
  <div class="fs-12 alert alert-success alert-dismissible fade show" #message role="alert">
    <p>
      Success! Your API key has been created. Please make sure to copy and save it in a secure place immediately. We will not display this key again due to security purposes. If lost or forgotten, you will need to create a new one.
    </p>
    <p>
      Remember, your API key communicates a secure access between your application and our service. You need to include this key in the 'X-Maxtaf-Api-Key' header when making HTTP requests to our API. Please assure to keep the key confidential and avoid sharing it with unauthorized individuals.
    </p>
    <p>
      Keep in mind not to expose your API key in publicly accessible areas such as GitHub, client-side code, etc.
    </p>
  </div>

  <div class="fs-12 alert alert-secondary show" *ngIf="apiKey != undefined && apiKey != ''">
    <div style="width:1px"></div>
    <div style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;">
      {{apiKey}}
      <div style="max-width: 80px">
        <button color="primary" class="pl-0 pr-0" mat-icon-button *ngIf="apiKey != undefined && apiKey != ''"
                [autofocus]="false"
                (click)="copyLogToClipboard(); $event.stopPropagation()">
          <mx-icon mx="copy" size="small"></mx-icon>
        </button>
        <button color="primary" class="pl-0 pr-0" mat-icon-button *ngIf="apiKey != undefined && apiKey != ''"
                [autofocus]="false"
                (click)="downloadApiKey(); $event.stopPropagation()">
          <mx-icon mx="download" size="small"></mx-icon>
        </button>

      </div>

    </div>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false"
          (click)="closeDialog()">Close
  </button>
</div>

