import {catchError, filter, map} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {ApiService} from './api.service';
import {ConfigService} from './config.service';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {CloudConfigService} from './cloud-config.service';
import {ScheduleConfigService} from './schedule-config.service';
import {Params} from '../../layout/mx/util/params/params';

@Injectable()
export class FileService {

  private router;

  constructor(
    private apiService: ApiService,
    private config: ConfigService,
    private http: HttpClient,
    private cloudConfigService: CloudConfigService,
    private scheduleConfigService: ScheduleConfigService,
    private injector: Injector
  ) {
  }

  getImportInfoCase(id) {
    return this.apiService.get(this.cloudConfigService.getImportInfoCases(id), {});
  }

  getImportInfoCases(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getImportInfoCase(), params);
  }

  deleteCasesInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.cloudConfigService.getImportInfoCase() + '?a=b' + queryParams, params);
  }

  getImportInfoSuite(id) {
    return this.apiService.get(this.cloudConfigService.getImportInfoSuites(id), {});
  }

  getImportInfoSuites(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getImportInfoSuite(), params);
  }

  deleteSuitesInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.cloudConfigService.getImportInfoSuite() + '?a=b' + queryParams, params);
  }


  getImportInfoSchedule(id) {
    return this.apiService.get(this.scheduleConfigService.getImportInfoSchedules(id), {});
  }

  getImportInfoSchedules(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.scheduleConfigService.getImportInfoSchedule(), params);
  }

  deleteSchedulesInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.scheduleConfigService.getImportInfoSchedule() + '?a=b' + queryParams, params);
  }


  getImportInfoProjectParams(searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    return this.apiService.get(this.cloudConfigService.getImportInfoProjectParam(), params);
  }

  getImportInfoProjectParam(id) {
    return this.apiService.get(this.cloudConfigService.getImportInfoProjectParams(id), {});
  }

  deleteProjectParamsInfos(ids, searchParams?: any, pageParams?: any) {
    searchParams = new Params(searchParams);
    pageParams = new Params(pageParams);
    const params = {...searchParams, ...pageParams};

    let queryParams = '';

    for (const id of ids) {
      queryParams = queryParams + '&ids%5B%5D=' + id + '';
    }

    return this.apiService.delete(this.cloudConfigService.getImportInfoProjectParam() + '?a=b' + queryParams, params);
  }


  importFile(file: File, url: string, overwrite1: boolean, overwrite1RequestName: string, overwrite2: boolean, overwrite2RequestName: string, importType: string, compile: boolean, overwrite3RequestName: string, overwrite3: boolean) {
    let queryParams = '';
    if (overwrite1) {
      queryParams = queryParams + overwrite1RequestName + '=true' + '&';
    }

    if (overwrite2) {
      queryParams = queryParams + overwrite2RequestName + '=true' + '&';
    }
    if (overwrite2) {
      queryParams = queryParams + overwrite2RequestName + '=true' + '&';
    }

    if (overwrite3) {
      queryParams = queryParams + overwrite3RequestName + '=true' + '&';
    }

    if (compile != undefined) {
      queryParams = queryParams + 'compile' + '=' + compile + '&';
    }

    if (importType === 'xml') {
      queryParams = queryParams + 'source=maximo' + '&';
    }

    if (importType === 'junit') {
      queryParams = queryParams + 'source=java' + '&';
    }

    if (importType === 'python') {
      queryParams = queryParams + 'source=python' + '&';
    }

    if (importType === 'csharp') {
      queryParams = queryParams + 'source=csharp' + '&';
    }

    if (importType === 'javascript') {
      queryParams = queryParams + 'source=javascript' + '&';
    }

    const formdata: FormData = new FormData();

    formdata.append('importFile', file);

    const headers = new HttpHeaders({
      Accept: 'application/json'
    });

    const req = new HttpRequest('POST', url + '?' + queryParams, formdata, {
      headers,
      withCredentials: true,
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req).pipe(
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          this.redirectIfUnauthorized();
        }

        throw error;
      }));
  }

  exportFile(url: string): Observable<any> {

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json'
    });


    const req = new HttpRequest('GET', url, {}, {
      headers,
      withCredentials: true,
      responseType: 'blob'
    });

    return this.http.request(req).pipe(
      filter(response => response instanceof HttpResponse),
      map((response: HttpResponse<any>) => response),
      catchError(error => {
        console.error('error: ', error);

        if (error && error.status === 401) {
          this.redirectIfUnauthorized();
        }

        throw error;
      })
    );
  }

  private redirectIfUnauthorized() {
    if (this.router == null) {
      this.router = this.injector.get(Router);
    }

    this.router.navigate(['/logout']);
  }

}
