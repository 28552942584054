import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nav-tree-file-icon',
  templateUrl: './nav-tree-file-icon.component.html',
  styleUrls: ['./nav-tree-file-icon.component.css']
})
export class NavTreeFileIconComponent implements OnInit {
  @Input('icon') icon = 'file-outline';
  @Input('color') color = 'primary';

  @Input('extension') set setIcon(extension: string) {
    this.convertExtensionToIcon(extension);
  }

  constructor() {

  }

  ngOnInit(): void {

  }

  private convertExtensionToIcon(extension: string) {
    if (extension == 'java') {
      this.icon = 'language-java';
      return;
    }

    if (extension == 'class') {
      this.icon = 'file-cog-outline';
      return;
    }

    if (extension == 'py' || extension == 'python') {
      this.icon = 'language-python';
      return;
    }

    if (extension == 'mxml') {
      this.icon = 'cloud-tags';
      return;
    }

    if (extension == 'utam') {
      this.icon = 'flash';
      return;
    }

    if (extension == 'cases') {
      this.icon = 'clipboard-play-outline';
      return;
    }

    if (extension == 'pos') {
      this.icon = 'clipboard-text-outline';
      return;
    }

    if (extension == 'xml' || extension == 'xsd' || extension == 'xsl' || extension == 'xslt' || extension == 'xhtml' || extension == 'dom' || extension == 'kml' || extension == 'kmz') {
      this.icon = 'xml';
      return;
    }

    if (extension == 'json' || extension == 'template') {
      this.icon = 'code-json';
      return;
    }

    if (extension == 'csv') {
      this.icon = 'file-delimited-outline';
      return;
    }

    if (extension == 'xlsx' || extension == 'xlsm' || extension == 'xlsb' || extension == 'xltx' || extension == 'xltm' || extension == 'xls' || extension == 'xlt' || extension == 'xls' || extension == 'xlam' || extension == 'xla' || extension == 'xlw' || extension == 'xlr') {
      this.icon = 'microsoft-excel';
      return;
    }

    if (extension == 'text' || extension == 'txt') {
      this.icon = 'note-text-outline';
      return;
    }

    if (extension == 'docx' || extension == 'docm' || extension == 'dotx' || extension == 'dotm' || extension == 'docb' || extension == 'doc' || extension == 'dot' || extension == 'wbk') {
      this.icon = 'file-word-outline';
      return;
    }

    if (extension == 'html') {
      this.icon = 'language-html5';
      return;
    }

    if (extension == 'css') {
      this.icon = 'language-css3';
      return;
    }

    if (extension == 'c') {
      this.icon = 'language-c';
      return;
    }

    if (extension == 'cpp') {
      this.icon = 'language-cpp';
      return;
    }

    if (extension == 'sh') {
      this.icon = 'script-text-outline';
      return;
    }

    if (extension == 'cs') {
      this.icon = 'language-csharp';
      return;
    }

    if (extension == 'php') {
      this.icon = 'language-php';
      return;
    }

    if (extension == 'js') {
      this.icon = 'language-javascript';
      return;
    }

    if (extension == 'ts') {
      this.icon = 'language-typescript';
      return;
    }

    if (extension == 'kt') {
      this.icon = 'language-kotlin';
      return;
    }

    if (extension == 'zip') {
      this.icon = 'zip-box-outline';
      return;
    }

    if (extension == 'rar') {
      this.icon = 'zip-box';
      return;
    }

    if (extension == 'jpg' || extension == 'jpeg' || extension == 'raw') {
      this.icon = 'file-image-outline';
      return;
    }

    if (extension == 'png') {
      this.icon = 'file-image';
      return;
    }

    if (extension == 'svg') {
      this.icon = 'svg';
      return;
    }

    if (extension == 'jar') {
      this.icon = 'language-java';
      return;
    }

    if (extension == 'mp3') {
      this.icon = 'file-music-outline';
      return;
    }

    if (extension == 'mp4') {
      this.icon = 'file-video-outline';
      return;
    }

    if (extension == 'pdf') {
      this.icon = 'file-pdf-outline';
      return;
    }
  }
}
