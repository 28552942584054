<section class="main-container ai-title-wrapper"
         [ngClass]="{'ai-color': ai, 'user-color': !ai}"
>
  <!--    <p class="text-box" [innerHTML]="message"></p>-->

  <markdown
    class="markdown-style"
    [data]="message"
    lineNumbers
  >

  </markdown>

</section>
