<section>
  <div class="responsive-table-wrapper">
    <table mat-table matSort
           [class.from-code-lookup]="fromCodeLookup"
           [ngClass]="{
              'list-with-select': multiSelect
           }"
           [dataSource]="items"
           [matSortActive]="pageParams.sort.value.split(',')[0]"
           [matSortDirection]="pageParams.sort.value.split(',')[1]"
           (matSortChange)="sortData($event)">


      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="selectAllToggle($event)"
                        style="margin-top: 8px;"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" style="padding-top: 8px;">
          <mat-checkbox (click)="$event.stopPropagation();"
                        (change)="$event ? selectedChange(row) : null"
                        [checked]="isSelectedRow(row)">
          </mat-checkbox>

        </td>
      </ng-container>

      <!--      <ng-container matColumnDef="select">-->
      <!--        <th mat-header-cell *matHeaderCellDef style="padding-top: 8px;"></th>-->
      <!--        <td mat-cell *matCellDef="let row" style="padding-top: 8px;">-->
      <!--          <mat-checkbox (cl                       ick)="$event.stopPropagation();"-->
      <!--                        (change)="$event ? selectedChange(row) : null"-->
      <!--                        [checked]="isSelectedRow(row)">-->
      <!--          </mat-checkbox>-->
      <!--        </td>-->
      <!--      </ng-container>-->

      <ng-container matColumnDef="openScript">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element; let i = index" [matTooltip]="'Open script'"
            style="padding-right: 0px !important;max-width: 38px;width: 38px;">
          <!--          <a mat-button color="primary"-->
          <!--            (click)="clickedScriptItem(element, i); $event.stopPropagation()">-->
          <div class="open-script">
            <mx-icon mx="workspace" [color]="'#737373'" [hoverColor]="'#1e88e5'" [marginBottom]="false"
                     style="max-width: 22px; max-height: 22px;height: 22px;width: 22px"
                     (click)="clickedScriptItem(element, i); $event.stopPropagation()"></mx-icon>

          </div>
          <!--          </a>-->

        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let element" [matTooltip]="getNameForTooltip(element.name)">
          <!--          {{ getNameForPrint(element.name) }}-->
          <div [innerHTML]="getNameForPrint(element.name)"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Category</th>
        <td mat-cell *matCellDef="let element">
          {{element.pageObject == null || element.pageObject == true ? 'Page Object' : 'Case'}}
        </td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="caseType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
        <td mat-cell *matCellDef="let element"> {{element.caseType}}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description</th>
        <td mat-cell *matCellDef="let element"
            [matTooltip]="getDescriptionForTooltip(element.description)">
          <div [innerHTML]="getDescriptionForPrint(element.description)"></div>
          <!--          {{getDescriptionForPrint(element.description)}}-->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          (click)="clickedItem(row, i);"
          [ngClass]="{ 'highlight': selection.isSelected(row)}"></tr>

    </table>
  </div>

  <spinner-for-table [loading]="isLoading"></spinner-for-table>

  <div class="container mr-0">
    <div class="row justify-content-end"
         style="flex-direction: row;justify-content: space-between;align-items: center;">
      <div class="col-xs-4" *ngIf="selection.selected.length > 0">
        <p style="font-size:13px; color:grey; margin-top:auto; margin-bottom:auto">
          Selected: {{selection.selected.length}} </p>
      </div>
      <div class="col-xs-8">
        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [length]="pagesInfo.totalElements"
          [pageIndex]="pageParams.page.value"
          [pageSize]="pageParams.size.value"
          (page)="setPageSizeOrIndex($event)"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>

  </div>
</section>
