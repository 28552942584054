<h1 mat-dialog-title *ngIf="!spinner">Create a {{type}}</h1>
<h1 mat-dialog-title *ngIf="spinner">Creating your project..</h1>

<div mat-dialog-content style="padding: 32px" *ngIf="spinner">
  <mat-spinner style="margin:auto;"></mat-spinner>
</div>

<div mat-dialog-content *ngIf="!spinner">
  <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>

  <label>{{type}} name</label>
  <div class="mb-3">
    <input type="text" [disabled]="spinner" [(ngModel)]="project.name">
  </div>
  <mat-expansion-panel [expanded]="false" [disabled]="spinner">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>Advanced settings</span>
      </mat-panel-title>
      <mat-panel-description style="color: black;">


      </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-checkbox [(ngModel)]="automaticallyGenerateId">Automatically generate id.</mat-checkbox>
    <br>
    <label *ngIf="!automaticallyGenerateId">{{type}} id</label>
    <div *ngIf="!automaticallyGenerateId">
      <input type="text" [disabled]="spinner" [(ngModel)]="project.id">
    </div>
  </mat-expansion-panel>
  <br>
</div>

<div mat-dialog-actions *ngIf="!spinner">
  <button mat-button *ngIf="!spinner" [mat-dialog-close]="null" id="cancelCreateProjectButton">Cancel</button>

  <button mat-button *ngIf="!spinner" (click)="save()" id="createProjectConfirmButton" color="primary">Create</button>

  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>

</div>
