<h1 mat-dialog-title class="text-center" *ngIf="title != ''">{{title}}</h1>
<div mat-dialog-content *ngIf="body != ''">
  <p>{{body}}</p>
</div>
<div mat-dialog-actions>

  <button *ngIf="!disableThirdButton" id="cancelButton" mat-button [mat-dialog-close]="undefined">
    {{(label3 != '' ? label3 : 'CANCEL')}}
  </button>

  <button id="noButton" mat-button [mat-dialog-close]="false" *ngIf="!disableSecondButton" color="warn">
    {{(label2 != '' ? label2 : 'NO')}}
  </button>

  <button id="yesButton" mat-button [mat-dialog-close]="true" color="primary">
    {{(label1 != '' ? label1 : 'YES')}}
  </button>

</div>
