<section class="main-container" style="height: 100%">

  <div class="stop-responding" *ngIf="isRunActive">
    <button mat-raised-button
            color="primary"
            [disabled]="disableInput"
            (mousedown)="stopEvent()"
            aria-label="Stop Responding">
      <mat-icon>
        stop
      </mat-icon>
      Stop Responding
    </button>
  </div>

  <div class="input-box" style="height: 100%"
       tabindex="2"
       *ngIf="!isRunActive"
       [matTooltip]="disableInput ? 'Maximum number of requests per project on a daily basis has been reached using the Maxtaf key. Please configure your key in the project parameters.': ''">

    <mat-form-field class="fill-container" style="height: 100%" >
      <mat-label>Send a message</mat-label>
      <textarea
        #textareaElement
        matInput
        placeholder="Send a message"
        (focus)="focusOnInput()"
        (blur)="focusOutInput()"
        [disabled]="disableInput"
        [(ngModel)]="this.value"
        [rows]="inputActive?10:2"
        (keydown.enter)="$event.preventDefault();askQuestionEvent()"
      ></textarea>
    </mat-form-field>
    <button mat-mini-fab
            [disabled]="disableInput"
            (mousedown)="askQuestionEvent();"
            aria-label="Send message" style="background: none;
    box-shadow: none;
    color: #1e88e5;">
      <mat-icon style="vertical-align: initial;">
        send
      </mat-icon>
    </button>
  </div>
</section>
