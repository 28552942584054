<h1 mat-dialog-title>Import {{type}}</h1>


<div mat-dialog-content>
  <div *ngIf="!importSpinner">
    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <h5 class="alert-heading"> {{(
            (importInfoDetails.workspaceError != null && importInfoDetails.workspaceError != "") ||
            (importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != "") ||
            (importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != "") ||
            (importInfoDetails.suitesError != null && importInfoDetails.suitesError != "") ||
            (importInfoDetails.casesError != null && importInfoDetails.casesError != "")
          ) ? 'Partial ' : ''}} Success</h5>
          <hr>
          <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
          <button (click)="showSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>

    <app-error-message *ngIf="showUploadError" [error]="error" [showInDialog]="true"></app-error-message>

    <mat-progress-bar *ngIf="currentFileUpload" mode="determinate" [value]="progress.percentage"
                      class="mt-1"></mat-progress-bar>

    <br *ngIf="currentFileUpload"/>

    <div class="container" style=" max-width: 100%;">
      <div class="row justify-content-md-center">
        <div class="col">
          <div class="input-group mb-3">
            <div class="custom-file">
              <input (change)="selectFile($event)" type="file" class="custom-file-input" id="inputGroupFile01">
              <label class="custom-file-label" for="inputGroupFile01">{{fileName}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br *ngIf="!hideSourceRadioGroup">

    <mat-radio-group *ngIf="!hideSourceRadioGroup" [disabled]="spinner">
      <mat-radio-button class="mr-3" value="JSON" [checked]="importType === 'json'" (change)="importType = 'json'">JSON
      </mat-radio-button>

      <br>

      <mat-radio-button value="XML" [checked]="importType === 'xml'" (change)="importType = 'xml'">XML
      </mat-radio-button>

      <br>

      <mat-radio-button value="JUNIT" [checked]="importType === 'junit'" (change)="importType = 'junit'">Selenium IDE /
        JUnit
      </mat-radio-button>

      <br>

      <mat-radio-button value="PYTHON" [checked]="importType === 'python'" (change)="importType = 'python'">Selenium IDE
        /
        PyTest
      </mat-radio-button>

      <br>

      <mat-radio-button value="JAVASCRIPT" [checked]="importType === 'javascript'" (change)="importType = 'javascript'">
        Selenium IDE /
        Mocha
      </mat-radio-button>

      <br>

      <mat-radio-button value="JAVASCRIPT" [checked]="importType === 'csharp'" (change)="importType = 'csharp'">
        Selenium IDE /
        NUnit
      </mat-radio-button>
    </mat-radio-group>

    <br *ngIf="!hideSourceRadioGroup">
    <br>

    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwrite1" (change)="overwrite1 = !overwrite1"
                  id="overwriteCheck">{{overwriteButton1Message}}
    </mat-checkbox>

    <br *ngIf="this.type.toLowerCase() != 'project params'"/>
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="compile"
                  *ngIf="this.type.toLowerCase() != 'project params'" (change)="compile = !compile"
                  id="compileCheck">{{compileButton1Message}}
    </mat-checkbox>
    <br *ngIf="!hideOverwriteButton2"/>
    <mat-checkbox *ngIf="!hideOverwriteButton2" [disabled]="spinner" type="checkbox" [checked]="overwrite2"
                  (change)="overwrite2 = !overwrite2"
                  id="overwrite2Check">{{overwriteButton2Message}}
    </mat-checkbox>
    <br *ngIf="!hideOverwriteButton3"/>
    <mat-checkbox *ngIf="!hideOverwriteButton3" [disabled]="spinner" type="checkbox" [checked]="overwrite3"
                  (change)="overwrite3 = !overwrite3"
                  id="overwrite3Check">{{overwriteButton3Message}}
    </mat-checkbox>
  </div>

  <div *ngIf="importSpinner && this.importInfoDetails == undefined">
    <mat-spinner style="align-self:flex-end; margin: 0 auto;" [diameter]="50" color="primary"></mat-spinner>
  </div>
  <div *ngIf="importSpinner && this.importInfoDetails != undefined">

    <div class="row justify-content-md-center" style=" max-width: 100%;">
      <div *ngIf="showSuccess" class="row">
        <div *ngIf="importStatusField">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            <h5 class="alert-heading">Success</h5>
            <hr>
            <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
            <button (click)="showSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div *ngIf="!importStatusField">
          <div class="alert alert-danger alert-dismissible fade show" role="alert">
            <h5 class="alert-heading">Error</h5>
            <hr>
            <p *ngIf="successMessage" class="mb-0">{{ successMessage }} </p>
            <button (click)="showSuccess=false" type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="this.type.toLowerCase() == 'project params'">
      Project Params: {{importInfoDetails.currentProjectParamsNumber}}/{{importInfoDetails.maxProjectParamsNumber}}
    </div>
    <div *ngIf="this.type.toLowerCase() != 'project params'">
      Cases:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentCasesNumber}}
      /{{importInfoDetails.maxCasesNumber}}
    </div>
    <div *ngIf="this.type === 'schedule' || this.type === 'suite'">
      Suites:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{importInfoDetails.currentSuitesNumber}}
      /{{importInfoDetails.maxSuitesNumber}}
    </div>
    <div
      *ngIf="(this.type === 'schedule' || this.type === 'suite') && importInfoDetails.currentSuitesNumber == importInfoDetails.maxSuitesNumber && importInfoDetails.connectingCasesWithSuitesStart && !importInfoDetails.connectingCasesWithSuitesEnd">
      {{counterDot == 0 ? 'Connecting cases with suites.' : counterDot == 1 ? 'Connecting cases with suites..' : 'Connecting cases with suites...'}}
    </div>
    <div *ngIf="this.type === 'schedule'">
      Schedules:&nbsp;{{importInfoDetails.currentScheduleNumber}}/{{importInfoDetails.maxScheduleNumber}}
    </div>


    <br *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''">

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsSkipped != null && importInfoDetails.projectParamsSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped project params
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsErrors != null && importInfoDetails.projectParamsErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Project params errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.projectParamsError != null && importInfoDetails.projectParamsError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.projectParamsError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesSkipped != null && importInfoDetails.casesSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped cases
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesErrors != null && importInfoDetails.casesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cases errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.casesError != null && importInfoDetails.casesError != ''"
                         [expanded]="importInfoDetails.casesError != null && importInfoDetails.casesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.casesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>


    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesSkipped != null && importInfoDetails.suitesSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped suites
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesErrors != null && importInfoDetails.suitesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''"
                         [expanded]="importInfoDetails.suitesError != null && importInfoDetails.suitesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.suitesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.caseSuitesErrors != null && importInfoDetails.caseSuitesErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suite items errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.caseSuitesErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.caseSuitesError != null && importInfoDetails.caseSuitesError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Suites items error
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.caseSuitesError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleSkipped != null && importInfoDetails.scheduleSkipped != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Skipped schedules
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Skipped" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleSkipped"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleErrors != null && importInfoDetails.scheduleErrors != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Schedules errors
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Errors" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleErrors"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel style="border-radius: 0;"
                         *ngIf="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''"
                         [expanded]="importInfoDetails.scheduleError != null && importInfoDetails.scheduleError != ''">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Error log
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-form-field class="w-100">
          <textarea matInput placeholder="Error" matAutosizeMaxRows="5"
                    matAutosizeMinRows="2"
                    [contentEditable]="false"
                    [matTextareaAutosize]="true" [(ngModel)]="importInfoDetails.scheduleError"
                    (ngModelChange)="paramsChangeValue()"></textarea>
      </mat-form-field>
    </mat-expansion-panel>

    <br>
  </div>
</div>

<div mat-dialog-actions>
  <button id="okButton" mat-button mat-raised-button color="primary" *ngIf="!spinner && finished"
          (click)="closeDialog()">Ok
  </button>
  <button id="closeButton" mat-button *ngIf="!spinner && !finished" (click)="closeDialog()">Close</button>
  <button id="uploadButton" [disabled]="!selectedFiles" mat-raised-button [ngClass]="{'green_button': selectedFiles}"
          *ngIf="!spinner && !finished" (click)="upload()">Upload
  </button>

  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
