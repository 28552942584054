<h1 mat-dialog-title>Upload to <b>{{ data.uploadPath }}</b></h1>
<div mat-dialog-content>

  <overlay-scrollbars
    #navTreeScrolling
    [options]="{ scrollbars: { autoHide: 'l' } }"
    [ngStyle]="{'max-height': '470px'}"
  >

    <div class="dialog-content">

      <div class="container" appDnd (fileDropped)="onFileDropped($event)">
        <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)"/>

        <mx-icon color="primary" class="mx-icon-upload" mx="upload"></mx-icon>

        <h3>Drag and drop file here</h3>
        <h3>or</h3>
        <label for="fileDropRef">Browse for file</label>
      </div>


      <div class="files-list">
        <div class="single-file-container" *ngFor="let file of files; let i = index">

          <div class="single-file">
            <app-nav-tree-file-icon class="file-icon" [color]="file?.status == 'fail'?'warn':'primary'"
                                    [extension]="file?.fileType"></app-nav-tree-file-icon>
            <div class="info">
              <h4 class="name">
                {{ file?.name }}
              </h4>
              <p class="size">
                {{ formatBytes(file?.size, 2) }}
              </p>
              <mat-progress-bar
                class="example-margin"
                [color]="file?.status == 'fail'?'warn':'primary'"
                [mode]="'determinate'"
                [value]="file?.progress">
              </mat-progress-bar>
            </div>
            <div style="flex-direction: column;">
              <!--              <mat-checkbox-->
              <!--                *ngIf="file.fileType == 'java' || file.fileType == 'py'"-->
              <!--                color="primary"-->
              <!--                [disabled]="file.status != 'wait'"-->
              <!--                [checked]="file?.uploadAsCase"-->
              <!--                (change)="file.uploadAsCase = true"-->
              <!--                style="margin-left: 0.7rem;"-->
              <!--                matTooltip="Upload As Test Case."-->
              <!--              ></mat-checkbox>-->
              <div style="width: 20px; height: 20px;"></div>

              <mx-icon color="accent" hoverColor="warn" (click)="deleteFile(i)" class="delete" mx="delete"></mx-icon>
            </div>
          </div>

          <app-error-message style="width: 100%;" [cleanDesign]="true" *ngIf="file.error != undefined"
                             [error]="file.error"
                             [showInDialog]="true"></app-error-message>

        </div>
      </div>
      <!--  </div>-->
      <div class="mb-2 w-100">
        <mat-checkbox type="checkbox" [checked]="overwrite" (change)="overwrite = !overwrite"
                      id="overwriteCheck">Overwrite data with import file?
        </mat-checkbox>
      </div>

    </div>
  </overlay-scrollbars>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]>Close</button>

  <button mat-button *ngIf="!spinner" color="primary" (click)="uploadFiles()">
    <span>Upload</span>
  </button>

  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
