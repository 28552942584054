import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {GlobalEventsManagerService} from '../services/global-events-manager.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {first} from 'rxjs/operators';
import {User} from '../../layout/users/shared/models/user';
import {UserService} from '../../layout/users/shared/services/user.service';
import {MaxtafTokensStorageService} from '../services/maxtaf-tokens-storage.service';

@Injectable()
export class CasesGuard implements CanActivate {

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private globalEventsManager: GlobalEventsManagerService,
    private userService: UserService,
    private tokensService: MaxtafTokensStorageService
  ) {
  }

  canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      this.auth.authState.pipe(first()).subscribe(
        user => {

          if (user) {
            this.globalEventsManager.showNavBar(true);
            this.tokensService.setRefreshToken(user.refreshToken);
            resolve(true);
          } else {
            this.auth.signInAnonymously().then(
              anonymous => {
                this.globalEventsManager.showNavBar(true);
                this.auth.idToken.pipe(first()).subscribe(
                  idToken => {

                    this.tokensService.setUserId(anonymous.user.uid);
                    this.tokensService.setRefreshToken(anonymous.user.refreshToken);
                    this.tokensService.setFireToken(idToken);
                    this.userService.getCurrentUserProject().user = new User(anonymous.user.uid, anonymous.user.email, anonymous.user.displayName);
                    resolve(true);
                  },
                  error => {
                    this.router.navigate(['/logout']).then();
                    resolve(false);
                    throw new Error(error);
                  }
                );
              }).catch(
              error => {
                this.router.navigate(['/logout']).then();
                resolve(false);
                throw new Error(error);
              }
            );
          }
        },
        error => {
          console.error(error);
        }
      );
    });
  }

}
