import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MxDiffCodeOptionsComponent } from './mx-diff-code-options.component';
import {ImportCaseInfoOptionComponent} from "../import-case-info-option/import-case-info-option.component";
import {ImportCaseInfoFilterModule} from "../import-case-info-filter/import-case-info-filter.module";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MxOptionsModule} from "../../../mx/options/mx-options/mx-options.module";



@NgModule({
  declarations: [MxDiffCodeOptionsComponent],
  exports: [
    MxDiffCodeOptionsComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatButtonModule,
    MatTooltipModule,
    MxOptionsModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class MxDiffCodeOptionsModule { }
