<div mat-dialog-content>
  <label>Ask Anything...</label>
  <!--  <div *ngIf="type == 'input'">-->
  <!--    <input type="text" [(ngModel)]="value">-->
  <!--  </div>-->
  <div>
    <mat-form-field class="w-100">
      <overlay-scrollbars
        style="max-height: 200px !important;"
        [options]="{ scrollbars: { autoHide: 'n' } }"
      >
        <textarea matInput placeholder="" class="overflow-hidden" [matTextareaAutosize]="true"
                  [(ngModel)]="value"></textarea>
      </overlay-scrollbars>
    </mat-form-field>
  </div>
  <!--  <div *ngIf="type == 'number'">-->
  <!--    <input OnlyNumber="true" matInput type="number" maxlength="6" placeholder=""-->
  <!--           [(ngModel)]="value">-->
  <!--  </div>-->
</div>

<div mat-dialog-actions>
  <!--  <button mat-button *ngIf="name=='Params:'" color="primary">-->
  <!--    <a target="_blank" href="https://www.maxtaf.com/guides/mtc-guide/?version=Cloud#caseParams">-->
  <!--      <mx-icon mx="information"></mx-icon>-->
  <!--    </a>-->
  <!--  </button>-->

  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button id="okButton" [mat-dialog-close]="value" color="primary">Ask</button>
</div>

