import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import * as moment from 'moment';
import {ImportInfoDetails} from '../../../dialogs/import/ImportInfoDetails';
import {FileService} from '../../../../shared/services/file.service';

@Component({
  selector: 'app-import-case-info-details-dialog',
  templateUrl: './import-case-info-details-dialog.component.html',
  styleUrls: ['./import-case-info-details-dialog.component.css']
})
export class ImportCaseInfoDetailsDialogComponent implements OnInit {

  importInfoDetails: ImportInfoDetails = new ImportInfoDetails();

  constructor(dialogRef: MatDialogRef<ImportCaseInfoDetailsDialogComponent>,
              private fileService: FileService,
              @Inject(MAT_DIALOG_DATA) data: {
                importInfoDetails: ImportInfoDetails
              }) {
    this.importInfoDetails = data.importInfoDetails;
    this.fileService.getImportInfoCase(this.importInfoDetails.casesId).subscribe(
      res => {
        this.importInfoResponse(res);
      },
      error => {
        console.error(error);
      }
    );
  }

  private importInfoResponse(res: ImportInfoDetails) {
    this.importInfoDetails = res;
  }

  ngOnInit(): void {
  }

  setFormat(runDate: Date) {
    if (runDate == null) {
      return '/';
    }
    const startFormat = moment.localeData(navigator.language).longDateFormat('lll');
    const format = startFormat.substr(0, startFormat.substr(0, startFormat.indexOf(':')).lastIndexOf(' ')) + ', h:mm:ss a';
    return moment(runDate).format(format);
  }

  paramsChangeValue() {

  }
}
