import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiInputComponent} from './ai-input.component';
import {IconModule} from '../../../mx/icon/icon.module';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [AiInputComponent],
  exports: [
    AiInputComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ]
})
export class AiInputModule {
}
