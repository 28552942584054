export class AIChatConfig {
  aiType: string = 'openai';
  openAiKey: string;
  openAiModel: string;
  temperature: string; // 0.0 - 1.0
  constructor(openAiKey: string = '', openAiModel: string = '', temperature: string = "0.5") {
    this.openAiKey = openAiKey;
    this.openAiModel = openAiModel;
    this.temperature = temperature;
  }
}
