import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AiOptionsDialogComponent} from './ai-options-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IconModule} from "../../../mx/icon/icon.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";


@NgModule({
  declarations: [AiOptionsDialogComponent],
    imports: [
        CommonModule,
        FormsModule,
        IconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        ReactiveFormsModule,
        MatTooltipModule,
        MatButtonToggleModule,
        MatOptionModule,
        MatSelectModule
    ], exports: [AiOptionsDialogComponent]
})
export class AiOptionsDialogModule {
}
