<section class="main-container">

  <div class="row">
    <div class="col-md">
      <mat-form-field class="form-field-full-width">
        <mat-select placeholder="Filter done as exact match, or not"
                    [(value)]="search.exactSearch">
          <mat-option value="Contains">Contains</mat-option>
          <mat-option value="Exact">Exact</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-md">
      <button (click)="filterRecords()" mat-raised-button color="primary" class="mr-1 mb-1">
        <mx-icon marginBottom="true" mx="filter"></mx-icon>
        <span class="pl-1">Filter</span>
      </button>
      <button (click)="clearCompileFilter()" mat-raised-button color="primary" class="mr-1 mb-1">
        <mx-icon marginBottom="true" mx="clear"></mx-icon>
        <span class="pl-1">Clear</span>
      </button>
      <button (click)="clickedClose()" mat-raised-button class="mb-1"
              color="primary">
        <mx-icon marginBottom="true" mx="close"></mx-icon>
        <span class="pl-1">Close</span>
      </button>
    </div>

  </div>

  <div class="row">

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('caseName')" type="text"
               placeholder="Case name.."
               [(ngModel)]="search.caseName">
      </mat-form-field>
    </div>

    <div class="col-md">
      <mat-form-field class="w-100">
        <input matInput [disabled]="lockSearchParams.includes('errorMessage')" type="text"
               placeholder="Compile error.."
               [(ngModel)]="search.errorMessage">
      </mat-form-field>
    </div>

  </div>
</section>
