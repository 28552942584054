<app-error-message *ngIf="showSaveError" [error]="error"></app-error-message>

<h1 mat-dialog-title class="text-center">Already has a {{type}} named "{{(type == 'CASE') ? case.name :
  suite.name}}"</h1>

<div mat-dialog-actions>
  <button mat-button (click)="openEditNameDialog()" class="w-100 ml-0"> Change name</button>
  <button mat-button (click)="replace()" class="w-100 ml-0"> Replace {{type == 'CASE' ? 'cases' : 'suites'}}</button>
  <button mat-button [mat-dialog-close]="false" class="w-100 ml-0"> Skip</button>
</div>
