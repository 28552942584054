import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateApiKeyComponent } from './create-api-key.component';
import {ErrorModule} from "../../../mx/error/error.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {IconModule} from "../../../mx/icon/icon.module";



@NgModule({
  declarations: [CreateApiKeyComponent],
  imports: [
    CommonModule,
    ErrorModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    IconModule
  ],
  exports: [
    CreateApiKeyComponent
  ]
})
export class CreateApiKeyModule { }
