import {Injectable} from '@angular/core';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {PageParams} from '../../../mx/util/params/PageParams';

@Injectable({
  providedIn: 'root'
})
export class UserConstService {

  constructor() {

  }

  public static getUserPageParams(): UserPageParams {
    return {};
  }

  public static getDefaultProjectPageParams(configurationParam: boolean): AdminPageParams {
    return {
      tabIndex: new SearchParam(0, 0),

      mainTab: this.initMainTabParams(),
      userTab: this.initProjectPageUserTabParams(),
      userRequestsTab: this.initProjectPageUserRequestsTabParams(),
      projectParamsTab: this.initProjectPageProjectParamsTabParams(configurationParam),
    };
  }

  public static getDefaultUserSearchParams(): UserSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      email: new SearchParam(null, null),
      name: new SearchParam(null, null),
    };
  }

  public static getDefaultUserPreferenceSearchParams(name): UserPreferencesParam {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(name, null),
    };
  }

  public static getDefaultUserPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('updatedDate,desc', 'updatedDate,desc')
    };
  }

  public static getDefaultUserRequestsSearchParams(): UserSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      email: new SearchParam(null, null),
      name: new SearchParam(null, null),
    };
  }

  public static getDefaultUserRequestsPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('email,desc', 'email,desc')
    };
  }

  public static getDefaultProjectParamsSearchParams(configurationParam: boolean): ProjectParamsSearchParams {
    return {
      exactSearch: new SearchParam('Contains', 'Contains'),
      name: new SearchParam(null, null),
      value: new SearchParam(null, null),
      description: new SearchParam(null, null),
      autoIncrement: new SearchParam(null, null),
      removable: new SearchParam(null, null),
      // configurationParam: new SearchParam(configurationParam, configurationParam),
    };
  }

  public static getDefaultProjectParamsPageParams(size = 10): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(size, size),
      sort: new SearchParam('name,asc', 'name,asc')
    };
  }

  public static initProjectPageProjectParamsTabParams(configurationParam: boolean): ProjectPageProjectParamsTabParams {
    return {
      page: this.getDefaultProjectParamsPageParams(),
      search: this.getDefaultProjectParamsSearchParams(configurationParam),
    };
  }

  public static initProjectPageUserTabParams(): ProjectPageUserTabParams {
    return {
      page: this.getDefaultUserPageParams(),
      search: this.getDefaultUserSearchParams(),
    };
  }

  public static initProjectPageUserRequestsTabParams(): ProjectPageUserRequestsTabParams {
    return {
      page: this.getDefaultUserRequestsPageParams(),
      search: this.getDefaultUserRequestsSearchParams(),
    };
  }

  public static initMainTabParams(): ProjectPageMainTabParams {
    return {};
  }


}


export class UserPageParams {

}

export class ProjectPageProjectParamsTabParams {
  page: PageParams;
  search: ProjectParamsSearchParams;
}

export class ProjectPageUserTabParams {
  page: PageParams;
  search: UserSearchParams;
}

export class ProjectPageUserRequestsTabParams {
  page: PageParams;
  search: UserSearchParams;
}

export class ProjectPageMainTabParams {

}

export class UserSearchParams {
  exactSearch: SearchParam;
  email: SearchParam;
  name: SearchParam;
}

export class ProjectParamsSearchParams {
  exactSearch: SearchParam;
  name: SearchParam;
  value: SearchParam;
  description: SearchParam;
  autoIncrement: SearchParam;
  removable: SearchParam;
  // configurationParam: SearchParam;
}

export class AdminPageParams {
  tabIndex: SearchParam;
  mainTab: ProjectPageMainTabParams;
  userTab: ProjectPageUserTabParams;
  userRequestsTab: ProjectPageUserRequestsTabParams;
  projectParamsTab: ProjectPageProjectParamsTabParams;
}

export class UserPreferencesParam {
  exactSearch: SearchParam;
  name: SearchParam;
}
