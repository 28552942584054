import {Injectable} from '@angular/core';
import {ConfigService} from "../../../../shared/services/config.service";

@Injectable({
  providedIn: 'root'
})
export class AiConfigService {

  constructor(
    private configService: ConfigService
  ) {
  }

  private DEFAULT_PATH_VAR = this.configService.getDefaultPathVar();

  private API_PATH = this.configService.getHostURL() + this.configService.getPrefix() + '/ai';


  private AI_CHAT_URL = this.API_PATH + "/" + "chat";
  private ASK_PATH = "ask";
  private EXPORT_AI_CHAT_URL = this.AI_CHAT_URL + "/" + "export";

  public aiController_GET_getAIChat() {
    return this.AI_CHAT_URL;
  }


  public aiController_POST_addQuestion() {
    return this.AI_CHAT_URL + "/question";
  }

  public aiController_PUT_updateQuestion(aiChatID: string) {
    return this.AI_CHAT_URL + "/" + aiChatID;
  }

  public aiController_GET_askQuestion() {
    return this.AI_CHAT_URL + "/" + this.ASK_PATH;
  }

  public aiController_DELETE_deleteAiChat() {
    return this.AI_CHAT_URL;
  }

  public aiController_GET_exportAiChat() {
    return this.AI_CHAT_URL + "/export";
  }
  public aiController_GET_config() {
    return this.API_PATH + "/config";
  }


  export() {
    return this.EXPORT_AI_CHAT_URL;
  }
}
