<div mat-dialog-content class="pt-2">

  <!--  <mat-tab-group-->
  <!--    [selectedIndex]="params.tabIndex.value"-->
  <!--    (selectedIndexChange)="changeTabIndex($event)">-->
  <!--    <mat-tab [label]="showPageObjects? 'Page Objects': 'Cases'">-->


  <app-case-list-options
    [forDialog]="true"
    [showCase]="showCreateCases"
    [showPageObject]="showCreatePageObjects"
    [checkPaths]="getCaseListOptionsCheckPathObject()"
    [showCheckboxes]="true"
    [searchParams]="params.caseSearch"
    (searchParamsChange)="caseSearchParamsChange($event)"
    (refresh)="caseList.refreshItems()"
    (filter)="caseList.refreshItems()"
    (create)="openCreateDialog()"
    (createPageObjectEvent)="openCreatePageObjectEventDialog()"
  >

  </app-case-list-options>

  <app-list-case #caseList
                 [autoSetItems]="true"
                 [multiSelect]="multiSelect"

                 [searchParams]="params.caseSearch"
                 [displayedColumns]="['select', 'openScript', 'name', 'category', 'caseType', 'description']"
                 [pageParams]="params.casePage"
                 (pageParamsChange)="casePageParamsChange($event)"


                 (pagesInfoChanges)="pagesInfoChanges($event)"
                 (clickedItem)="clickedItem($event)"
                 (clickedScriptItem)="clickedScriptItem($event)"
                 (selected)="caseSelectedItems($event)"
  ></app-list-case>
  <!--    </mat-tab>-->
  <!--    <mat-tab label="Suites" *ngIf="!removeSuites">-->

  <!--      <app-suite-options-->
  <!--        [checkPaths]="getSuiteOptionsCheckPathObject()"-->
  <!--        [forDialog]="true"-->
  <!--        [searchParams]="params.suiteSearch"-->
  <!--        (searchParamsChange)="suiteSearchParamsChange($event)"-->

  <!--        (refresh)="suiteList.refreshItems()"-->
  <!--        (filter)="suiteList.refreshItems()">-->
  <!--      </app-suite-options>-->

  <!--      &lt;!&ndash;table&ndash;&gt;-->
  <!--      <app-list-suite-->
  <!--        [autoSetItems]="true"-->
  <!--        [multiSelect]="multiSelect"-->

  <!--        [searchParams]="params.suiteSearch"-->
  <!--        [pageParams]="params.suitePage"-->
  <!--        (pageParamsChange)="suitePageParamsChange($event)"-->
  <!--        (pagesInfoChanges)="suitePagesInfoChanges($event)"-->

  <!--        (clickedItem)="clickedItem($event)"-->
  <!--        (selected)="suiteSelectedItems($event)"-->
  <!--        #suiteList-->
  <!--      ></app-list-suite>-->
  <!--    </mat-tab>-->
  <!--  </mat-tab-group>-->

</div>
<div mat-dialog-actions class="d-flex float-right px-4 py-2">
  <button id="cancelButton" mat-button [mat-dialog-close]="false" style="float:right">Cancel</button>
  <button id="addButton" mat-button (click)="returnSelectedIds()" color="primary" style="float:right;font-weight:bold;">
    Add
  </button>
</div>
