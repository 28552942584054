<h4 mat-dialog-title
    style="font-size: 18px;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;">
  Compile logs
</h4>

<div mat-dialog-content>
  <app-compile-info-options
    [checkPaths]="getCompileInfoListOptionsCheckPathObject()"
    [(searchParams)]="params.search"

    (filter)="filter($event)"
    (refresh)="refreshCompileInfo();"
    (delete)="deleteCompileInfo();">

  </app-compile-info-options>

  <app-compile-info-list #list
                         class="mx-compile-list"
                         [autoSetItems]="true"

                         [searchParams]="params.search"
                         [pageParams]="params.page"
                         (pageParamsChange)="pageParamsChange($event)"
                         (pagesInfoChanges)="setPageInfoChanges($event)"

                         (clickedItem)="clickedCompileInfo($event)"
                         (selected)="selected($event)">

  </app-compile-info-list>
</div>
<div mat-dialog-actions class="d-flex float-right px-4 py-2 mb-0 mt-0" style="margin-bottom: 0px !important;">
  <button id="cancelButton" mat-button [mat-dialog-close]="false" style="float:right">Close</button>
</div>
