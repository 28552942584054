<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="defaultColors"
  #mxOptions
>

  <mx-options-header>

    <app-filter-by
      class="filter-by mr-1 ml-2"
      *ngIf="showButtons"
      [searchParams]="searchParams"
      [disabled]="!checkPaths.filter.enabled"
      [ignoreParams]="ignoreParams"
      (searchParamsChange)="newParams($event)"
      (filter)="filter()"
      [isSearchCollapsed]="mxOptions.isSearchCollapsed"
      (isSearchCollapsedChange)="mxOptions.toggleBody();"
    ></app-filter-by>

    <button mat-button
            *ngIf="showCheckboxes && showButtons && this.searchParams != undefined && this.searchParams.showPageObjects != undefined && showPageObject"
            (click)="clickedPageObject()"
            [matTooltip]="!showTooltip? undefined : 'Page Objects'"
    >
      <mx-icon [marginBottom]="iconMarginBottom"
               [mx]="this.searchParams.showPageObjects.value ? 'checkbox-marked' : 'checkbox-blank'"></mx-icon>
      <span class="px-1">Page Objects</span>
    </button>

    <button mat-button
            *ngIf="showCheckboxes && showButtons && this.searchParams != undefined && this.searchParams.showCases != undefined"
            [matTooltip]="!showTooltip? undefined : 'Cases'"
            (click)="clickedShowCases()"
    >
      <mx-icon [marginBottom]="iconMarginBottom"
               [mx]="this.searchParams.showCases.value ? 'checkbox-marked' : 'checkbox-blank'"></mx-icon>
      <span class="px-1">Cases</span>
    </button>

    <button mat-button
            *ngIf="showCheckboxes && showButtons && this.searchParams != undefined && this.searchParams.showPageObjects != undefined && !showPageObject"
            (click)="clickedPageObject()"
            [matTooltip]="!showTooltip? undefined : 'Page Objects'"
    >
      <mx-icon [marginBottom]="iconMarginBottom"
               [mx]="this.searchParams.showPageObjects.value ? 'checkbox-marked' : 'checkbox-blank'"></mx-icon>
      <span class="px-1">Page Objects</span>
    </button>


    <button mat-button
            *ngIf="!isTrash && showButtons && searchParams.showCases.value"
            [disabled]="!checkPaths.create.enabled"
            (click)="create()"
            [matTooltip]="!showTooltip? undefined : 'Create'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="create" color="green"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Create</span>
    </button>

    <!--    <button mat-button-->
    <!--            *ngIf="!isTrash && showButtons && searchParams.showPageObjects.value "-->
    <!--            [disabled]="!checkPaths.createPageObject.enabled"-->
    <!--            (click)="createPageObject()"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Create'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="create" color="green"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Create</span>-->
    <!--    </button>-->

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && !isTrash && showButtons"-->
    <!--            (click)="compile()"-->
    <!--            [disabled]="!checkPaths.compile.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Compile'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="compile"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Compile</span>-->
    <!--    </button>-->

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && !isTrash && showButtons"-->
    <!--            (click)="compileInfo()"-->
    <!--            [disabled]="!checkPaths.compile.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Compile logs'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="compile-info"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Compile logs</span>-->
    <!--    </button>-->

    <button mat-button
            *ngIf="showButtons"
            (click)="refresh()"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Refresh</span>
    </button>

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && showButtons"-->
    <!--            (click)="export()"-->
    <!--            [disabled]="!checkPaths.export.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Export'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="export"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Export</span>-->
    <!--    </button>-->

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && !isTrash && showButtons"-->
    <!--            (click)="import()"-->
    <!--            [disabled]="!checkPaths.import.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Import'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="import"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Import</span>-->
    <!--    </button>-->

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && !isTrash && showButtons"-->
    <!--            (click)="importInfo()"-->
    <!--            [disabled]="!checkPaths.import.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Import info'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="import-info"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Import info</span>-->
    <!--    </button>-->

    <button id="deleteButton" mat-button
            *ngIf="showButtons && !forDialog && (hasSelected || showDelete)"
            (click)="delete()"
            [disabled]="!checkPaths.delete.enabled"
            [matTooltip]="!showTooltip? undefined : 'Delete'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Delete</span>
    </button>

    <button mat-button
            *ngIf="isTrash && showButtons && !forDialog  && ( hasSelected || showRestore) "
            (click)="restore()"
            [disabled]="!checkPaths.restore.enabled"
            [matTooltip]="!showTooltip? undefined : 'Restore'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="restore"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Restore</span>
    </button>

    <!--    <button mat-button-->
    <!--            *ngIf="!forDialog && !isTrash && showButtons"-->
    <!--            (click)="trashRecords()"-->
    <!--            [disabled]="!checkPaths.trashRecords.enabled"-->
    <!--            [matTooltip]="!showTooltip? undefined : 'Trash records'">-->
    <!--      <mx-icon [marginBottom]="iconMarginBottom" mx="trash"></mx-icon>-->
    <!--      <span class="px-1" *ngIf="showButtonText">Trash records</span>-->
    <!--    </button>-->

    <button mat-button
            *ngIf="!forDialog && isTrash && showButtons"
            (click)="activeRecords()"
            [disabled]="!checkPaths.activeRecords.enabled"
            [matTooltip]="!showTooltip? undefined : 'Active records'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="cases"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Active records</span>
    </button>

    <span class="example-spacer"></span>

    <button mat-button
            *ngIf="!forDialog && showButtons"
            [disabled]="false"
            [matMenuTriggerFor]="username"
            [matTooltip]="!showTooltip? undefined : 'Options'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="options"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">More options</span>
    </button>

    <mat-menu #username="matMenu">
      <button
        *ngIf="!forDialog && !isTrash && showButtons && checkPaths.compile.enabled"
        (click)="compile()"
        [disabled]="!checkPaths.compile.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="compile" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Compile</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && !checkPaths.compile.enabled"
        (click)="$event.stopPropagation()"
        [disabled]="!checkPaths.compile.enabled"
        mat-menu-item>
        <mx-icon mx="compile"></mx-icon>
        <span class="ml-2">Compile</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && checkPaths.compile.enabled"
        [disabled]="!checkPaths.compile.enabled"
        (click)="compileInfo()"
        color="primary" mat-menu-item>
        <mx-icon mx="compile-info" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Compile logs</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && !checkPaths.compile.enabled"
        [disabled]="!checkPaths.compile.enabled"
        (click)="$event.stopPropagation();"
        mat-menu-item>
        <mx-icon mx="compile-info"></mx-icon>
        <span class="ml-2">Compile logs</span>
      </button>

      <button
        *ngIf="!forDialog && showButtons && checkPaths.export.enabled"
        (click)="export()"
        [disabled]="!checkPaths.export.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="export" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Export</span>
      </button>

      <button
        *ngIf="!forDialog && showButtons && !checkPaths.export.enabled"
        (click)="$event.stopPropagation();"
        [disabled]="!checkPaths.export.enabled"
        mat-menu-item>
        <mx-icon mx="export"></mx-icon>
        <span class="ml-2">Export</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && checkPaths.import.enabled"
        (click)="import()"
        [disabled]="!checkPaths.import.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="import" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Import</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && !checkPaths.import.enabled"
        (click)="$event.stopPropagation();"
        [disabled]="!checkPaths.import.enabled"
        mat-menu-item>
        <mx-icon mx="import"></mx-icon>
        <span class="ml-2">Import</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && checkPaths.import.enabled"
        (click)="importInfo()"
        [disabled]="!checkPaths.import.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="import-info" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Import Info</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && !checkPaths.import.enabled"
        (click)="$event.stopPropagation();"
        [disabled]="!checkPaths.import.enabled"
        mat-menu-item>
        <mx-icon mx="import-info"></mx-icon>
        <span class="ml-2">Import Info</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && checkPaths.trashRecords.enabled"
        (click)="trashRecords()"
        [disabled]="!checkPaths.trashRecords.enabled"
        color="primary" mat-menu-item>
        <mx-icon mx="trash" color="primary"></mx-icon>
        <span class="menu-text-primary ml-2">Bin</span>
      </button>

      <button
        *ngIf="!forDialog && !isTrash && showButtons && !checkPaths.trashRecords.enabled"
        (click)="$event.stopPropagation();"
        [disabled]="!checkPaths.trashRecords.enabled"
        mat-menu-item>
        <mx-icon mx="trash"></mx-icon>
        <span class="ml-2">Bin</span>
      </button>

      <!--      <button-->
      <!--        *ngIf="!forDialog && isTrash && showButtons && checkPaths.activeRecords.enabled"-->
      <!--        (click)="activeRecords()"-->
      <!--        [disabled]="!checkPaths.activeRecords.enabled"-->
      <!--        color="primary" mat-menu-item>-->
      <!--        <mx-icon mx="cases" color="primary"></mx-icon>-->
      <!--        <span class="menu-text-primary ml-2">Active records</span>-->
      <!--      </button>-->

      <!--      <button-->
      <!--        *ngIf="!forDialog && isTrash && showButtons && !checkPaths.activeRecords.enabled"-->
      <!--        (click)="$event.stopPropagation();"-->
      <!--        [disabled]="!checkPaths.activeRecords.enabled"-->
      <!--        mat-menu-item>-->
      <!--        <mx-icon mx="cases"></mx-icon>-->
      <!--        <span class="ml-2">Active records</span>-->
      <!--      </button>-->
    </mat-menu>

  </mx-options-header>

  <mx-options-body>

    <div class="filter">
      <!--      [lockSearchParams]="lockFilterParams"-->
      <app-filter-case

        [searchParams]="searchParams"
        [pageObject]="showPageObject"
        (searchParamsChange)="newParams($event)"
        (filter)="filter()"
        (close)="mxOptions.closeBody();"
      ></app-filter-case>
    </div>

  </mx-options-body>
</mx-options>
