import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';

import {AppRoutingModule, routes} from './app-routing.module';
import {ApiService} from './shared/services/api.service';
import {ConfigService} from './shared/services/config.service';
import {UserService} from './layout/users/shared/services/user.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthGuard} from './shared/guard/auth.guard';
import {CaseService} from './layout/cases/shared/services/case.service';
import {CompileService} from './layout/cases/shared/services/compile.service';
import {RunService} from './layout/runs/shared/services/run.service';

import {SuiteService} from './layout/suites/shared/services/suite.service';
import {FileService} from './shared/services/file.service';
import {AdminGuard} from './shared/guard/admin.guard';
import {GlobalEventsManagerService} from './shared/services/global-events-manager.service';
import {EqualValidator} from './shared/validators/equal-validator.directive';
import {CanDeactivateGuard} from './shared/can-deactivate/can-deactivate.guard';
import {AuthInterceptor} from './shared/services/auth-interceptor.service';
import {AngularFireModule} from '@angular/fire';
import {OnlyNumber} from './shared/util-directive/only-number/only-number.directive';
import {EnumToArrayPipe} from './shared/util-directive/enum-to-array/enum-to-array.pipe';
import {RouterModule} from '@angular/router';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';

import {environment} from '../environments/environment';
import {CasesGuard} from './shared/guard/cases.guard';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {NavbarModule} from './layout/navbar/navbar.model';
import {IdleDialogModule} from './layout/dialogs/idle-dialog/idle-dialog.module';
import {ConsoleToggleServiceService} from "./shared/services/console-toggle-service.service";

export function initUserFactory(userService: UserService) {
  return () => userService.initUser();
}

@NgModule({
  declarations: [
    AppComponent,
    EqualValidator,
    OnlyNumber,
    EnumToArrayPipe
  ],
  imports: [
    IdleDialogModule,
    NavbarModule,
    AngularFireModule.initializeApp(environment.firebase),
    RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', relativeLinkResolution: 'corrected'}),
    NgxAuthFirebaseUIModule.forRoot(
      environment.firebase,
      undefined,
      {
        enableFirestoreSync: true, // enable/disable autosync userRequests with firestore
        toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
        toastMessageOnAuthError: true, // whether to open/show a snackbar message on auth error - default : true
        passwordMinLength: 6
      }
    ),
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  exports: [RouterModule, OnlyNumber],
  providers: [
    ApiService,
    UserService,
    ConfigService,
    CaseService,
    SuiteService,
    CompileService,
    FileService,
    RunService,
    AuthGuard,
    AdminGuard,
    CasesGuard,
    GlobalEventsManagerService,
    ConsoleToggleServiceService,
    CanDeactivateGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initUserFactory,
      deps: [UserService],
      multi: true
    }
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass:
      AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
