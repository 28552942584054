import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/services/api.service';
import {UserService} from '../../../users/shared/services/user.service';
import {AiConfigService} from "./ai-config.service";
import {AIChatConfig} from "../models/aIChatConfig";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AiChatService {

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private aiConfigService: AiConfigService
  ) {
  }

  getChat() {
    return this.apiService.get(this.aiConfigService.aiController_GET_getAIChat(), '');
  }

  saveQuestion(message: any) {
    return this.apiService.post(this.aiConfigService.aiController_POST_addQuestion(), message);
  }

  updateChat(id, message) {
    return this.apiService.put(this.aiConfigService.aiController_PUT_updateQuestion(id), message);
  }

  askQuestionWithoutContent() {
    return this.apiService.get(this.aiConfigService.aiController_GET_askQuestion(), "{}");
  }

  delete() {
    return this.apiService.delete(this.aiConfigService.aiController_DELETE_deleteAiChat());
  }

  export() {
    return this.apiService.get(this.aiConfigService.aiController_GET_exportAiChat());
  }

  getAiConfig(): Observable<AIChatConfig> {
    return this.apiService.get(this.aiConfigService.aiController_GET_config());
  }

  updateAiConfig(aiConfig: AIChatConfig): Observable<AIChatConfig> {
    return this.apiService.put(this.aiConfigService.aiController_GET_config(), aiConfig);
  }
}
