import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CaseFileLocation} from '../../shared/models/case-file-location';
import {Case} from '../../shared/models/case';
import {BuildAutomationToolPath} from '../../shared/models/javaBuildAutomationToolPath';
import {CaseService} from '../../shared/services/case.service';

@Component({
  selector: 'app-change-file-location-dialog',
  templateUrl: './change-file-location-dialog.component.html',
  styleUrls: ['./change-file-location-dialog.component.css']
})
export class ChangeFileLocationDialogComponent implements OnInit {
  className = 'ChangeFileLocationDialogComponent';
  fileLocation: CaseFileLocation;
  testCase: Case;
  spinner = false;
  buildAutomationToolPath: BuildAutomationToolPath = new BuildAutomationToolPath();
  overwriteFileOnNewFileLocation = false;

  autoUpdate = true;


  constructor(
    public dialog: MatDialog,
    public caseService: CaseService,
    public dialogRef: MatDialogRef<ChangeFileLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      fileLocation: CaseFileLocation,
      testCase: Case,
      autoUpdate: boolean
    }
  ) {

    if (this.data.autoUpdate != null) {
      this.autoUpdate = this.data.autoUpdate;
    }

    if (this.data.fileLocation != undefined) {
      this.fileLocation = this.data.fileLocation;
    }

    if (this.data.testCase != undefined) {
      this.testCase = this.data.testCase;
    }

    this.getBuildAutomationToolPath();
  }

  ngOnInit(): void {
  }

  getBuildAutomationToolPath() {
    this.caseService.getBuildAutomationToolPath().subscribe(
      (buildAutomationToolPath: BuildAutomationToolPath) => {
        this.buildAutomationToolPath = buildAutomationToolPath;
      },
      error => {
        console.error(error);
      }
    );
  }

  updateCaseFileLocation() {
    this.fileLocation.packageCasePart = this.fileLocation.packageCasePartWithoutExtension + '.' + this.fileLocation.extension;
    this.fileLocation.fullPath = this.fileLocation.projectParamPart + this.fileLocation.buildAutomationToolPart + this.fileLocation.packageCasePart;
    this.fileLocation.fullPathWithoutExtension = this.fileLocation.projectParamPart + this.fileLocation.buildAutomationToolPart + this.fileLocation.packageCasePartWithoutExtension;
    this.fileLocation.fileName = this.fileLocation.packageCasePartWithoutExtension.substr(this.fileLocation.packageCasePartWithoutExtension.lastIndexOf('/') + 1);
    this.fileLocation.fileNameWithExtension = this.fileLocation.fileName + '.' + this.fileLocation.extension;

    if (this.autoUpdate) {
      // this.fileLocation.packageCasePart = this.fileLocation.packageCasePartWithoutExtension + '.' + this.fileLocation.extension;

      this.caseService.upadteCaseFileLocation(this.fileLocation, this.overwriteFileOnNewFileLocation).subscribe(
        (caseFileLocation: CaseFileLocation) => {
          this.fileLocation = caseFileLocation;
          this.closeDialog();
        },
        error => {
          console.error(error);
        }
      );
    } else {
      this.dialogRef.close(this.fileLocation);
    }
  }


  closeDialog(): void {
    this.dialogRef.close(this.fileLocation);
  }

  changeFileLocation() {

  }
}
