<section class="main-container ai-message-wrapper"

>
  <div class="ai-title-wrapper">
    <div class="title-box">
      <mx-icon mx="user" *ngIf="!ai"></mx-icon>
      <mx-icon mx="ai" *ngIf="ai"></mx-icon>
      <span class="title">{{(ai ? 'AI Chat' : 'You')}}</span>
    </div>
    <div class="title-buttons">
      <!--      <button mat-button-->
      <!--              style="margin: 0;padding: 0"-->
      <!--              [matTooltip]="'Copy to clipboard'"-->
      <!--              (click)="copyToClipboard(); $event.stopPropagation()">-->
      <!--        <mx-icon mx="copy"></mx-icon>-->
      <!--        &lt;!&ndash;    <span>Copy to clipboard</span>&ndash;&gt;-->
      <!--      </button>-->
    </div>
  </div>
  <div class="progress-box" style="padding-left: 7px; padding-right: 7px;">
    <!--    <mat-spinner color="primary" [diameter]="40"></mat-spinner>-->
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

</section>
