import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatDialog} from '@angular/material/dialog';
import {RemoteFilesService} from '../../shared/services/remote-files.service';
import {MaxtafTokensStorageService} from '../../../../shared/services/maxtaf-tokens-storage.service';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {Subscription} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';
import {MaxtafFileNode} from '../nav-workspace/shared/models/MaxtafFileNode';
import {DynamicFileSystemService} from '../nav-workspace/shared/services/dynamic-file-system.service';
import {NavMaxtafFileService} from '../nav-workspace/shared/services/nav-maxtaf-file.service';
import {CreateNewFileComponent} from '../create-new-file/create-new-file.component';
import {CreateNewFolderDialogComponent} from '../create-new-folder-dialog/create-new-folder-dialog.component';
import {UploadDialogComponent} from '../upload-dialog/upload-dialog.component';
import {YesNoDialogComponent} from '../../../dialogs/yes-no-dialog/yes-no-dialog.component';
import {saveAs} from 'file-saver';
import {UnzipDialogComponent} from '../unzip-dialog/unzip-dialog.component';
import {PasteDialogComponent} from '../paste-dialog/paste-dialog.component';
import {RenameDialogComponent} from '../rename-dialog/rename-dialog.component';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-ngx';
import {FilePropertiesDialogComponent} from '../file-properties-dialog/file-properties-dialog.component';
import {ZipDialogComponent} from '../zip-dialog/zip-dialog.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {CheckRolesService} from '../../../../shared/services/check-roles.service';
import {StorageConfigService} from '../../../../shared/services/storage-config.service';
import {CloudConfigService} from '../../../../shared/services/cloud-config.service';
import {CreateCaseDialogComponent} from '../../../cases/components/create-case-dialog/create-case-dialog.component';
import {Case} from '../../../cases/shared/models/case';
import {UserService} from '../../../users/shared/services/user.service';
import {DownloadFileDialogComponent} from '../download-file-dialog/download-file-dialog.component';
import {ConfigService} from '../../../../shared/services/config.service';
import {ProjectService} from '../../../projects/shared/services/project.service';
import {
  CreatePageObjectDialogComponent
} from '../../../cases/components/create-page-object-dialog/create-page-object-dialog.component';
import {SearchComponent} from "../file-search-dialog/search.component";

class NavWorkspaceTreeCheckPaths {
  createFolder = new CheckPath();
  createFile = new CheckPath();
  createCase = new CheckPath();
  rename = new CheckPath();
  delete = new CheckPath();
  upload = new CheckPath();
  download = new CheckPath();
  paste = new CheckPath();
  zip = new CheckPath();
  unzip = new CheckPath();
  cleanMaven = new CheckPath();
  compileMaven = new CheckPath();
  installMaven = new CheckPath();
}

@Component({
  selector: 'app-nav-workspace-tree',
  templateUrl: './nav-workspace-tree.component.html',
  styleUrls: ['./nav-workspace-tree.component.css']
})
export class NavWorkspaceTreeComponent implements OnInit, OnDestroy, AfterViewInit {


  showAlertError = false;
  error;

  activeNode = undefined;

  treeControl: FlatTreeControl<MaxtafFileNode>;

  dataSource: DynamicFileSystemService;

  checkPaths: NavWorkspaceTreeCheckPaths;

  @Input('height') height = '10px';


  @Input('isCopy') isCopy = true;
  @Output('isCopyChange') isCopyEmitter = new EventEmitter<boolean>();

  @Input('copyNode') copyNode: MaxtafFileNode = undefined;
  @Output('copyNodeChange') copyNodeEmitter = new EventEmitter<MaxtafFileNode>();

  @Input('initialData') set initialData(rootData: MaxtafFileNode[]) {
    this.treeControl = new FlatTreeControl<MaxtafFileNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicFileSystemService(this.treeControl, this.navMaxtafFileService);

    this.dataSource.data = rootData;
  }

  @ViewChild('navTreeScrolling') public navTreeScrolling: OverlayScrollbarsComponent;
  // @ViewChild('navTreeScrolling', {read: ElementRef, static: false}) navTreeScrolling: ElementRef;
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  overlayRef: OverlayRef | null;
  sub: Subscription;

  @Output('selected') selected = new EventEmitter<MaxtafFileNode>();
  @Output('createdNewFile') createdNewFile = new EventEmitter<string>();
  @Output('deleted') deleted = new EventEmitter<string>();

  isAdmin = false;
  canBePrivate = false;

  constructor(
    public dialog: MatDialog,
    private remoteFileService: RemoteFilesService,
    private navMaxtafFileService: NavMaxtafFileService,
    private tokensService: MaxtafTokensStorageService,
    public checkRolesService: CheckRolesService,
    private storageConfigService: StorageConfigService,
    private cloudConfigService: CloudConfigService,
    public overlay: Overlay,
    public configService: ConfigService,
    public userService: UserService,
    private projectService: ProjectService,
    public viewContainerRef: ViewContainerRef
  ) {
    this.isAdmin = this.userService.isAdmin();

    this.checkPaths = this.getNavWorkspaceTreeCheckPaths();
    this.checkRolesService.checkPaths(this.checkPaths).subscribe(
      checkPathsArray => {
        this.checkPaths = this.checkRolesService.transferCheckPathsArrayToObject(checkPathsArray);
      },
      error => {
        console.error(error);
      }
    );

    this.projectService.canBePrivate(this.userService.getCurrentUserProject().project.id).subscribe(
      (canBePrivate: boolean) => {
        this.canBePrivate = canBePrivate;


      }, error => {
        console.error(error);
        this.showError(error);
      });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    this.dataSource.allowScrolling(this.navTreeScrolling.osInstance());
    // this.dataSource.addFolderNodes('/java/src', this.tokensService.getProjectId());
  }

  ngOnDestroy(): void {
    const filterData: string[] = this.dataSource.data.filter(e => {
      return e.isFolder && e.childrenPageInfo;
    }).map(e => e.pathWithName).filter((value, index, array) => {

      for (let i = index + 1; i < array.length; i++) {
        if (array[i].startsWith(value)) {
          return false;
        }
      }
      return true;

    }).map(e => e + '/');

    // this.dataSource.findFileInTree('/java/libs');

  }


  openPageInNewWindow(url, w, h) {
    // Fixes dual-screen position
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft;
    const top = (height - h) / 2; /// systemZoom + dualScreenTop;


    const newWindow = window.open(url, '_blank',
      `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
    );

    if (window.focus) {
      newWindow.focus();
    }
  }

  getLevel = (node: MaxtafFileNode) => node.level;

  isExpandable = (node: MaxtafFileNode) => node.isFolder;

  hasChild = (_: number, node: MaxtafFileNode) => node.isFolder;

  isLoadMore = (_: number, node: MaxtafFileNode) => {
    return true; // node.childrenPageInfo != undefined && node.childrenPageInfo.totalPages > 1;
  };

  loadMore(loadMoreParentItem: any) {

  }

  // dd primer
  clickedFile(node: MaxtafFileNode) {
    if (node.typeOfFile == 'zip') {
      this.unzipDialog(node);
    } else {

      this.selected.emit(node);
      this.closeContextMenu();
    }
  }

  /************ context menu ************/

  openContextMenu(event: MouseEvent, node: MaxtafFileNode) {

    // event.type = 'contextmenu';
    this.closeContextMenu();

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(event)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);


    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    // this.overlayRef = this.overlay.create();

    this.overlayRef.attach(new TemplatePortal(this.contextMenu, this.viewContainerRef, {
      $implicit: node
    }));

  }

  closeContextMenu() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  /************ context menu button *************/


  createFile(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.createFileDialog(node);
  }

  createFileDialog(node: MaxtafFileNode) {

    const dialogRef = this.dialog.open(CreateNewFileComponent, {
      width: '400px',
      data: {
        path: node.pathWithName + '/',
        header: 'Add file',
        hidePath: true
      }
    });

    dialogRef.afterClosed().subscribe(filePath => {
      if (filePath != undefined) {
        this.createdNewFile.emit(filePath);
        this.refreshDataInNode(node);
      }
    });
  }

  createFolder(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.createNewFolderDialog(node);
  }

  createNewFolderDialog(node: MaxtafFileNode) {
    const dialogRef = this.dialog.open(CreateNewFolderDialogComponent, {
      width: '500px',
      data: {
        path: node.pathWithName + '/'
      }
    });

    dialogRef.componentInstance.onNewFolderCreated.subscribe(() => {
      this.refreshDataInNode(node);
    });

    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  upload(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.uploadFileDialog(node);
  }

  uploadFileDialog(node: MaxtafFileNode, files: any[] = []) {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      width: '700px',
      data: {
        projectId: this.tokensService.getProjectId(),
        uploadPath: node.isFolder ? node.pathWithName + (node.pathWithName.endsWith('/') ? '' : '/') : node.path + (node.path.endsWith('/') ? '' : '/'),
        files
      }
    });

    dialogRef.componentInstance.onUpload.subscribe(() => {
      if (node.isFolder) {
        this.refreshDataInNode(node);
      } else {
        this.refreshDataInNodeParent(node);
      }
    });

    dialogRef.afterClosed().subscribe(() => {
    });
  }


  rename(node: MaxtafFileNode) {
    this.closeContextMenu();

    this.renameDialog(node);
  }

  // RenameDialogComponent
  renameDialog(node: MaxtafFileNode) {
    const dialogRef = this.dialog.open(RenameDialogComponent, {
      width: '400px',
      data: {
        pathWithName: node.pathWithName,
        path: node.path,
        name: node.name,
        isFile: !node.isFolder
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        // this.createdNewFile.emit(filePath);
        this.refreshDataInNodeParent(node);
      }
    });
  }


  delete(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.deleteDialog(node);
  }

  deleteDialog(node: MaxtafFileNode): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {
      width: '400px',
      data: {
        body: '',
        title: 'Are you sure you want to delete this?',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteFile(node);
      }
    });
  }

  deleteFile(node: MaxtafFileNode) {
    this.remoteFileService.deleteFile(node.pathWithName)
      .subscribe(
        res => {
          this.deleted.emit(node.pathWithName);
          this.refreshDataInNodeParent(node);
        },
        error => {
          this.showError(error);
        }
      );
  }


  download(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.downloadDialog(node);
  }

  downloadDialog(node: MaxtafFileNode) {
    const dialogRef = this.dialog.open(DownloadFileDialogComponent, {
      width: '400px',
      data: {
        pathWithName: node.pathWithName,
        isFolder: node.isFolder,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // if (result) {
      //   this.downloadFile(node);
      // }
    });
  }

  downloadFile(node: MaxtafFileNode) {

    const filePaths: Array<string> = new Array<string>();

    filePaths.push(node.pathWithName + (node.isFolder ? '/' : ''));

    this.remoteFileService.downloadFilesOrFolders(filePaths)
      .subscribe(
        res => {
          const contentDisposition = res.headers.get('content-disposition') || '';
          const matches = /filename=([^;]+)/ig.exec(contentDisposition);
          const fileName = (matches[1] || 'untitled').trim();

          // TODO: Files like '.git' are saved with removed dot 'git'
          const blob = new Blob([res.body], {type: 'application/text'});
          saveAs(blob, fileName);
        },
        error => {
          this.showError(error);
        }
      );
  }

  copy(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.copyNode = node;
    this.isCopy = true;
    this.isCopyEmitter.emit(this.isCopy);
    this.copyNodeEmitter.emit(this.copyNode);
  }

  cut(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.copyNode = node;
    this.isCopy = false;
    this.isCopyEmitter.emit(this.isCopy);
    this.copyNodeEmitter.emit(this.copyNode);
  }

  paste(node: MaxtafFileNode) {
    this.closeContextMenu();

    this.pasteDialog(this.copyNode, node);
  }

  pasteDialog(copyNode: MaxtafFileNode, pasteNode: MaxtafFileNode) {
    const dialogRef = this.dialog.open(PasteDialogComponent, {
      width: '400px',
      data: {
        isCopy: this.isCopy,
        copyNode,
        pasteNode
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        // this.createdNewFile.emit(filePath);
        this.refreshDataInNode(pasteNode);
        if (!this.isCopy) {
          this.refreshDataInNodeParent(copyNode);
        }
      }
    });
  }

  zip(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.zipDialog(node);
  }

  zipDialog(node: MaxtafFileNode) {
    const dialogRef = this.dialog.open(ZipDialogComponent, {
      width: '500px',
      data: {
        node
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshDataInNodeParent(node);
      }
    });
  }

  unzip(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.unzipDialog(node);
  }

  unzipDialog(node: MaxtafFileNode) {
    const dialogRef = this.dialog.open(UnzipDialogComponent, {
      width: '500px',
      data: {
        header: 'Unzip \'' + node.name + '\'',
        zipLocation: node.pathWithName,
        unzipLocation: node.path + (node.path.endsWith('/') ? '' : '/')
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refreshDataInNodeParent(node);
      }
    });
  }

  refresh(node: MaxtafFileNode) {
    this.closeContextMenu();
    this.refreshDataInNode(node);
  }


  refreshDataInNode(node: MaxtafFileNode) {
    this.treeControl.collapse(node);
    this.treeControl.expand(node);
  }

  refreshDataInNodeParent(node: MaxtafFileNode) {
    const parent = this.getParent(node);
    this.refreshDataInNode(parent);
  }

  properties(node: MaxtafFileNode) {
    this.closeContextMenu();
    const dialogRef = this.dialog.open(FilePropertiesDialogComponent, {
      width: '500px',
      data: {
        pathWithName: node.pathWithName,
        edit: false,
        projectId: node.projectId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == null) {
        return;
      }
    });
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  getParent(node: MaxtafFileNode) {
    const {treeControl} = this;
    const currentLevel = treeControl.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = treeControl.dataNodes[i];

      if (treeControl.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }


  showError(error) {
    console.error(error);
    this.error = error;
    this.showAlertError = true;
  }

  isPrimaryProject(node: MaxtafFileNode) {
    return node.projectId == this.tokensService.getProjectId();
  }

  onFileDropped($event: any, node: MaxtafFileNode) {
    this.uploadFileDialog(node, $event);
  }

  cleanMaven(node: MaxtafFileNode) {

  }

  compileMaven(node: MaxtafFileNode) {

  }

  installMaven(node: MaxtafFileNode) {

  }

  createCase(node: MaxtafFileNode) {
    var type = 'JAVA';
    if (node.pathWithName.startsWith('/java/src/test/java')) {
      type = 'JAVA';
    }

    if (node.pathWithName.startsWith('/mxml')) {
      type = 'MXML';
    }

    if (node.pathWithName.startsWith('/python')) {
      type = 'PYTHON';
    }

    if (node.pathWithName.startsWith('/utam')) {
      type = 'UTAM';
    }

    if (node.pathWithName.startsWith('/javascript/scripts')) {
      type = 'JAVASCRIPT';
    }

    const dialogRef = this.dialog.open(CreateCaseDialogComponent, {
      width: '400px',
      data: {
        newCaseFilePath: node.pathWithName,

        fromWorkspace: true,
        openCreatedCase: false,
        type: type,
        blockType: true
      }
    });

    dialogRef.afterClosed().subscribe((result: Case) => {
      if (result) {
        // dd primer
        const maxtafFileNode = new MaxtafFileNode(
          result.fileLocation.fullPath.substr(result.fileLocation.fullPath.lastIndexOf('/') + 1),
          result.fileLocation.fullPath.substr(0, result.fileLocation.fullPath.lastIndexOf('/')),
          result.fileLocation.fullPath,
          result.fileLocation.fullPath.substr(result.fileLocation.fullPath.lastIndexOf('.') + 1),
          1,
          false,
          false,
          this.userService.getCurrentUserProject().project.id
        );
        this.selected.emit(maxtafFileNode);//MaxtafFileNode

        this.refresh(node);
      }
    });
  }

  createPageObject(node: MaxtafFileNode) {
    var type = 'JAVA';
    if (node.pathWithName.startsWith('/java/src/test/java')) {
      type = 'JAVA';
    }

    if (node.pathWithName.startsWith('/mxml')) {
      type = 'MXML';
    }

    if (node.pathWithName.startsWith('/utam')) {
      type = 'UTAM';
    }

    if (node.pathWithName.startsWith('/python')) {
      type = 'PYTHON';
    }

    if (node.pathWithName.startsWith('/javascript/scripts')) {
      type = 'JAVASCRIPT';
    }

    const dialogRef = this.dialog.open(CreatePageObjectDialogComponent, {
      width: '400px',
      data: {
        newCaseFilePath: node.pathWithName,

        fromWorkspace: true,
        openCreatedCase: false,
        type: type,
        blockType: true
      }
    });

    // dd primer
    dialogRef.afterClosed().subscribe((result: Case) => {
      if (result) {
        const maxtafFileNode = new MaxtafFileNode(
          result.fileLocation.fullPath.substr(result.fileLocation.fullPath.lastIndexOf('/') + 1),
          result.fileLocation.fullPath.substr(0, result.fileLocation.fullPath.lastIndexOf('/')),
          result.fileLocation.fullPath,
          result.fileLocation.fullPath.substr(result.fileLocation.fullPath.lastIndexOf('.') + 1),
          1,
          false,
          false,
          this.userService.getCurrentUserProject().project.id
        );
        this.selected.emit(maxtafFileNode);//MaxtafFileNode

        this.refresh(node);
      }
    });
  }

  canCreateCase(node: MaxtafFileNode) {
    if (node.pathWithName.startsWith('/java/src/test/java')) {
      return true;
    }

    if (node.pathWithName.startsWith('/mxml')) {
      return true;
    }

    if (node.pathWithName.startsWith('/utma')) {
      return true;
    }

    if (node.pathWithName.startsWith('/python')) {
      return true;
    }

    if (node.pathWithName.startsWith('/javascript/scripts')) {
      return true;
    }

    return false;
  }

  private getNavWorkspaceTreeCheckPaths(): NavWorkspaceTreeCheckPaths {
    return {
      createFolder: new CheckPath('POST', this.storageConfigService.createFolder()),
      createFile: new CheckPath('POST', this.storageConfigService.createFile()),
      createCase: new CheckPath('POST', this.cloudConfigService.addCase()),
      rename: new CheckPath('PUT', this.storageConfigService.renameFile()),
      delete: new CheckPath('DELETE', this.storageConfigService.deleteFile()),
      upload: new CheckPath('POST', this.storageConfigService.uploadFile()),
      download: new CheckPath('GET', this.storageConfigService.downloadFile()),
      paste: new CheckPath('POST', this.storageConfigService.copyOrMoveFile()),
      zip: new CheckPath('POST', this.storageConfigService.zipFile()),
      unzip: new CheckPath('POST', this.storageConfigService.unzipFile()),
      cleanMaven: new CheckPath('', ''),
      compileMaven: new CheckPath('', ''),
      installMaven: new CheckPath('', ''),
    };
  }


  searchFolder(node: any) {
    // launch file search dialog
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '700px',
      data: {
        path: node.pathWithName === '/' ? node.pathWithName : node.pathWithName + '/'
      }
    });

    dialogRef.afterClosed().subscribe(({path, lineNumber}) => {
      if (path) {
        // Extract file name and path from the result string
        const lastSlashIndex = path.lastIndexOf('/');
        const fileName = path.substring(lastSlashIndex + 1);
        const filePath = path.substring(0, lastSlashIndex);
        const fileType = path.substr(path.lastIndexOf('.') + 1);
        const maxtafFileNode = new MaxtafFileNode(
          fileName,
          filePath,
          path,
          fileType,
          1,
          false,
          false,
          this.userService.getCurrentUserProject().project.id
        );
        maxtafFileNode.selectLine = lineNumber;
        this.selected.emit(maxtafFileNode);//MaxtafFileNode
        this.refresh(node);
      }


      });


}}
