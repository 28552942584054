<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="defaultColors"
  #mxOptions
>

  <mx-options-header>

    <!--    &lt;!&ndash;      filter&ndash;&gt;-->
    <!--    <app-filter-by-->
    <!--      class="filter-by mr-1 ml-2"-->
    <!--      *ngIf="showButtons"-->
    <!--      [searchParams]="searchParams"-->
    <!--      [disabled]="!checkPaths.filter.enabled"-->
    <!--      (searchParamsChange)="newParams($event)"-->
    <!--      (filter)="filter()"-->
    <!--      [isSearchCollapsed]="mxOptions.isSearchCollapsed"-->
    <!--      (isSearchCollapsedChange)="mxOptions.toggleBody();"-->
    <!--    ></app-filter-by>-->

    <button mat-button
            *ngIf="showButtons"
            (click)="mxOptions.toggleBody();"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Filter'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="filter"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Filter</span>
    </button>

    <!--      refresh-->
    <button mat-button
            *ngIf="showButtons"
            (click)="refresh()"
            [disabled]="!checkPaths.refresh.enabled"
            [matTooltip]="!showTooltip? undefined : 'Refresh'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="refresh"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Refresh</span>
    </button>

    <!--      delete-->
    <button id="deleteButton" mat-button
            *ngIf="showButtons && !forDialog"
            (click)="delete()"
            [disabled]="!checkPaths.delete.enabled"
            [matTooltip]="!showTooltip? undefined : 'Delete'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="delete"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Delete</span>
    </button>

  </mx-options-header>

  <mx-options-body>
    <div class="filter">
      <app-import-case-info-filter
        [lockSearchParams]="lockFilterParams"
        [searchParams]="searchParams"
        (searchParamsChange)="newParams($event)"
        (filter)="filter()"
        (close)="mxOptions.closeBody();"
      ></app-import-case-info-filter>
    </div>

  </mx-options-body>
</mx-options>
