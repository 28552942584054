<mat-toolbar class="px-4 mb-0">
  <span>Select a project</span>
  <span class="example-spacer"></span>
</mat-toolbar>

<div mat-dialog-content class="mt-0 pt-0">

  <div class="px-4">
    <app-error-message *ngIf="showErrorAlert" [error]="error" [showInDialog]="true"></app-error-message>
  </div>

  <app-project-options
    [checkPaths]="getProjectOptionsCheckPathObject()"
    [(searchParams)]="params.search"
    (filter)="filter($event)"
    (create)="openCreateDialog('Project', null, null)"
    (refresh)="refreshProjects()"
  >

  </app-project-options>
  <app-projects-list
    [autoSetItems]="true"

    [searchParams]="params.search"
    [pageParams]="params.page"
    (pageParamsChange)="pageParamsChange($event)"

    (clickedItem)="clickedProject($event)"
  >

  </app-projects-list>
</div>

<div mat-dialog-actions style="margin-right: 24px">
  <button mat-button [mat-dialog-close]>Cancel</button>
</div>
