import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CompileError} from '../../shared/models/compileError';
import {CompileService} from '../../shared/services/compile.service';
import {CaseSearchParams} from '../../shared/services/case-const.service';
import {interval, Subscription} from 'rxjs';
import {CompileInfo} from '../../shared/models/compileInfo';
import {
  CompileInfoCaseInfoOptionsPaths
} from '../compile-info-case-info-options/compile-info-case-info-options.component';
import {CheckPath} from '../../../mx/options/CheckPath';
import {
  CompileInfoCaseInfoConstParams,
  CompileInfoCaseInfoConstService,
  CompileInfoCaseInfoDialogParams
} from '../compile-info-details-dilaog/compile-info-case-info-const.service';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CompileCaseInfo} from '../../shared/models/CompileCaseInfo';
import {CompileInfoCaseInfoListComponent} from '../compile-info-case-info-list/compile-info-case-info-list.component';
import {PageParams} from '../../../mx/util/params/PageParams';
import {ClickedTableItem} from '../../../mx/util/list/ClickedTableItem';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-compile-dialog',
  templateUrl: './compile-dialog.component.html',
  styleUrls: ['./compile-dialog.component.css']
})
export class CompileDialogComponent implements OnInit {

  searchParams: CaseSearchParams = undefined;
  ids: [] = [];
  compileErrors: CompileError[] = [];
  spinner = false;
  compileType = 1;
  refreshIntervalSub: Subscription;
  @Input('refreshIntervalInMs') refreshInterval = 1000;

  // Workaround for angular component issue #13870
  disableAnimation = true;

  startCompile = false;
  endCompile = false;
  compileInfo: CompileInfo = new CompileInfo();

  constructor(
    public dialogRef: MatDialogRef<CompileDialogComponent>,
    protected route: ActivatedRoute,
    private allDialogRef: MatDialog,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) public data: {
      ids: [],
      searchParams: CaseSearchParams
    },
    private compileService: CompileService
  ) {
    dialogRef.disableClose = true;
    if (this.data.ids != undefined && this.data.ids.length > 0) {
      this.ids = this.data.ids;
      this.compileType = 1;
    } else {
      this.compileType = 2;
    }

    if (this.data.searchParams != undefined) {
      this.searchParams = this.data.searchParams;
    }

    this.params = CompileInfoCaseInfoConstService.initCompileInfoCaseInfoDialogParams(5);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stopLiveRefresh();
  }

  ngAfterViewInit(): void {
    // timeout required to avoid the dreaded 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => this.disableAnimation = false);
  }


  compile() {
    this.spinner = true;
    this.startCompile = true;
    // this.compileService.compileCases(this.ids, this.searchParams, this.compileType).subscribe(
    this.compileService.compileCasesAsync(this.ids, this.searchParams, this.compileType).subscribe(
      res => {

        // this.spinner = false;
        // this.compileErrors = res;
        // if (this.compileErrors == null || this.compileErrors.length == 0) {
        //   this.dialogRef.close();
        // }

        this.compileInfo = res;
        this.startLiveRefresh();
      },
      error => {
        this.spinner = false;
        console.error(error);
      }
    );
  }

  compileInfoFunction() {
    // this.compileService.compileCasesInfo(this.compileInfo.id).subscribe(
    this.compileService.compileCasesInfo(this.compileInfo.id).subscribe(
      (res: CompileInfo) => {
        this.compileInfo = res;

        // this.spinner = false;
        // this.compileErrors = res;
        // if (this.compileErrors == null || this.compileErrors.length == 0) {
        //   this.dialogRef.close();
        // }

        this.importInfoResponse(this.compileInfo);
      },
      error => {
        console.error(error);
      }
    );
  }

  pagesInfo: PageInfo = new PageInfo();
  compileInfos: CompileCaseInfo[] = [];
  @ViewChild(CompileInfoCaseInfoListComponent) compileInfoList: CompileInfoCaseInfoListComponent;
  params: CompileInfoCaseInfoDialogParams;


  getCompileInfoCaseInfoListOptionsCheckPathObject(): CompileInfoCaseInfoOptionsPaths {
    return {
      refresh: new CheckPath('GET', this.compileService.compileCasesInfos()),
      filter: new CheckPath('GET', this.compileService.compileCasesInfos())
    };
  }


  filter(compileInfoCaseInfoConstParams: CompileInfoCaseInfoConstParams) {
    this.compileInfoList.getItems();
  }

  refreshCompileInfo() {
    this.compileInfoList.getItems();
  }


  setPageInfoChanges(pagesInfo: PageInfo) {
    this.pagesInfo = pagesInfo;
  }

  pageParamsChange(pageParams: PageParams) {
    this.params.page = pageParams;
  }


  compiledError(compiledError: any) {
    this.router.navigate(
      [
        'cases-workspace',
      ],
      {
        queryParams: {
          caseId: compiledError.compileCaseInfo.testCaseId,
          scriptTab: true,
          selectLine: compiledError.compiledError.line,
          selectPath: compiledError.compiledError.path,
          selectType: 'compile',
          compileError: compiledError.compileCaseInfo.compileError,
          transpileError: compiledError.compileCaseInfo.transpileError,
        }
      });
    this.allDialogRef.closeAll();
  }

  transpiledError(transpiledError: any) {
    this.router.navigate(
      [
        'cases-workspace'
      ],
      {
        queryParams: {
          caseId: transpiledError.compileCaseInfo.testCaseId,
          scriptTab: true,
          selectLine: transpiledError.compiledError.line,
          selectPath: transpiledError.compiledError.path,
          selectType: 'transpile',
          compileError: transpiledError.compileCaseInfo.compileError,
          transpileError: transpiledError.compileCaseInfo.transpileError,
        }
      });
    this.allDialogRef.closeAll();
  }

  clickedCompileInfo(compileCaseInfo: ClickedTableItem<CompileCaseInfo>) {
    //
    // const dialogRef = this.dialog.open(CompileInfoDetailsDialogComponent, {
    //   width: '600px',
    //   data: {
    //     compileInfo: compileInfo.item
    //   }
    // });
    //
    // dialogRef.afterClosed().subscribe(result => {
    //
    // });

  }


  private importInfoResponse(res: CompileInfo) {
    this.compileInfo = res;

    if (this.compileInfo.completed) {
      this.spinner = false;
      this.endCompile = true;
      this.stopLiveRefresh();
    }
  }

  startLiveRefresh() {
    this.refreshIntervalSub = interval(this.refreshInterval)
      .subscribe(res => {
        if (document.hidden) {
          return;
        }

        this.compileInfoFunction();
      });
  }


  stopLiveRefresh() {
    //this.showSuccess = true;
    if (this.refreshIntervalSub) {
      this.refreshIntervalSub.unsubscribe();
    }
  }

  paramsChangeValue() {

  }
}
