<h1 mat-dialog-title class="text-center">Are you sure you want to delete the case import information?</h1>
<div mat-dialog-content>
  <app-error-message *ngIf="showErrorAlert" [error]="error" [fullWidth]="true"></app-error-message>

  <div [@.disabled]="disableAnimation" *ngIf="!hideAdvancedSettings" style="margin-left: 2rem">
    <br>

    <mat-radio-group [(ngModel)]="deleteType" color="primary">
      <mat-radio-button [value]="1" [disabled]="this.selected.length == 0">Only selected</mat-radio-button>
      <br>
      <mat-radio-button [value]="2">Use Filter</mat-radio-button>
    </mat-radio-group>
  </div>

  <br>

</div>
<div mat-dialog-actions>
  <button id="noButton" mat-button [mat-dialog-close]="false" *ngIf="!spinner || !disableSecondButton" color="warn">
    NO
  </button>

  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>

  <button *ngIf="!spinner" id="yesButton" mat-button (click)="deleteDeleteDialog()" color="primary">
    YES
  </button>
</div>
