<h1 mat-dialog-title>

  <div class="user-profile">
    <div class="user-avatar">
      <mx-icon mx="user-avatar"></mx-icon>
    </div>
    <div class="profile-description">
      <div class="profile-primary-text"
           *ngIf="userProject != null && userProject.user.email != null">{{userProject.user.email}}</div>
      <div class="profile-secondary-text"
           *ngIf="userProject != null && userProject.user.displayName != null">{{userProject.user.displayName}}</div>
    </div>
  </div>

</h1>
<div mat-dialog-content>
  <app-user [userProject]="userProject"
            [shadow]="false"
            [allowEditName]="allowEditName"
            [userDetailsShow]="userDetailsShow"
            [userProjectDetailsShow]="userProjectDetailsShow"></app-user>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="userProject" color="primary">Close</button>
</div>
