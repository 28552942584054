<div mat-dialog-title style="display: flex; justify-content: space-between;">
  <h4 style="align-self:flex-start;">Change password</h4>

  <span style="font-size: 16px; align-self:flex-end;" [style.color]="color">{{message}}</span>
  <mat-spinner *ngIf="spinner" style="align-self:flex-end;" [diameter]="50" color="accent"></mat-spinner>
</div>

<div mat-dialog-content>

  <form [formGroup]="form" #loginForm="ngForm">

    <mat-form-field class="full-width-input">
      <input matInput ngModel name="hideCurrentPasswordButton"
             [type]="hideCurrentPasswordButton  ? 'password' : 'text'" placeholder="Current password"
             formControlName="oldPassword">
      <mat-icon matSuffix (click)="hideCurrentPasswordButton = !hideCurrentPasswordButton">{{hideCurrentPasswordButton ?
        'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error>Current password is <strong>required</strong></mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input">
      <input matInput [type]="hidePasswordButton  ? 'password' : 'text'" placeholder="New password"
             formControlName="password">
      <mat-icon matSuffix (click)="hidePasswordButton = !hidePasswordButton">{{hidePasswordButton ? 'visibility' :
        'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="form.get('password').invalid">
        Password is <strong>required</strong> and min length is 5
      </mat-error>
    </mat-form-field>

    <mat-form-field class="full-width-input">
      <input matInput [type]="hideConfirmPasswordButton ? 'password' : 'text'" placeholder="Retype new password"
             formControlName="confirmPassword">
      <mat-icon matSuffix (click)="hideConfirmPasswordButton = !hideConfirmPasswordButton">
        {{hideConfirmPasswordButton ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="form.get('confirmPassword')?.invalid">Passwords should be the same</mat-error>
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions style="display: flex; justify-content: flex-end;">
  <button mat-raised-button *ngIf="!showButtonOk" (click)="tryChangePassword()" color="primary"
          style="float:right;font-weight:bold;">Change password
  </button>
  <button mat-raised-button *ngIf="showButtonOk" [mat-dialog-close]="true" color="primary"
          style="float:right;font-weight:bold;">OK
  </button>
  <button mat-button [mat-dialog-close]="false">Cancel</button>
</div>
