import {Injectable} from '@angular/core';
import {SearchParam} from '../../../mx/util/params/searchParam';
import {PageParams} from '../../../mx/util/params/PageParams';
import {RunConstService, RunSearchParams} from '../../../runs/shared/services/run-const.service';
import {ScheduleConstService, ScheduleSearchParams} from '../../../scheduler/shared/services/schedule-const.service';
import {CaseSearchParams} from '../../../cases/shared/services/case-const.service';
import {PageInfo} from '../../../../shared/models/pageInfo';
import {CaseSuiteConstCommonService} from "../../../cases/shared/services/case-suite-const-common.service";

@Injectable({
  providedIn: 'root'
})
export class SuiteConstService {

  constructor() {
  }

  public static getDefaultSearchParams(archived: boolean = false): SuiteSearchParams {
    return CaseSuiteConstCommonService.getDefaultCaseSearchParams(archived);
  }

  public static getDefaultPageParams(size = 10): PageParams {
    return CaseSuiteConstCommonService.getDefaultCasePageParams(size)
  }

  public static getDefaultSuitePageParams(): SuiteParams {
    return {
      suite: this.initSuiteComponentParams(),

      runPage: RunConstService.getDefaultPageParams(),
      runSearch: RunConstService.getDefaultSearchParams(),

      schedulePage: ScheduleConstService.getDefaultPageParams(),
      scheduleSearch: ScheduleConstService.getDefaultSearchParams(),

      suitesPage: this.getDefaultPageParams(1),
      suitesSearch: this.getDefaultSearchParams(),
      suiteIndex: new SearchParam(null, null)
    };
  }

  private static initSuiteComponentParams(): SuiteComponentParams {
    return {
      suiteId: new SearchParam(null, null),
      suiteTabIndex: new SearchParam(0, 0),

      itemsInSuite: this.initItemsInSuiteParams(),
    };
  }

  public static initItemsInSuiteParams(): ItemsInSuiteParams {
    return {
      suiteItemsSearch: this.getDefaultSuiteItemsSearchParams(),
      suiteItemsPage: this.getDefaultSuiteItemsPageParams(),

      addItemDialog: this.initAddItemDialogParams(),
      itemIndex: new SearchParam(null, null),
    };
  }

  public static initAddItemDialogParams(): SuiteAddItemsDialogParams {
    return {
      tabIndex: new SearchParam(0, 0),
      index: new SearchParam(null, null),

      caseSearch: CaseSuiteConstCommonService.getDefaultCaseSearchParams(false),
      casePage: CaseSuiteConstCommonService.getDefaultCasePageParams(5),
      casePageInfo: new PageInfo(),

      suitePage: this.getDefaultPageParams(10),
      suiteSearch: this.getDefaultSearchParams(false),
      suitePageInfo: new PageInfo()

    };
  }

  public static getDefaultSuiteItemsSearchParams(): SuiteItemsSearchParams {
    return {};
  }


  public static getDefaultSuiteItemsPageParams(): PageParams {
    return {
      page: new SearchParam(0, 0),
      size: new SearchParam(10, 10),
      sort: new SearchParam('sequence,asc', 'sequence,asc')
    };
  }
}

export class SuiteSearchParams {
  public exactSearch: SearchParam;
  public name: SearchParam;
  public description: SearchParam;
  public params: SearchParam;
  public archived: SearchParam;
}

export class SuiteParams {
  public suite: SuiteComponentParams;

  public runPage: PageParams;
  public runSearch: RunSearchParams;

  public schedulePage: PageParams;
  public scheduleSearch: ScheduleSearchParams;

  public suitesPage: PageParams;
  public suitesSearch: SuiteSearchParams;
  public suiteIndex: SearchParam;
}

export class SuiteComponentParams {
  public suiteId: SearchParam;
  public suiteTabIndex: SearchParam;

  public itemsInSuite: ItemsInSuiteParams;
}

export class ItemsInSuiteParams {
  public suiteItemsSearch: SuiteItemsSearchParams;
  public suiteItemsPage: PageParams;
  public addItemDialog: SuiteAddItemsDialogParams;
  public itemIndex: SearchParam;
}

export class SuiteAddItemsDialogParams {
  public tabIndex: SearchParam;
  public index: SearchParam;

  public caseSearch: CaseSearchParams;
  public casePage: PageParams;
  public casePageInfo: PageInfo;

  public suitePage: PageParams;
  public suiteSearch: SuiteSearchParams;
  public suitePageInfo: PageInfo;
}

export class SuiteItemsSearchParams {

}
