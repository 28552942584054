import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './shared/guard/auth.guard';
import {AdminGuard} from './shared/guard/admin.guard';
import {CasesGuard} from './shared/guard/cases.guard';

export const routes: Routes = [
  {
    path: 'tos',
    loadChildren: () => import('./tos/tos-page/tos-page.module').then(m => m.TosPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./layout/login-register/pages/register/register.module').then(m => m.RegisterModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./layout/login-register/pages/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./layout/login-register/pages/logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'selectProject'
  },
  {
    path: 'home',
    loadChildren: () => import('./layout/home-page/home-page.module').then(m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'files',
    loadChildren: () => import('./layout/storage/pages/workspace-page/workspace-page.module').then(m => m.WorkspacePageModule),
    canActivate: [CasesGuard]
  },
  {
    path: 'files/edit',
    loadChildren: () => import('./layout/storage/pages/workspace-page/workspace-page.module').then(m => m.WorkspacePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terminal',
    loadChildren: () => import('./layout/storage/pages/terminal-page/terminal-page.module').then(m => m.TerminalPageModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'noVncClient',
    loadChildren: () => import('./layout/storage/pages/novnc-client-page/novnc-client-page.module').then(m => m.NoVncClientPageModule),
    canActivate: [AuthGuard, AdminGuard]
  },
  {
    path: 'schedule',
    loadChildren: () => import('./layout/scheduler/pages/schedule-list-page/schedule-list-page.module').then(m => m.ScheduleListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'parameters',
    loadChildren: () => import('./layout/projectParams/pages/project-prams-page/project-prams-page.module').then(m => m.ProjectPramsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule/:jobId',
    loadChildren: () => import('./layout/scheduler/pages/schedule-job-details-page/schedule-job-details-page.module').then(m => m.ScheduleJobDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'selectProject',
    loadChildren: () => import('./layout/projects/pages/select-project/select-project.module').then(m => m.SelectProjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases/:caseid',
    loadChildren: () => import('./layout/cases/pages/single-case/single-case.module').then(m => m.SingleCaseModule),
    canActivate: [CasesGuard]
  },
  {
    path: 'pageObjects/:caseid',
    loadChildren: () => import('./layout/pageObject/pages/single-page-object/single-page-object.module').then(m => m.SinglePageObjectModule),
    canActivate: [CasesGuard]
  },
  {
    path: 'cases',
    loadChildren: () => import('./layout/cases/pages/home-case-list/home-case.module').then(m => m.HomeCaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cases-workspace',
    loadChildren: () => import('./layout/cases/pages/case-workspace/case-workspace.module').then(m => m.CaseWorkspaceModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'page-objects-workspace',
    loadChildren: () => import('./layout/cases/pages/page-objects-workspace/page-objects-workspace.module').then(m => m.PageObjectsWorkspaceModule),
    canActivate: [CasesGuard]
  },
  {
    path: 'pageObjects',
    loadChildren: () => import('./layout/pageObject/pages/home-page-object/home-page-object.module').then(m => m.HomePageObjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'suites/:suiteid',
    loadChildren: () => import('./layout/suites/pages/single-suite/single-suite.module').then(m => m.SingleSuiteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'suites',
    loadChildren: () => import('./layout/suites/pages/home-suites-list/home-suites-list.module').then(m => m.HomeSuitesListModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/cases/:caseid',
    loadChildren: () => import('./layout/cases/pages/single-case/single-case.module').then(m => m.SingleCaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/pageObjects/:caseid',
    loadChildren: () => import('./layout/pageObject/pages/single-page-object/single-page-object.module').then(m => m.SinglePageObjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'systemReport',
    loadChildren: () => import('./layout/system-report/pages/system-report-page/system-report-page.module').then(m => m.SystemReportPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/cases',
    loadChildren: () => import('./layout/cases/pages/archived-case/archived-case.module').then(m => m.ArchivedCaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/pageObjects',
    loadChildren: () => import('./layout/pageObject/pages/archived-page-object/archived-page-object.module').then(m => m.ArchivedPageObjectModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/suites/:suiteid',
    loadChildren: () => import('./layout/suites/pages/archived-single-suite/archived-single-suite.module').then(m => m.ArchivedSingleSuiteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'archived/suites',
    loadChildren: () => import('./layout/suites/pages/archived-suite/archived-suite.module').then(m => m.ArchivedSuiteModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'runs/:runid',
    loadChildren: () => import('./layout/runs/pages/run-details-page/run-details-page.module').then(m => m.RunDetailsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'runs',
    loadChildren: () => import('./layout/runs/pages/run-list-page/run-list-page.module').then(m => m.RunListPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'verifyEmail',
    loadChildren: () => import('./layout/login-register/pages/verify-email-address/verify-email-address.module').then(m => m.VerifyEmailAddressModule),
    // canActivate: [AuthGuard]
  },
  {
    path: 'transfer-spinner',
    loadChildren: () => import('./layout/storage/components/transfer-spinner/transfer-spinner.module').then(m => m.TransferSpinnerModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'middle-page',
    loadChildren: () => import('./layout/mx/middle-page/middle-page.module').then(m => m.MiddlePageModule),
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'activeRuns',
  //   loadChildren: () => import('./layout/runs/pages/run-active-log-list/run-active-log-list.module').then(m => m.RunActiveLogListModule),
  //   canActivate: [AuthGuard]
  // },
  {
    path: 'profile',
    loadChildren: () => import('./layout/users/pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings/project',
    loadChildren: () => import('./layout/users/pages/project-settings/project-settings.module').then(m => m.ProjectSettingsModule),
    canActivate: [AuthGuard]
  },
  {
    path: '404',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
    canActivate: [AuthGuard]
  },
  {
    path: '403',
    loadChildren: () => import('./forbidden/forbidden.module').then(m => m.ForbiddenModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'itemsDeleted',
    loadChildren: () => import('./item-deleted/item-deleted.module').then(m => m.ItemDeletedModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
