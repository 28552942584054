import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditorWorkspaceComponent} from './editor-workspace.component';
import {FormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {TabsWorkspaceComponent} from '../tabs-workspace/tabs-workspace.component';
import {IconModule} from '../../../mx/icon/icon.module';
import {ImageMxViewerModule} from '../image-viewer-workspace/image-mx-viewer.module';
import {MxVideoViewerModule} from '../mx-video-viewer/mx-video-viewer.module';
import {MxHtmlViewerModule} from '../mx-html-viewer/mx-html-viewer-module';
import {CaseTabsComponent} from '../../../cases/components/case-tabs/case-tabs.component';
import {AskQuestionDialogModule} from "../../../ai/components/ask-question-dialog/ask-question-dialog.module";

@NgModule({
  providers: [TabsWorkspaceComponent, CaseTabsComponent],
  declarations: [EditorWorkspaceComponent],
  exports: [
    EditorWorkspaceComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    IconModule,
    ImageMxViewerModule,
    MxVideoViewerModule,
    MxHtmlViewerModule,
    AskQuestionDialogModule
  ]
})
export class EditorWorkspaceModule {
}
