import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-ask-question-dialog',
  templateUrl: './ask-question-dialog.component.html',
  styleUrls: ['./ask-question-dialog.component.css']
})
export class AskQuestionDialogComponent implements OnInit {


  value = '';

  // Ctrl + S shortcut for saving parameters
  saveShortcutListener = function (e) {
    if ((window.navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey) && e.keyCode == 83) {
      e.preventDefault();
      document.getElementById('okButton').click();
    }
  };

  constructor(public dialogRef: MatDialogRef<AskQuestionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {}) {

  }

  ngOnInit(): void {
    document.addEventListener('keydown', this.saveShortcutListener, false);
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.saveShortcutListener, false);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
