import {Injectable} from '@angular/core';
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class UserProjectConfigService {

  constructor(
    private configService: ConfigService
  ) {
  }

  private DEFAULT_PATH_VAR = this.configService.getDefaultPathVar();

  private API_PATH = this.configService.getHostURL() + this.configService.getPrefix() + '/userproject';

  private USERS_PATH = 'users';
  private PROJECT_PATH = 'projects';
  private SECURITY_GROUP_PATH = 'securityGroups';
  private USER_PROJECT_PATH = this.USERS_PATH + '/' + this.PROJECT_PATH;
  private PROJECT_DATABASE_PATH = this.PROJECT_PATH + '/database/info';
  private BRIDGE_ADDRESS_PATH = 'bridgeAddress';
  private PARAMS_PATH = 'params';
  private DATA_PATH = 'data';
  private ALL_PARAMS_PATH = this.PARAMS_PATH + '/all';
  private SENSORS_PATH = 'sensors';
  private WHOAMI_PATH = 'whoami';
  private INVITATION_USER_PATH = 'invitationUser';
  private SYSTEM_PARAMS_PATH = 'system';
  private CAN_RUN_PATH = 'canRun';
  private RELEASE_RUNS_PATH = 'releaseRuns';
  private LIST_PATH = 'list';
  private ONE_PATH = 'findOne';
  private REFRESH_PATH = 'refresh';
  private CHECK_PATH = 'check';
  private ROLLBACK_TO_DATE_PROJECTS_PATH = 'rollbackToDateAllProjectDatabases';
  private UPDATE_ALL_PROJECT_DATABASES_PATH = 'updateAllProjectDatabases';
  private UPDATE_ALL_PROJECT_STORAGES_PATH = 'updateAllProjectStorages';
  private ENGINE_PATH = 'engine';
  private ENGINE_ADDRESS_PATH = this.ENGINE_PATH + '/address';
  private UNAUTHORIZED_REQUESTS_PATH = 'unauthorizedRequests';
  private IS_LICENSED_PATH = 'isLicensed';
  private CRUD_CASE_ROLE_PATH = 'role/crudCase';

  private USERS_URL = this.API_PATH + '/' + this.USERS_PATH;
  private PROJECT_URL = this.API_PATH + '/' + this.PROJECT_PATH;
  private UNAUTHORIZED_REQUESTS_URL = this.API_PATH + '/' + this.UNAUTHORIZED_REQUESTS_PATH;
  private SECURITY_GROUP_URL = this.API_PATH + '/' + this.SECURITY_GROUP_PATH;
  private USER_PROJECT_URL = this.API_PATH + '/' + this.USER_PROJECT_PATH;
  private USER_PROJECT_SECURITY_GROUP_URL = this.API_PATH + '/' + this.USER_PROJECT_PATH + '/' + 'securityGroups';
  private PROJECT_DATABASE_URL = this.API_PATH + '/' + this.PROJECT_DATABASE_PATH;
  private INVITATION_USER_URL = this.API_PATH + '/' + this.INVITATION_USER_PATH;
  private WHOAMI_URL = this.API_PATH + '/' + this.WHOAMI_PATH;
  private SYSTEM_PARAMS_URL = this.API_PATH + '/' + this.PARAMS_PATH + '/' + this.SYSTEM_PARAMS_PATH;
  private NOTIFY_RUN_REPORT_URL = this.API_PATH + '/' + 'run/report';
  private SYSTEM_REPORTS_PATH = 'systemReport';
  private SYSTEM_REPORTS_URL = this.API_PATH + '/' + this.SYSTEM_REPORTS_PATH;
  private API_TOKEN_KEY_URL = this.API_PATH + "/api/token/key"

  public whoami() {
    return this.WHOAMI_URL;
  }

  public getSecurityGroups() {
    return this.SECURITY_GROUP_URL;
  }

  public getSystemParams() {
    return this.SYSTEM_REPORTS_URL;
  }

  public invitationUser() {
    return this.INVITATION_USER_URL;
  }

  public getUserRequests() {
    return this.INVITATION_USER_URL;
  }

  public removeRequest(email: string = this.DEFAULT_PATH_VAR) {
    return this.INVITATION_USER_URL + '/' + email;
  }

  public invitationUserRefresh(email: string = this.DEFAULT_PATH_VAR) {
    return this.INVITATION_USER_URL + '/' + this.REFRESH_PATH + '/' + email;
  }

  public invitationUserCheck(email?: string, invCode?: string) {
    return this.INVITATION_USER_URL + '/' + this.CHECK_PATH + '?email=' + email + '&invCode=' + invCode;
  }

  public getUserProjects() {
    return this.USER_PROJECT_URL;
  }

  public getLogs(projectId) {
    return this.PROJECT_URL + '/' + projectId + '/unauthorizedRequests';
  }

  public updateUser(id: string = this.DEFAULT_PATH_VAR) {
    return this.USERS_URL + '/' + id;
  }

  public getProjects() {
    return this.PROJECT_URL;
  }

  public getProjectsWithCreateCaseRole() {
    return this.PROJECT_URL + '/' + this.CRUD_CASE_ROLE_PATH;
  }

  public getProject(id = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + id;
  }

  public canBePrivate(id = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + 'canBePrivate' + '/' + id;
  }

  public createProject(automaticallyGenerateId = false, clearNameOfIllegalCharacters = false) {
    return this.PROJECT_URL + '?automaticallyGenerateId=' + automaticallyGenerateId + '&clearNameOfIllegalCharacters=' + clearNameOfIllegalCharacters;
  }

  public updateProject(id: string = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + id;
  }

  public deleteProject(id: string = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + id;
  }

  public startEngineOrResetShutdownTimer(projectId: string = this.DEFAULT_PATH_VAR, forceRestart?) {
    if (forceRestart) {
      return this.PROJECT_URL + '/' + projectId + '/' + this.ENGINE_PATH + '?forceRestart=true';
    } else {
      return this.PROJECT_URL + '/' + projectId + '/' + this.ENGINE_PATH;
    }
  }

  restartEngineLsp(projectId: string) {
    return this.PROJECT_URL + '/' + projectId + '/lsp/restart/' + this.ENGINE_PATH;
  }

  public getEngineStatus(projectId: string = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + projectId + '/' + this.ENGINE_PATH;
  }

  public isLicensed(projectId: string = this.DEFAULT_PATH_VAR) {
    return this.PROJECT_URL + '/' + projectId + '/' + this.IS_LICENSED_PATH;
  }

  public deleteUserProject(userProjectId: string = this.DEFAULT_PATH_VAR) {
    return this.USER_PROJECT_URL + '/' + userProjectId;
  }

  public updateUserProject(userProjectId: string = this.DEFAULT_PATH_VAR) {
    return this.USER_PROJECT_URL + '/' + userProjectId;
  }

  public createOrUpdateOrDeleteUserProjectSecurityGroups(userProjectId: string = this.DEFAULT_PATH_VAR) {
    return this.USER_PROJECT_SECURITY_GROUP_URL + '/' + userProjectId;
  }

  public getBridgeAddress() {
    return this.USER_PROJECT_URL + '/' + this.BRIDGE_ADDRESS_PATH;
  }

  USER_PREFERENCES_PARAMS_URL = this.API_PATH + '/' + this.PARAMS_PATH + '/user/preferences';
  FIND_ONE_PATH = 'findOne';

  public getUserPreferencesParams() {
    return this.USER_PREFERENCES_PARAMS_URL;
  }

  public getUserPreferencesParamsList() {
    return this.USER_PREFERENCES_PARAMS_URL + '/' + this.LIST_PATH;
  }

  public getUserPreferencesParam() {
    return this.USER_PREFERENCES_PARAMS_URL + '/' + this.FIND_ONE_PATH;
  }

  public setUserPreferencesParam() {
    return this.USER_PREFERENCES_PARAMS_URL;
  }

  public getSensors() {
    return this.USER_PROJECT_URL + '/' + this.SENSORS_PATH;
  }

  AI_URL = this.API_PATH + '/assistant/chat';

  getChat(userProjectId: string) {
    return this.AI_URL; //+ '/' + userProjectId;
  }

  askQuestion(userProjectId: string) {
    return this.AI_URL; //+ '/' + userProjectId;
  }

  askQuestionWithoutContent(userProjectId: string) {
    return this.AI_URL + "/" + "ask";
  }

  saveQuestion(userProjectId: string) {
    return this.AI_URL + "/" + "question";
  }

  delete(userProjectId: string) {
    return this.AI_URL + "/" + "delete";
  }

  export() {
    return this.AI_URL + "/" + "export";
  }

  getChatNormal() {
    return  this.API_PATH + "/" + "chat1";
  }

  getChatWebFlux() {
    return this.API_PATH + "/" + "chat";
  }

  createApiKey() {
    return this.API_TOKEN_KEY_URL;
  }
}
