import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import * as monaco from "monaco-editor";

@Component({
  selector: 'app-mx-diff-code',
  templateUrl: './mx-diff-code.component.html',
  styleUrls: ['./mx-diff-code.component.css']
})
export class MxDiffCodeComponent implements OnInit {
  showErrorAlert = false;
  error;

  leftLanguage = "text/plain";

  @Input("leftLanguage") set _setMainLanguage(leftLanguage) {
    this.leftLanguage = this.setLanguage(leftLanguage.toLowerCase().trim());
  }

  leftCode = "";

  @Input("leftCode") set _setLeftCode(leftCode) {
    if(this.leftCode != ""){
      this.leftCode = leftCode;
      this.updateLeftModel(this.leftCode);
    }else{
      this.leftCode = leftCode;
    }

    // this.createDiffEditor();

  }

  rightLanguage = "text/plain";

  @Input("rightLanguage") set _setSecondLanguage(rightLanguage) {
    this.rightLanguage = this.setLanguage(rightLanguage.toLowerCase().trim());
  }

  rightCode = "";

  @Input("rightCode") set _setRightCode(rightCode) {
    this.rightCode = rightCode;
    // this.createDiffEditor();
  }

  @ViewChild('editorContainer', {static: true}) editorContainerRef: ElementRef;
  @ViewChild('diffNavigator', {static: true}) diffNavigatorRef: ElementRef;
  private diffEditor: monaco.editor.IStandaloneDiffEditor;
  private diffNavigator: monaco.editor.IDiffNavigator;

  private originalModel: monaco.editor.ITextModel;
  private modifiedModel: monaco.editor.ITextModel;


  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.createDiffEditor();
  }

  showError(error) {
    this.error = error;
    this.showErrorAlert = true;
  }

  createDiffEditor(): void {
    this.diffEditor = monaco.editor.createDiffEditor(this.editorContainerRef.nativeElement, {
      automaticLayout: true,
    });

    this.originalModel = monaco.editor.createModel(this.leftCode, this.leftLanguage);
    // const originalModel = monaco.editor.createModel(this.getCode1(), 'text/plain');
    this.modifiedModel = monaco.editor.createModel(this.rightCode, this.rightLanguage);

    this.diffEditor.setModel({
      original: this.originalModel,
      modified: this.modifiedModel,
    });

    this.diffNavigator = monaco.editor.createDiffNavigator(this.diffEditor, {
      followsCaret: true,
      ignoreCharChanges: true,
    });
  }

  updateLeftModel(newCode: string): void {
    this.originalModel.setValue(newCode);
  }

  updateRightModel(newCode: string): void {
    this.modifiedModel.setValue(newCode);
  }

  navigatePrevious(): void {
    this.diffNavigator.previous();

    // this.diffNavigator.
  }


  navigateNext(): void {
    this.diffNavigator.next();
  }


  private setLanguage(typeOfFile) {
    if (
      typeOfFile == 'xsd' ||
      typeOfFile == 'mxml' ||
      typeOfFile == 'xsl' ||
      typeOfFile == 'xslt' ||
      typeOfFile == 'xhtml' ||
      typeOfFile == 'dom' ||
      typeOfFile == 'kml' ||
      typeOfFile == 'kmz'
    ) {
      typeOfFile = 'xml';
    }

    if (typeOfFile == 'template') {
      typeOfFile = 'json';
    }

    if (typeOfFile == 'py') {
      typeOfFile = 'python';
    }

    if (typeOfFile == 'robot') {
      typeOfFile = 'text';
    }

    if (typeOfFile == 'js') {
      typeOfFile = 'javascript';
    }

    if (typeOfFile == 'cs') {
      typeOfFile = 'csharp';
    }

    return typeOfFile;
  }


}
