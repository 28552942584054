import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchCriteria } from './search-criteria.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl = ''; // Adjust this as per your API endpoint

  constructor(private http: HttpClient) {}

  getSearchResults(criteria: SearchCriteria): Observable<string[]> {
    // The responseType is expected to be JSON, which is the default, so we don't need to set it explicitly
    return this.http.post<string[]>(`${this.apiUrl}/api/storage/folders/getSearchResults`, criteria);
  }

  stopSearch(): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/storage/folders/searchStop`, {});
  }
}
