import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AIChatConfig} from "../../shared/models/aIChatConfig";
import {AiChatService} from "../../shared/services/ai-chat.service";

@Component({
  selector: 'app-ai-options-dialog',
  templateUrl: './ai-options-dialog.component.html',
  styleUrls: ['./ai-options-dialog.component.css']
})
export class AiOptionsDialogComponent implements OnInit {

  public aiConfig: AIChatConfig = new AIChatConfig();
  constructor(
    public aiService: AiChatService,
    public dialogRef: MatDialogRef<AiOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      id: string
    }
  ) {
    aiService.getAiConfig().subscribe((aiConfig: AIChatConfig) => {
      this.aiConfig = aiConfig;
      this.aiConfig.aiType = 'openai'
    }, error => {
      console.error("error",error);
    });
  }

  ngOnInit(): void {
  }

  updateAIOption() {
    this.aiService.updateAiConfig(this.aiConfig).subscribe((aiConfig: AIChatConfig) => {
      this.dialogRef.close();
    }, error => {
      this.dialogRef.close();
      console.error("error", error);
    }
  );

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
