import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})

export class ErrorMessageComponent {

  showErrorAlert = true;
  errorTitle: string;
  @Input('message') errorMessage: string;
  @Input('removeClose') removeClose = false;
  moreDetails;

  stickToTopOfBottomComponent = false;
  showInDialog = false;
  fullWidth = false;

  hasMoreDetails = false;

  @Input('error') set _error(error) {
    this.showErrorAlert = true;

    if (error.error instanceof Blob) {
      let errorObj = '';

      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          errorObj = JSON.parse(reader.result);
          error.error = errorObj;
          this.parseError(error);
        }
      };

      reader.readAsText(error.error);
    } else {
      this.parseError(error);
    }
  }

  @Input('stickToTopOfBottomComponent') set _stickToTopOfBottomComponent(bool) {
    this.stickToTopOfBottomComponent = bool;
  }

  @Input('cleanDesign') cleanDesign = false;
  @Input('infoColors') infoColors = false;

  @Input('showInDialog') set _showInDialog(bool) {
    this.showInDialog = bool;
  }

  @Input('fullWidth') set _fullWidth(bool) {
    this.fullWidth = bool;
  }

  constructor(
    public snackBar: MatSnackBar
  ) {
  }

  parseError(error) {
    if (error.error && error.error.errorCode) {
      this.errorTitle = error.error.errorCode;
    } else if (error.name) {
      this.errorTitle = error.name;
    } else {
      this.errorTitle = 'Error';
    }

    if (error.error && error.error.errorMessage) {
      this.errorMessage = error.error.errorMessage;
    } else if (error.message) {
      this.errorMessage = error.message;
    } else {
      this.errorMessage = 'There was an error';
    }

    if (error.error && error.error.stackTrace) {
      const moreDetailsObj = {
        timestamp: error.error.timestamp,
        error: error.error.errorCode,
        message: error.error.errorMessage,
        trace: error.error.stackTrace.join('\r\n')
      };

      this.moreDetails = JSON.stringify(moreDetailsObj, null, 2);
      this.hasMoreDetails = true;
    }

    if (error.error && error.error.trace) {
      this.moreDetails = JSON.stringify(error.error, null, 2);
      this.hasMoreDetails = true;
    }
  }

  hideErrorAlert() {
    this.showErrorAlert = false;
  }

  copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.moreDetails;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.openSnackBar('Successfully copied to clipboard', 'OK', 5);
  }

  openSnackBar(message: string, action: string, timeInSec: number) {
    const sec = 1000;
    this.snackBar.open(message, action, {
      duration: timeInSec * sec
    });
  }
}
