<div mat-dialog-content>

  <app-error-message *ngIf="showErrorAlert" [error]="error"></app-error-message>

  <!--  <label>{{isCopy? 'Copy' : 'Cut'}} file:</label>-->
  <!--  <div class="pb-3">-->
  <!--    <input type="text" [disabled]="true" [(ngModel)]="copyNode.name">-->
  <!--  </div>-->

  <label>Rename {{isFile ? 'file' : 'folder'}} '{{name}}'</label>
  <div class="pb-3">
    <input type="text" [(ngModel)]="newName">
  </div>

  <div style="margin-left: 5px; padding-bottom: 0;" class="pb-3">
    <mat-checkbox [disabled]="spinner" type="checkbox" [checked]="overwrite" (change)="overwrite = !overwrite"
                  id="overwriteCheck">Overwrite data if there is file with same name.
    </mat-checkbox>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]>Cancel</button>
  <button mat-button *ngIf="!spinner" (click)="rename()" color="primary">Rename</button>
  <button mat-button *ngIf="spinner" color="primary">
    <mat-icon style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;">
      <mat-spinner style="width: 20px; height: 20px; line-height: 20px; font-size: 20px;"
                   [diameter]="20"
                   class="whiteSpinner"></mat-spinner>
    </mat-icon>
  </button>
</div>
