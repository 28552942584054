<mx-options
  [allowShadow]="allowShadow"
  [showButtons]="showButtons"
  [showButtonText]="showButtonText"
  [showTooltip]="showTooltip"
  [smallHeight]="smallHeight"
  [iconMarginBottom]="iconMarginBottom"
  [defaultColors]="defaultColors"
  #mxOptions
>

  <mx-options-header>

    <!--      delete-->
    <button id="deleteButton" mat-button
            *ngIf="showButtons"
            (click)="prev()"
            [matTooltip]="!showTooltip? undefined : 'Prev'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="up"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Prev</span>
    </button>

    <!--      refresh-->
    <button mat-button
            *ngIf="showButtons"
            (click)="next()"
            [matTooltip]="!showTooltip? undefined : 'Next'">
      <mx-icon [marginBottom]="iconMarginBottom" mx="down"></mx-icon>
      <span class="px-1" *ngIf="showButtonText">Next</span>
    </button>

  </mx-options-header>

  <mx-options-body>

  </mx-options-body>
</mx-options>
