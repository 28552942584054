import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MxDiffCodeComponent } from './mx-diff-code.component';
import {ErrorModule} from "../error/error.module";
import {MxDiffCodeOptionsModule} from "../../cases/components/mx-diff-code-options/mx-diff-code-options.module";



@NgModule({
    declarations: [MxDiffCodeComponent],
    exports: [
        MxDiffCodeComponent
    ],
  imports: [
    CommonModule,
    ErrorModule,
    MxDiffCodeOptionsModule
  ]
})
export class MxDiffCodeModule { }
